@use "../../global" as *;

/*=================================
  pager
=================================*/
.pager {
  margin-top: vwclamp(40,80);
  &List {
    display: flex;
    justify-content: center;
    &__item {
      margin-right: vwclamp(10,20);
      a,span {
        @include font-outfit;
        font-size: vwclamp(15,30);
        line-height: 1;
        padding: vwclamp(15,30) vwclamp(17,35);
        display: block;
      }
      a {
        color: $color-theme;
        text-decoration: none;
        background: $color-theme-light;
        @include mq(pc) {
          &:hover {
            color: #fff;
            background: $color-theme;
          }
        }
      }
      span {
        color: #fff;
        background: $color-theme;
      }
    }
  }
  .wp-pagenavi {
    display: flex;
    justify-content: center;
    .pages {
      font-size: vwclamp(15,30);
      line-height: 1;
      padding: vwclamp(15,30) vwclamp(17,35);
      margin-right: vwclamp(10,20);
      border: 1px solid $color-theme;
    }
    .current {
      font-size: vwclamp(15,30);
      line-height: 1;
      color: #fff;
      padding: vwclamp(15,30) vwclamp(17,35);
      background: $color-theme;
      display: block;
      text-decoration: none;
      border: 1px solid $color-theme;
    }
    .page {
      font-size: vwclamp(15,30);
      color: $color-theme;
      line-height: 1;
      padding: vwclamp(15,30) vwclamp(17,35);
      display: block;
      background: $color-theme-light;
      text-decoration: none;
      margin-left: vwclamp(10,20);
      border: 1px solid $color-theme-light;
      @include mq(pc) {
        &:hover {
          color: #fff;
          background: $color-theme;
        }
      }
    }
    .nextpostslink {
      font-size: vwclamp(15,30);
      color: $color-theme;
      line-height: 1;
      padding: vwclamp(15,30) vwclamp(17,35);
      display: block;
      background: #fff;
      text-decoration: none;
      margin-left: vwclamp(10,20);
      border: 1px solid $color-theme;
      @include mq(pc) {
        &:hover {
          color: #fff;
          background: $color-theme;
        }
      }
    }
    .previouspostslink {
      font-size: vwclamp(15,30);
      color: $color-theme;
      line-height: 1;
      padding: vwclamp(15,30) vwclamp(17,35);
      display: block;
      background: #fff;
      text-decoration: none;
      border: 1px solid $color-theme;
      @include mq(pc) {
        &:hover {
          color: #fff;
          background: $color-theme;
        }
      }
    }
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    pager
  =================================*/
  .pager {
    display: block;
    text-align: center;
    margin-top: vwclamp(20,40,$sp-width);
    &List {
      justify-content: center;
      &__item {
        margin-right: vwclamp(8,16,$sp-width);
        a,span {
          padding: vwclamp(10,20,$sp-width) vwclamp(15,30,$sp-width);
        }
      }
    }
    .wp-pagenavi {
      .pages {
        padding: vwclamp(10,20,$sp-width) vwclamp(15,30,$sp-width);
        margin-right: vwclamp(8,16,$sp-width);
      }
      .current {
        padding: vwclamp(10,20,$sp-width) vwclamp(15,30,$sp-width);
      }
      .page {
        padding: vwclamp(10,20,$sp-width) vwclamp(15,30,$sp-width);
        margin-left: vwclamp(8,16,$sp-width);
      }
      .nextpostslink {
        padding: vwclamp(10,20,$sp-width) vwclamp(15,30,$sp-width);
        margin-left: vwclamp(8,16,$sp-width);
      }
      .previouspostslink {
        padding: vwclamp(10,20,$sp-width) vwclamp(15,30,$sp-width);
      }
    }
  }
}