@use "../global" as *;
/* =================================
  header
================================= */
.home {
  .header {
    padding: 0;
    .burger {
      right: inherit;
      left: 0;
    }
  }
}
.header {
  width: 100%;
  padding: vwclamp(17,34) vwclamp(100,270) vwclamp(17,34) vwclamp(20,170);
  position: relative;
  z-index: 9999;
  &__inner {
    display: flex;
    align-items: center;
  }
  &__logo {
    .logo {
      width: vwclamp(215,430);
      display: inline-block;
      vertical-align: middle;
      a {
        display: block;
        @include mq(pc) {
          &:hover {
            opacity: .8;
          }
        }
      }
    }
  }
  .gNav {
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    &List {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-left: 10px;
      &__item {
        display: inline-block;
        position: relative;
        &:not(:first-child) {
          margin-left: vwclamp(20,110);
        }
        a {
          font-size: vwclamp(16,35);
          font-weight: 400;
          color: $font-color-base;
          text-decoration: none;
          display: block;
          position: relative;
          z-index: 2;
          @include mq(pc) {
            &:hover {
              color: $color-theme;
            }
          }
        }
      }
    }
  }
  .burgerNav {
    width: 100%;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transform: scale(.9);
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-in-out, transform .3s ease-out,visibility .2s ease-out;
    &__logo {
      width: vwclamp(215,430);
      position: absolute;
      top: vwclamp(17,34);
      left: 50%;
      transform: translateX(-50%);
    }
    &List {
      padding: vwclamp(238,476) vwclamp(20,170) 70px;
      display: flex;
      flex-wrap: wrap;
      gap: vwclamp(50,100) 0;
      width: 80vw;
      margin: 0 auto;
      &__item {
        width: 50%;
        a {
          font-size: vwclamp(18,35);
          font-weight: 500;
          color: $color-link;
          text-decoration: none;
          @include mq(pc) {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    &.show {
      transform: scale(1);
      visibility: visible;
      opacity: 1;
    }
    &.hide {
      transform: scale(.9);
      opacity: 0;
      visibility: hidden;
    }
  }
  .headerNav__cover {
    content: "";
    width: 0;
    height: 100vh;
    display: block;
    background: url(../img/bg_burgerNav_pc.png) no-repeat right center,#fff;
    background-size: cover;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    opacity: 0;
    @include animation-base(all,.3s);
    &.show {
      opacity: 1;
      width: 100%;
    }
    &.hide {
      opacity: 0;
      width: 0;
    }
  }
  /*バーガーボタン設定*/
  .burger {
    width: vwclamp(85,170);
    height: vwclamp(85,170);
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 9999;
    background: $color-theme;
    span {
      width: vwclamp(35,70);
      height: vwclamp(2.5,5);
      display: block;
      background: #fff;
      position: absolute;
      top: vwclamp(32,64);
      right: 0;
      left: 0;
      margin: 0 auto;
      transition: width 0.2s, right 0.2s, left 0.2s;
      &.burger--top{
        transform: translateY(0);
      }
      &.burger--middle{
        transform: translateY(vwclamp(10,20));
        position: relative;
        background: none;
        &::before{
          content: "";
          display: block;
          position: absolute;
          width: vwclamp(35,70);
          height: vwclamp(2.5,5);
          background: #fff;
          transition: all 0.2s;
          transform: rotate(0deg);
        }
        &::after{
          content: "";
          display: block;
          position: absolute;
          width: vwclamp(35,70);
          height: vwclamp(2.5,5);
          background: #fff;
          transition: all 0.2s;
          transform: rotate(0deg);
        }
      }
      &.burger--bottom{
        transform: translateY(vwclamp(20,40));
      }
    }
    /*クリック後、バツボタンになる*/
    &.is-open {
      position: fixed;
      background: transparent;
      .burger--top{
        left: 100%;
        width: 0px;
      }
      .burger--middle {
        &::before{
          transform: rotate(135deg);
          background: $font-color-base;
        }
        &::after {
          transform: rotate(45deg);
          background: $font-color-base;
        }
      }
      .burger--bottom{
        right: 100%;
        width: 0px;
      }
    }
    &.fixedNav {
      position: fixed;
      animation: header-show .5s ease-in;
    }
  }
}
@include mq(sp) {
  /* =================================
    header
  ================================= */
  .home {
    .header {
      .burger {
        right: 0;
        left: inherit;
      }
    }
  }
  .header {
    padding: vwclamp(17,35,$sp-width) vwclamp(25,50,$sp-width);
    &__inner {
      display: block;
    }
    &__logo {
      .logo {
        width: vwclamp(141,282,$sp-width);
        line-height: 1;
      }
    }
    .gNav {
      display: none;
    }
    .burgerNav {
      &__logo {
        width: vwclamp(69,138,$sp-width);
        top: vwclamp(20,40,$sp-width);
        line-height: 1;
      }
      &List {
        padding: vwclamp(150,300,$sp-width) vwclamp(25,50,$sp-width);
        gap: vwclamp(25,50,$sp-width);
        width: 100%;
        &__item {
          width: calc((100% - vwclamp(25,50,$sp-width)) / 2);
          a {
            font-size: vwclamp(15,30,$sp-width);
          }
        }
      }
    }
    .headerNav__cover {
      background: url(../img/bg_burgerNav_sp.png) no-repeat right center,#fff;
      background-size: cover;
    }
    /*バーガーボタン設定*/
    .burger {
      width: vwclamp(60,120,$sp-width);
      height: vwclamp(60,120,$sp-width);
      span {
        width: vwclamp(21,42,$sp-width);
        top: vwclamp(22,44,$sp-width);
        &.burger--middle{
          transform: translateY(vwclamp(7,14,$sp-width));
          &::before{
            width: vwclamp(21,42,$sp-width);
          }
          &::after{
            width: vwclamp(21,42,$sp-width);
          }
        }
        &.burger--bottom{
          transform: translateY(vwclamp(14,28,$sp-width));
        }
      }
    }
  }
}