@use "../global" as *;
/*=================================
footer
=================================*/
.pageTop {
  width: vwclamp(90,180);
  cursor: pointer;
  position: absolute;
  right: vwclamp(20,170);
  bottom: vwclamp(110,220);
  opacity: 0;
  @include animation-base;
  @include mq(pc) {
    &:hover {
      transform: translateY(-10%);
    }
  }
  &.float {
    position: fixed;
    // bottom: 40px;
    // right: 5%;
    z-index: 10;
    opacity: 1;
  }
  &.nonfloat {
    opacity: 1;
  }
}
.mainWrap:not(.under) {
  .pageTop {
    bottom: vwclamp(170,214);
  }
}
.footer {
  position: relative;
  z-index: -1;
  a {
    color: #fff;
  }
  &__main {
    background: url(../img/bg_footer_pc.jpg) no-repeat center center;
    background-size: cover;
    // max-width: $base-width;
    // margin: 0 auto;
    padding: vwclamp(80,160) vwclamp(20,170) vwclamp(118,236);
    box-sizing: content-box;
  }
  &__sub {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: #fff;
    padding: vwclamp(48,97) vwclamp(20,170);
    .copy {
      // @include font-outfit;
      font-family: "Outfit","Noto Sans JP", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "Yu Gothic", YuGothic, "Noto Sans JP", "源ノ角ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
      font-size: vwclamp(15,30);
      letter-spacing: 0.05em;
      line-height: 2.2;
    }
  }
  &__logo {
    font-size: vwclamp(30,60);
    line-height: 2.33333;
    letter-spacing: .05em;
    color: #fff;
    margin-bottom: vwclamp(50,100);
  }
  &Cont {
    display: flex;
    align-self: flex-start;
  }
  &Info {
    &Wrap {
      border-top: vwclamp(2,4) solid #fff;
      padding-top: vwclamp(23,46);
    }
    &__ttl {
      font-size: vwclamp(18,35);
      font-weight: 500;
      color: #fff;
      margin-bottom: vwclamp(15,30);
    }
    &__add {
      font-size: vwclamp(15,30);
      line-height: 1.5;
      color: #fff;
    }
    & + .footerInfo {
      margin-top: vwclamp(25,50);
    }
  }
  &Nav {
    border-top: vwclamp(2,4) solid #fff;
    padding-top: vwclamp(23,46);
    &:not(:first-child) {
      margin-left: vwclamp(60,180);
    }
    &Wrap {
      display: flex;
      margin-left: vwclamp(142,424);
    }
    &__ttl {
      font-size: vwclamp(18,35);
      font-weight: 500;
      line-height: 1;
      color: #fff;
      margin-bottom: vwclamp(24,48);
    }
    &List {
      &__item {
        a {
          font-size: vwclamp(15,30);
          line-height: 2;
          color: #fff;
          display: block;
          text-decoration: none;
          padding-right: vwclamp(26,52);
          position: relative;
          &::after {
            content: "";
            width: vwclamp(15,30);
            height: vwclamp(12,24);
            display: inline-block;
            background: url(../img/footer_arrow.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          @include mq(pc) {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .footerSubLink {
    display: flex;
    gap: 0 vwclamp(20,40);
    &__item {
      a {
        font-size: vwclamp(15,30);
        font-weight: 500;
        color: $font-color-base;
        text-decoration: none;
        @include mq(pc) {
          &:hover {
            color: $color-link;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@include mq(sp) {
  /*=================================
  footer
  =================================*/
  .pageTop {
    width: vwclamp(60,120,$sp-width);
    right: 0;
    bottom: vwclamp(50,100,$sp-width);
  }
  .mainWrap:not(.under) {
    .pageTop {
      bottom: vwclamp(50,100,$sp-width);
    }
  }
  .footer {
    &__main {
      background: url(../img/bg_footer_sp.jpg) no-repeat center center;
      background-size: cover;
      padding: vwclamp(75,150,$sp-width) vwclamp(25,50,$sp-width) vwclamp(74,148,$sp-width);
    }
    &__sub {
      display: block;
      padding: vwclamp(30,60,$sp-width) vwclamp(25,50,$sp-width);
      .copy {
        font-size: vwclamp(15,30,$sp-width);
        display: block;
        text-align: center;
        margin-top: vwclamp(25,50,$sp-width);
      }
    }
    &__logo {
      font-size: vwclamp(22,35,$sp-width);
      line-height: 1.5;
      letter-spacing: 0;
      margin-bottom: vwclamp(31,62,$sp-width);
    }
    &Cont {
      display: block;
    }
    &Info {
      &Wrap {
        border-top: vwclamp(1,2,$sp-width) solid #fff;
        padding-top: vwclamp(25,50,$sp-width);
      }
      &__ttl {
        font-size: vwclamp(20,40,$sp-width);
        margin-bottom: vwclamp(10,20,$sp-width);
      }
      &__add {
        font-size: vwclamp(15,30,$sp-width);
      }
      & + .footerInfo {
        margin-top: vwclamp(25,50,$sp-width);
      }
    }
    &Nav {
      border-top: vwclamp(1,2,$sp-width) solid #fff;
      padding-top: vwclamp(25,50,$sp-width);
      margin-top: vwclamp(50,100,$sp-width);
      &:not(:first-child) {
        margin-left: 0;
      }
      &Wrap {
        display: block;
        margin-left: 0;
      }
      &__ttl {
        font-size: vwclamp(20,40,$sp-width);
        margin-bottom: vwclamp(15,30,$sp-width);
      }
      &List {
        &__item {
          a {
            font-size: vwclamp(15,30,$sp-width);
            padding-right: 0;
            &::after {
              width: vwclamp(14,28,$sp-width);
              height: vwclamp(10,20,$sp-width);
              position: static;
              margin-left: vwclamp(15,30,$sp-width);
              margin-bottom: vwclamp(-5,-10,$sp-width);
            }
          }
        }
      }
    }
    .footerSubLink {
      gap: 0 vwclamp(30,80,$sp-width);
      justify-content: center;
      &__item {
        a {
          font-size: vwclamp(15,30,$sp-width);
        }
      }
    }
  }
}