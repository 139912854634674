@use "../../global" as *;

/*=================================
  title
=================================*/
.ttl--base {
  font-size: vwclamp(40,80);
  font-weight: 400;
  color: $font-color-base;
  line-height: 1.2125;
  text-align: center;
  margin-bottom: vwclamp(50,100);
}
.ttl--box {
  margin-bottom: vwclamp(50,100);
  &__ruby {
    @include font-outfit;
    font-size: vwclamp(13,25);
    letter-spacing: .05em;
    line-height: 1;
  }
  &__main {
    font-size: vwclamp(40,80);
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: .05em;
    color: $color-theme;
  }
  &__text {
    font-size: vwclamp(17,35);
    line-height: 1.71428;
  }
  &.white {
    .ttl--box__ruby {
      color: #fff;
    }
    .ttl--box__main {
      color: #fff;

    }
    .ttl--box__text {
      color: #fff;

    }
  }
}
.ttl--page {
  margin-bottom: vwclamp(50,100);
  &__ruby {
    @include font-outfit;
    font-size: vwclamp(13,25);
    letter-spacing: .05em;
    line-height: 1;
  }
  &__main {
    font-size: vwclamp(45,90);
    font-weight: 500;
    line-height: 1.6666;
    color: $color-theme;
  }
}
.singlePage__mainTtl {
  font-size: vwclamp(40,80);
  font-weight: 400;
  line-height: 1.75;
  text-align: center;
  margin-bottom: vwclamp(50,100);
}

@include mq(sp) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: vwclamp(28,55,$sp-width);
    line-height: 1.454545;
    margin-bottom: vwclamp(50,100,$sp-width);
    padding: 0 vwclamp(25,50,$sp-width);
  }
  .ttl--box {
    margin-bottom: vwclamp(40,80,$sp-width);
    &__ruby {
      font-size: vwclamp(10,20,$sp-width);
    }
    &__main {
      font-size: vwclamp(35,70,$sp-width);
      line-height: 2;
      letter-spacing: 0;
    }
    &__text {
      font-size: vwclamp(15,30,$sp-width);
      line-height: 2;
    }
  }
  .ttl--page {
    padding: 0 vwclamp(25,50,$sp-width);
    margin-bottom: vwclamp(25,50,$sp-width);
    &__ruby {
      font-size: vwclamp(10,20,$sp-width);
    }
    &__main {
      font-size: vwclamp(30,60,$sp-width);
    }
  }
  .singlePage__mainTtl {
    font-size: vwclamp(28,55,$sp-width);
    line-height: 1.454545;
    text-align: left;
    margin-bottom: vwclamp(50,100,$sp-width);
    padding: 0 vwclamp(25,50,$sp-width);
  }
}