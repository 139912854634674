@use "../../global" as *;
/*=================================
  page--article
=================================*/
/* 記事一覧 */
.articleList {
  position: relative;
  z-index: 1;
  &__item {
    border-bottom: vwclamp(1,2) solid #AAAAAA;
    @include mq(pc) {
      &:hover {
        .textWrap__ttl {
          text-decoration: underline!important;
        }
      }
    }
    .itemWrap {
      padding: vwclamp(25,50) 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      .thumb {
        width: 30%;
        margin-left: 5%;
        overflow: hidden;
        img {
          margin: 0 auto;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        & + .textWrap {
          width: calc(100% - 35%);
        }
      }
      .textWrap {
        width: 100%;
        &__date {
          font-size: vwclamp(12,25);
          margin-bottom: vwclamp(14,28);
        }
        &__ttl {
          font-size: vwclamp(18,50);
          font-weight: 400;
          line-height: 1.66666;
          color: $color-theme;
          padding-left: vwclamp(20,40);
          display: block;
          text-decoration: none;
          position: relative;
          &::before {
            content: "";
            width: vwclamp(10,20);
            height: vwclamp(10,20);
            display: inline-block;
            background: $color-theme;
            border-radius: 50px;
            position: absolute;
            top: vwclamp(15,30);
            left: 0;
          }
        }
        &__text {
          font-size: vwclamp(15,30);
          font-weight: 400;
          line-height: 2;
          margin-top: vwclamp(15,30);
        }
      }
    }
  }
}
.articleListWrap {
  &__inner {
    padding: vwclamp(25,50);
    background: #fff;
    max-width: $base-width;
    margin: 0 auto;
    @include singleStyle-base;
    @for $i from 1 through 6 {
      & > h#{$i}:first-child {
        margin-top: 0!important;
      }
    }
    .pager {
      @for $i from 1 through 6 {
        & + h#{$i}{
          margin-top: vwclamp(50,100);
        }
      }
    }
  }
}

/* singlePage */
.singlePage {
  @include singleStyle-parts;
  &__inner {
    padding: vwclamp(50,100) vwclamp(50,100);
    background: #fff;
    @include mq(tab) {
      padding: 100px 100px;
    }
    @include singleStyle-base;
    @for $i from 1 through 2 {
      & > h#{$i}:first-child {
        margin-top: vwclamp(-50,-100)!important;
        @include mq(tab) {
          margin-top: -100px!important;
        }
      }
    }
  }
}

/* 記事詳細ページ */
.articleDetail {
  @include singleStyle-parts;
  &__inner {
    padding: vwclamp(50,100) vwclamp(50,100);
    background: #fff;
    @include mq(tab) {
      padding: 100px 100px;
    }
    @include singleStyle-base;
    @for $i from 1 through 6 {
      & > h#{$i}:first-child {
        margin-top: 0!important;
      }
    }
    .tocBox {
      & + * {
        margin-top: 0!important;
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /* 記事一覧 */
  .articleList {
    &__item {
      border-bottom: vwclamp(1,2,$sp-width) solid #AAAAAA;
      .itemWrap {
        padding: vwclamp(25,50,$sp-width) 0;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        .thumb {
          width: 100%;
          margin: 0 0 vwclamp(15,30,$sp-width);
          & + .textWrap {
            width: 100%;
          }
        }
        .textWrap {
          width: 100%;
          &__date {
            font-size: vwclamp(12,24,$sp-width);
            margin-bottom: vwclamp(20,40,$sp-width);
          }
          &__ttl {
            font-size: vwclamp(20,40,$sp-width);
            padding-left: vwclamp(20,40,$sp-width);
            &::before {
              content: "";
              width: vwclamp(10,20,$sp-width);
              height: vwclamp(10,20,$sp-width);
              top: vwclamp(15,30,$sp-width);
            }
          }
          &__text {
            font-size: vwclamp(16,32,$sp-width);
            margin-top: vwclamp(15,30,$sp-width);
          }
        }
      }
    }
  }
  .articleListWrap {
    &__inner {
      padding: vwclamp(25,50,$sp-width);
      .pager {
        @for $i from 1 through 6 {
          & + h#{$i}{
            margin-top: vwclamp(25,50,$sp-width);
          }
        }
      }
    }
  }
  /* singlePage */
  .singlePage {
    &__inner {
      padding: 0 vwclamp(25,50,$sp-width);
      background: transparent;
    }
  }
  /* 記事詳細ページ */
  .articleDetail {
    &__inner {
      padding: 0 vwclamp(25,50,$sp-width);
      background: transparent;
    }
  }
}