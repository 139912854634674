@use "../mixin/variables" as *;
@use "../mixin/breakpoint" as *;
//フォントの定義
%font {
  font-family: "Noto Sans JP", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "Yu Gothic", YuGothic, "Noto Sans JP", "源ノ角ゴシック","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: vwclamp(16,35);
  color: $font-color-base;
  line-height: 2;
  @include mq(sp) {
    font-size: vwclamp(14,30,$sp-width);
  }
}

@mixin font-outfit {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
}