@use "../../global" as *;
/*=================================
  お問い合わせ
=================================*/
.cvWrap {
  .cvBnr {
    background: url(../img/contact/contact_bnr_img_pc.png) no-repeat left vwclamp(55,110) bottom, $color-theme;
    background-size: auto calc(100% - vwclamp(20,50));
    mix-blend-mode: multiply;
    padding: vwclamp(85,170) vwclamp(20,50) vwclamp(85,170) vwclamp(200,800);
    @include mq(tab) {
      padding: vwclamp(85,170) vwclamp(20,50) vwclamp(85,170) vwclamp(470,940);
      background-size: auto calc(100% - vwclamp(20,50));
    }
    &__ttl {
      font-size: vwclamp(25,50);
      line-height: 1.5;
      letter-spacing: .05em;
      color: #fff;
      margin-bottom: vwclamp(22,44);
    }
    &__tel {
      @include font-outfit;
      font-size: vwclamp(60,120);
      line-height: 1;
      color: #fff;
      text-decoration: none;
      padding-left: vwclamp(70,208);
      position: relative;
      pointer-events: none;
      @include mq(tab) {
        font-size: vwclamp(82,163);
      }
      &::before {
        content: "";
        width: vwclamp(56,168);
        height: vwclamp(36,110);
        display: inline-block;
        background: url(../img/icon_free.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
    .businessHours {
      display: flex;
      margin-top: vwclamp(20,40);
      &__ttl {
        font-size: vwclamp(20,40);
        line-height: 1;
        color: #fff;
        padding-right: vwclamp(45,90);
        position: relative;
        &::after {
          content: "";
          width: vwclamp(2,4);
          height: vwclamp(37,74);
          display: inline-block;
          background: #fff;
          position: absolute;
          top: 50%;
          right: vwclamp(22,45);
          transform: translateY(-50%) rotate(-45deg);
        }
      }
      &__text {
        font-size: vwclamp(20,40);
        line-height: 1;
        color: #fff;
      }
    }
  }
  .cvTtl {
    font-size: vwclamp(40,80);
    font-weight: 400;
    line-height: 1.75;
    text-align: center;
    margin: vwclamp(70,140) 0 vwclamp(77,154);
  }
}
.contactPage {
  @include singleStyle-parts;
  &__inner {
    padding: 0 vwclamp(50,100) vwclamp(41,82);
    background: #fff;
    @include mq(tab) {
      padding: 0 100px 80px;
    }
    @include singleStyle-base;
    @for $i from 1 through 6 {
      & > h#{$i}:first-child {
        margin-top: 0!important;
      }
    }
  }
}
.formBox {
  @for $i from 1 through 6 {
    h#{$i}{
      margin-top: 0!important;
      margin-bottom: vwclamp(50,100)!important;
    }
  }
  .step {
    display: flex;
    justify-content: center;
    gap: 0 vwclamp(108,215);
    margin: vwclamp(50,100) 0;
    &__item {
      font-size: vwclamp(18,35);
      font-weight: 400;
      line-height: 1;
      display: inline-block;
      position: relative;
      padding-left: vwclamp(33,65);
      &:not(:last-child) {
        &::after {
          content: "";
          width: vwclamp(45,90);
          height: vwclamp(1,2);
          display: inline-block;
          background: #AAAAAA;
          position: absolute;
          top: 50%;
          right: vwclamp(-75,-150);
          transform: translateY(-50%);
          z-index: 0;
        }
      }
      &::before {
        content: "";
        width: vwclamp(20,40);
        height: vwclamp(20,40);
        display: inline-block;
        background: #EFF6F7;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 1;
      }
      &.active {
        font-weight: 700;
        color: $color-theme;
        &:before {
          background: $color-theme;
        }
      }
    }
  }
  .requiredNotes {
    color: $color-red;
    text-align: right;
    margin: vwclamp(50,100) 0 vwclamp(25,50);
  }
  .btn--form {
    display: block;
    text-align: center;
    width: 100%;
    max-width: 80%;
    &:not(.back) {
      input {
        font-size: vwclamp(19,38);
        font-weight: 500;
        color: #fff;
        text-decoration: none;
        padding: vwclamp(34,68) vwclamp(5,10);
        background: $color-theme;
        display: block;
        width: 100%;
        border: vwclamp(1,2) solid transparent;
        cursor: pointer;
        @include animation-base;
      }
      @include mq(pc) {
        &:hover {
          input {
            color: $font-color-base;
            background: #fff;
            border-color: $font-color-base;
          }
        }
      }
    }
    &.back {
      max-width: initial;
      input {
        font-size: vwclamp(15,30);
        color: #AAAAAA;
        cursor: pointer;
      }
    }
  }
}
.mw_wp_form_input {
  .step {
    .step__item:nth-child(1) {
      &:before {
        background: $color-theme;
      }
    }
  }
}
.mw_wp_form_confirm {
  .step {
    .step__item:nth-child(2) {
      &:before {
        background: $color-theme;
      }
    }
  }
}
/* =================================
  お問い合わせフォーム
================================= */
.consultForm {
  &__item {
    display: flex;
    align-items: flex-start;
    padding: vwclamp(25,50) 0;
    border-bottom: vwclamp(1,2) solid #AAAAAA;
    &:first-child {
      border-top: vwclamp(1,2) solid #AAAAAA;
    }
  }
  &__ttl {
    font-size: vwclamp(18,35);
    font-weight: 400;
    width: calc(504 / 1600 * 100%);
    position: relative;
    .sub {
      font-size: vwclamp(12,20);
      display: block;
    }
    span:not(.sub) {
      font-size: vwclamp(18,35);
      color: $color-theme;
      line-height: 1;
      margin-left: vwclamp(9,18);
    }
    .required {
      color: $color-red!important;
    }
  }
  &__list {
    font-size: vwclamp(18,35);
    width: calc(100% - calc(504 / 1600 * 100%) - vwclamp(36,72));
    p {
      line-height: 1.65;
    }
    * {
      font-size: vwclamp(18,35);
    }
    input:not([type="radio"]):not([type="checkbox"]),textarea,select {
      background: #F7F7F7;
      border-radius: 10px;
      padding: vwclamp(21,42) vwclamp(29,58);
      border: none;
    }
    input:not([type="radio"]):not([type="checkbox"]):not([class="widthNormal"]),textarea,select {
      width: 100%;
      box-sizing: border-box;
    }
    .mwform-radio-field {
      display: inline-block;
      margin-bottom: 1rem;
      margin-right: 3rem;
      label {
        display: inline-block;
      }
    }
    input[type="radio"] + span {
      padding-left: vwclamp(40,80);
      display: inline-block;
      position: relative;
    }
    input[type="radio"] + span::before {
      content: "";
      width: vwclamp(30,60);
      height: vwclamp(30,60);
      box-sizing: border-box;
      margin: auto;
      border-radius: 50%;
      background-color: #fff;
      border: vwclamp(3,6) solid #c5c5c5;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
    input[type="radio"]:checked + span::after {
      content: "";
      width: vwclamp(18,36);
      height: vwclamp(18,36);
      border-radius: 50%;
      margin: auto 0;
      background-color: $color-theme;
      position: absolute;
      top: 0;
      bottom: 0;
      left: vwclamp(6,13);
    }
    .mwform-checkbox-field {
      display: inline-block;
      margin-bottom: 1rem;
      margin-right: 3rem;
      label {
        display: inline-block;
      }
    }
    input[type="checkbox"] + span {
      padding-left: vwclamp(40,80);
      display: inline-block;
      position: relative;
    }
    input[type="checkbox"] + span::before {
      content: "";
      width: vwclamp(30,60);
      height: vwclamp(30,60);
      box-sizing: border-box;
      margin: auto;
      border-radius: 3px;
      background-color: #fff;
      border: vwclamp(3,6) solid #c5c5c5;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
    input[type="checkbox"]:checked + span::after {
      content: "";
      width: vwclamp(20,40);
      height: vwclamp(20,40);
      display: inline-block;
      mask-image: url(../img/icon_check_white.svg);
      mask-repeat: no-repeat;
      mask-size: contain;
      background-color: $color-theme;
      position: absolute;
      top: vwclamp(10,20);
      left: vwclamp(5,10);
    }
    textarea {
      min-height : 280px;
    }
    .error {
      font-size: vwclamp(18,35);
      color: $color-red;
      margin-top: vwclamp(3,5);
      display: block;
    }
    .yoyaku {
      display: flex;
      align-items: center;
      .rank {
        font-weight: bold;
        color: $color-theme;
        width: vwclamp(100,200);
      }
      .yoyakuBox {
        width: calc(100% - vwclamp(100,200));
        display: flex;
      }
      .yoyakudate {
        width: calc((100% - vwclamp(10,20)) / 2)!important;
      }
      .yoyakujikan {
        width: calc((100% - vwclamp(10,20)) / 2)!important;
        margin-left: vwclamp(10,20);
      }
      & + .yoyaku {
        margin-top: vwclamp(10,20);
      }
    }
  }
  & + .consultForm {
    margin-top: vwclamp(50,100);
  }
}
.confirmingTxt {
  text-align: center;
  margin: vwclamp(50,100) 0 vwclamp(40,80);
  p {
    font-size: vwclamp(20,40);
  }
  input {
    -webkit-appearance: checkbox;
  }
  a {
    text-decoration: underline;
    &:hover {
      color: $color-theme;
    }
  }
}
.checkText {
  label {
    margin-right: vwclamp(10,20);
  }
}
.completedForm {
  .completedMessage {
    font-size: vwclamp(25,50);
    line-height: 1;
    letter-spacing: .05em;
    display: inline-block;
    margin-top: vwclamp(50,100);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    &::after {
      content: "";
      width: 100%;
      height: vwclamp(2,4);
      background: $color-theme;
      display: inline-block;
    }
  }
  p {
    text-align: center;
    margin-top: vwclamp(25,50);
  }
}

@include mq(sp) {
  /*=================================
    お問い合わせ
  =================================*/
  .cvWrap {
    background: url(../img/contact/contact_bnr_img_sp.png) no-repeat right bottom;
    background-size: 40% auto;
    text-align: center;
    padding: 0 vwclamp(25,50,$sp-width) vwclamp(50,100,$sp-width);
    margin-bottom: vwclamp(25,50,$sp-width);
    .cvBnr {
      background: $color-theme;
      padding: vwclamp(32,64,$sp-width) vwclamp(10,20,$sp-width);
      &__ttl {
        font-size: vwclamp(15,30,$sp-width);
        letter-spacing: 0;
        margin-bottom: vwclamp(15,30,$sp-width);
      }
      &__tel {
        font-size: vwclamp(35,70,$sp-width);
        padding-left: vwclamp(45,90,$sp-width);
        pointer-events: initial;
        &::before {
          width: vwclamp(39,78,$sp-width);
          height: vwclamp(24,48,$sp-width);
          left: 0;
        }
      }
      .businessHours {
        justify-content: center;
        margin-top: vwclamp(15,30,$sp-width);
        &__ttl {
          font-size: vwclamp(15,30,$sp-width);
          padding-right: vwclamp(30,60,$sp-width);
          &::after {
            width: vwclamp(1,2,$sp-width);
            height: vwclamp(12,24,$sp-width);
            right: vwclamp(15,30,$sp-width);
          }
        }
        &__text {
          font-size: vwclamp(15,30,$sp-width);
        }
      }
    }
    .cvTtl {
      font-size: vwclamp(28,55,$sp-width);
      line-height: 1.454545;
      text-align: left;
      margin: vwclamp(50,100,$sp-width) 0 0;
    }
  }
}

@media screen and (max-width: 1080px) {
  /*=================================
    お問い合わせ
  =================================*/
  .contactPage {
    margin-top: vwclamp(25,50,$sp-width);
    &__inner {
      padding: 0 vwclamp(25,50,$sp-width);
    }
    table {
      display: table;
    }
  }
  .formBox {
    background: #fff;
    padding: vwclamp(25,50,$sp-width);
    border: vwclamp(25,50,$sp-width) solid #EFF6F7;
    width: calc(100% + vwclamp(50,100,$sp-width));
    margin-left: vwclamp(-25,-50,$sp-width);
    @for $i from 1 through 6 {
      h#{$i}{
        margin-top: 0!important;
        margin-bottom: vwclamp(25,50,$sp-width)!important;
      }
    }
    .step {
      gap: 0 vwclamp(30,60,$sp-width);
      margin: vwclamp(25,50,$sp-width) 0;
      &__item {
        font-size: vwclamp(13,24,$sp-width);
        padding-left: vwclamp(19,38,$sp-width);
        &:not(:last-child) {
          &::after {
            width: vwclamp(20,40,$sp-width);
            height: vwclamp(1,2,$sp-width);
            right: vwclamp(-22,-44,$sp-width);
          }
        }
        &::before {
          width: vwclamp(15,30,$sp-width);
          height: vwclamp(15,30,$sp-width);
        }
      }
    }
    .requiredNotes {
      margin: vwclamp(25,50,$sp-width) 0;
    }
    .btn--form {
      max-width: 100%;
      &:not(.back) {
        input {
          font-size: vwclamp(15,30,$sp-width);
          padding: vwclamp(22,44,$sp-width) vwclamp(5,10,$sp-width);
          border: vwclamp(1,2,$sp-width) solid transparent;
        }
      }
      &.back {
        input {
          font-size: vwclamp(15,30,$sp-width);
        }
      }
    }
  }
  /* =================================
    お問い合わせフォーム
  ================================= */
  .consultForm {
    &__item {
      flex-wrap: wrap;
      gap: vwclamp(15,30,$sp-width) 00;
      padding: vwclamp(25,50,$sp-width) 0;
      border-bottom: vwclamp(1,2,$sp-width) solid #AAAAAA;
      &:first-child {
        border-top: vwclamp(1,2,$sp-width) solid #AAAAAA;
      }
    }
    &__ttl {
      font-size: vwclamp(15,30,$sp-width);
      width: 100%;
      .sub {
        font-size: vwclamp(12,20,$sp-width);
      }
      span:not(.sub) {
        font-size: vwclamp(15,30,$sp-width);
        margin-left: vwclamp(12,24,$sp-width);
      }
    }
    &__list {
      font-size: vwclamp(15,30,$sp-width);
      width: 100%;
      * {
        font-size: vwclamp(15,30,$sp-width);
      }
      input:not([type="radio"]):not([type="checkbox"]),textarea,select {
        border-radius: 5px;
        padding: vwclamp(19,38,$sp-width) vwclamp(15,30,$sp-width);
      }
      .mwform-radio-field {
        margin-right: 1.5rem;
      }
      input[type="radio"] + span {
        padding-left: vwclamp(40,80,$sp-width);
      }
      input[type="radio"] + span::before {
        width: vwclamp(30,60,$sp-width);
        height: vwclamp(30,60,$sp-width);
        border: vwclamp(3,6,$sp-width) solid #c5c5c5;
      }
      input[type="radio"]:checked + span::after {
        width: vwclamp(18,36,$sp-width);
        height: vwclamp(18,36,$sp-width);
        left: vwclamp(6,12,$sp-width);
      }
      .mwform-checkbox-field {
        margin-right: 1.5rem;
      }
      input[type="checkbox"] + span {
        padding-left: vwclamp(40,80,$sp-width);
      }
      input[type="checkbox"] + span::before {
        width: vwclamp(30,60,$sp-width);
        height: vwclamp(30,60,$sp-width);
        border: vwclamp(3,6,$sp-width) solid #c5c5c5;
      }
      input[type="checkbox"]:checked + span::after {
        content: "";
        width: vwclamp(20,40);
        height: vwclamp(20,40);
        display: inline-block;
        mask-image: url(../img/icon_check_white.svg);
        mask-repeat: no-repeat;
        mask-size: contain;
        background-color: $color-theme;
        position: absolute;
        top: vwclamp(8,16);
        left: vwclamp(5,10);
      }
      textarea {
        min-height : 240px;
      }
      .error {
        font-size: vwclamp(15,30,$sp-width);
        margin-top: vwclamp(3,5,$sp-width);
      }
      .yoyaku {
        .rank {
          width: vwclamp(75,150,$sp-width);
        }
        .yoyakuBox {
          width: calc(100% - vwclamp(75,150,$sp-width));
          display: flex;
        }
        .yoyakudate {
          width: calc((100% - vwclamp(10,20,$sp-width)) / 2)!important;
        }
        .yoyakujikan {
          width: calc((100% - vwclamp(10,20,$sp-width)) / 2)!important;
          margin-left: vwclamp(10,20,$sp-width);
        }
        & + .yoyaku {
          margin-top: vwclamp(10,20,$sp-width);
        }
      }
    }
    & + .consultForm {
      margin-top: vwclamp(50,100,$sp-width);
    }
  }
  .confirmingTxt {
    margin: vwclamp(35,70,$sp-width) 0;
    p {
      font-size: vwclamp(15,30,$sp-width);
    }
  }
  .checkText {
    label {
      margin-right: vwclamp(10,20,$sp-width);
    }
  }
  .completedForm {
    .completedMessage {
      font-size: vwclamp(20,40,$sp-width);
      margin-top: vwclamp(50,100,$sp-width);
      &::after {
        height: vwclamp(2,4,$sp-width);
      }
    }
    p {
      margin-top: vwclamp(25,50,$sp-width);
    }
  }
}