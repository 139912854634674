@use "../global" as *;
/*=================================
  sidebar
=================================*/
.sidebar {
  > * + * {
    margin-top: vwclamp(50,100);
  }
  .sideLinkList {
    &__item {
      &:not(:first-child) {
        margin-top: vwclamp(20,40);
      }
      a {
        font-size: vwclamp(15,30);
        line-height: 1;
        color: $font-color-base;
        text-align: center;
        text-decoration: none;
        display: block;
        border: vwclamp(1,2) solid $font-color-base;
        background: #fff;
        padding: vwclamp(19,38) vwclamp(10,20);
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          width: vwclamp(15,30);
          height: vwclamp(11,22);
          mask-image: url(../img/icon_arrow.svg);
          background-color: $color-theme;
          mask-repeat: no-repeat;
          mask-position: center;
          mask-size: contain;
          position: absolute;
          top: 50%;
          right: vwclamp(19,38);
          transform: translateY(-50%);
          @include animation-base;
        }
        @include mq(pc) {
          &:hover {
            color: #fff;
            background: $color-theme;
            &::before {
              background-color: #fff;
              transform: translate(30%,-50%);
            }
          }
        }
      }
    }
  }
  .profileCard {
    &__img {
      display: block;
      margin-bottom: vwclamp(20,40);
    }
    &__name {
      font-size: vwclamp(30,60);
      font-weight: 500;
      line-height: 1;
      color: $font-color-base;
    }
    &__ruby {
      @include font-outfit;
      font-size: vwclamp(12,25);
      letter-spacing: .05em;
      line-height: 1;
      color: $color-theme;
      display: block;
      margin-bottom: vwclamp(10,20);
    }
  }
}

@media screen and (max-width: 1080px) {
  /*=================================
    sidebar
  =================================*/
  .sidebar {
    .sideLinkList {
      display: flex;
      overflow-x: scroll;
      word-break: keep-all;
      white-space: nowrap;
      gap: 0 vwclamp(15,30,$sp-width);
      padding: 0 vwclamp(25,50,$sp-width);
      &__item {
        &:not(:first-child) {
          margin-top: 0;
        }
        a {
          font-size: vwclamp(15,30,$sp-width);
          border: vwclamp(1,2,$sp-width) solid $font-color-base;
          padding: vwclamp(19,38,$sp-width) vwclamp(30,60,$sp-width) vwclamp(19,38,$sp-width) vwclamp(10,20,$sp-width);
          &::before {
            width: vwclamp(15,30,$sp-width);
            height: vwclamp(11,22,$sp-width);
            right: vwclamp(9,18,$sp-width);
          }
        }
      }
    }
    .profile {
      padding: vwclamp(50,100,$sp-width) vwclamp(25,50,$sp-width);
      background: #EFF6F7;
      display: none;
    }
    .profileCard {
      width: fit-content;
      max-width: 80%;
      margin: 0 auto;
      &__img {
        margin-bottom: vwclamp(25,50,$sp-width);
      }
      &__name {
        font-size: vwclamp(30,60,$sp-width);
      }
      &__ruby {
        font-size: vwclamp(12,25,$sp-width);
        margin-bottom: vwclamp(10,20,$sp-width);
      }
    }
  }
  .spMenuStatic {
    .sidebar {
      margin-top: vwclamp(50,100,$sp-width);
    }
  }
}