@use "../../global" as *;
/*=================================
  topPage
=================================*/
.home {
  .top--bgWrap {
    background: url(../img/top/top_bg01.png) no-repeat right top / 100%,url(../img/top/top_bg02.png) no-repeat left bottom -1px / 100%;
    position: relative;
    padding: vwclamp(70,140) 0 vwclamp(90,180);
  }
  .bottom--bgWrap {
    background: url(../img/top/top_bg03.png) no-repeat right top vwclamp(425,850) / 100%,url(../img/top/top_bg04.png) no-repeat left bottom / 100%;
    position: relative;
    padding: vwclamp(90,180) 0 vwclamp(170,214);
  }
  /* メインビジュアル */
  .kv {
    width: 100%;
    height: vw(1326);
    background: #fff;
    position: relative;
    opacity: 0;
    &__logo {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__imgBox {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &__img01 {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__img02 {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__img03 {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  /* サービス */
  .secService {
    &Main {
      margin-top: vwclamp(74,148);
      &__col {
        display: flex;
        gap: 0 vwclamp(70,140);
        padding: 0 vwclamp(20,170);
        &:first-child {
          position: relative;
          &::after {
            content: "";
            width: 50%;
            height: calc(100% + vwclamp(192,384));
            display: block;
            background: url(../img/top/top_bg_service_pc.png) no-repeat right bottom;
            background-size: cover;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 0;
            mix-blend-mode: darken;
          }
        }
        &:nth-child(2n) {
          flex-direction: row-reverse;
        }
        & + .secServiceMain__col {
          margin-top: vwclamp(100,200);
        }
      }
      &__img {
        width: vwclamp(400,1406);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__cont {
        width: calc(100% - vwclamp(400,1406) + vwclamp(70,140));
      }
      &__ttl {
        &--ruby {
          @include font-outfit;
          font-size: vwclamp(17,25);
          line-height: 2;
          letter-spacing: .05em;
        }
        &--main {
          font-size: vwclamp(40,80);
          font-weight: 500;
          color: $color-theme;
          line-height: 1.75;
          letter-spacing: .05em;
        }
      }
      &__text {
        font-size: vwclamp(18,35);
        line-height: 1.714285;
      }
      &__btn {
        margin-top: vwclamp(40,80);
        max-width: calc(100% - vwclamp(33,65));
        position: relative;
        z-index: 1;
      }
      &__link {
        font-size: vwclamp(18,35);
        font-weight: 400;
        line-height: 1.5;
        color: #fff;
        border: vwclamp(1,2) solid $color-theme;
        background: $color-theme;
        text-decoration: none;
        text-align: center;
        display: block;
        margin: 0 auto;
        padding: vwclamp(29,58) vwclamp(40,80) vwclamp(29,58) vwclamp(5,10);
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          width: vwclamp(22,44);
          height: vwclamp(15,30);
          mask-image: url(../img/icon_arrow.svg);
          background-color: #fff;
          mask-repeat: no-repeat;
          mask-position: center;
          mask-size: contain;
          position: absolute;
          top: 50%;
          right: vwclamp(5,34);
          transform: translateY(-50%);
          @include animation-base;
        }
        @include mq(pc) {
          &:hover {
            color: $color-theme;
            background: #fff;
            &::before {
              background-color: $color-theme;
              transform: translate(15%,-50%);
            }
          }
        }
        & + .secServiceMain__link {
          margin-top: vwclamp(40,80);
        }
      }
    }
    &Sub {
      padding: 0 vwclamp(20,170);
      margin-top: vwclamp(100,200);
      &__inner {
        background: #fff;
        padding: vwclamp(60,120) vwclamp(20,170);
        display: flex;
        flex-wrap: wrap;
        gap: 0 vwclamp(60,120);
      }
      &__col {
        width: calc((100% - vwclamp(120,240)) / 3);
      }
      &__img {
        width: 100%;
        display: block;
        margin: vwclamp(20,40) 0 vwclamp(25,50);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__ttl {
        margin-bottom: vwclamp(20,40);
        &--ruby {
          @include font-outfit;
          font-size: vwclamp(17,25);
          line-height: 2;
          line-height: 2.64;
          letter-spacing: .05em;
        }
        &--main {
          font-size: vwclamp(30,60);
          font-weight: 500;
          color: $color-theme;
          line-height: 1.616666;
          letter-spacing: .03em;
        }
      }
      &__text {
        font-size: vwclamp(18,35);
        line-height: 1.714285;
      }
      &__btn {
        margin-top: vwclamp(25,50);
      }
      &__link {
        font-size: vwclamp(15,30);
        font-weight: 400;
        line-height: 1.5;
        color: $font-color-base;
        border: vwclamp(1,2) solid $font-color-base;
        background: #fff;
        text-decoration: none;
        text-align: center;
        display: inline-block;
        margin: 0 auto;
        padding: vwclamp(19,38) vwclamp(50,110);
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          width: vwclamp(15,30);
          height: vwclamp(10,21);
          mask-image: url(../img/icon_arrow.svg);
          background-color: $color-theme;
          mask-repeat: no-repeat;
          mask-position: center;
          mask-size: contain;
          position: absolute;
          top: 50%;
          right: vwclamp(5,38);
          transform: translateY(-50%);
          @include animation-base;
        }
        @include mq(pc) {
          &:hover {
            color: #fff;
            background: $color-theme;
            &::before {
              background-color: #fff;
              transform: translate(15%,-50%);
            }
          }
        }
      }
    }
  }
  /* お知らせ */
  .secInfo {
    padding: 0 vwclamp(20,170);
    margin-top: vwclamp(100,200);
    .infoBoxWrap {
      background: url(../img/top/top_bg_info_pc.png) no-repeat center center;
      background-size: cover;
      padding: vwclamp(74,148) vwclamp(58,116);
      position: relative;
    }
    .infoBox {
      display: flex;
      color: #fff;
      &__ttl {
        font-size: vwclamp(35,70);
        font-weight: 400;
        line-height: 2;
        letter-spacing: .04em;
        color: #fff;
        width: vwclamp(264,527);
        &::before {
          content: "NOTICE";
          @include font-outfit;
          font-size: vwclamp(12,25);
          line-height: 1;
          letter-spacing: .05em;
          color: #fff;
          margin-bottom: vwclamp(17,34);
          display: block;
        }
      }
      .infoList {
        width: calc(100% - vwclamp(264,527));
        height: vwclamp(130,260);
        padding-right: vwclamp(10,20);
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        &::-webkit-scrollbar {
          width: vwclamp(4,10);
        }
        &::-webkit-scrollbar-track {
          background-color: #4DB0F5;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #fff;
        }
        &__day {
          font-size: vwclamp(17,35);
          color: #fff;
          width: vwclamp(133,266);
          position: relative;
          &::after {
            content: "";
            display: inline-block;
            width: vwclamp(1.5,3);
            height: vwclamp(17,34);
            background: #fff;
            position: absolute;
            top: vwclamp(10,20);
            right: vwclamp(22,44);
            transform: rotate(-45deg);
          }
          &:nth-of-type(n + 2) {
            margin-top: vwclamp(17,34);
          }
        }
        &__text {
          width: calc(100% - vwclamp(133,266));
          &:nth-of-type(n + 2) {
            margin-top: vwclamp(17,34);
          }
          a {
            font-size: vwclamp(17,35);
            color: #fff;
            text-decoration: none;
            @include mq(pc) {
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .infoLink {
      font-size: vwclamp(17,35);
      line-height: 1;
      color: #fff;
      text-decoration: none;
      position: absolute;
      right: vwclamp(32,64);
      bottom: vwclamp(30,60);
      &::after {
        content: "";
        width: vwclamp(15,30);
        height: vwclamp(10,20);
        mask-image: url(../img/icon_arrow.svg);
        background-color: #fff;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-left: vwclamp(7,14);
        @include animation-base;
      }
      @include mq(pc) {
        &:hover {
          &::after {
            transform: translateX(15%);
          }
        }
      }
    }
  }
  .secMember {
    padding: 0 vwclamp(20,170);
    .memberSlider {
      width: calc(100% + vwclamp(25,50,$sp-width));
    }
  }
  .secAbout {
    padding: 0 vwclamp(20,170);
    margin: vwclamp(120,240) auto;
    &__inner {
      padding: vwclamp(20,157) vwclamp(20,170) vwclamp(20,170);
      background: $color-theme;
    }
    &Main {
      display: flex;
      flex-wrap: wrap;
      gap: vwclamp(64,128) vwclamp(35,70);
      &__ttl {
        color: $font-color-base;
        text-decoration: none;
        &--ruby {
          @include font-outfit;
          font-size: vwclamp(13,25);
          line-height: 2;
          letter-spacing: .05em;
        }
        &--main {
          font-size: vwclamp(20,70);
          font-weight: 400;
          line-height: 1.42857;
        }
        a {
          color: $font-color-base;
          text-decoration: none;
        }
      }
      &__img {
        img {
          @include animation-base;
        }
      }
      &__cont {
        position: absolute;
      }
      &__col {
        &:nth-child(-n + 2) {
          width: calc((100% - vwclamp(35,70)) / 2);
          background: #fff;
          position: relative;
          overflow: hidden;
          .secAboutMain__cont {
            width: vwclamp(190,380);
            top: 50%;
            left: vwclamp(100,430);
            transform: translateY(-50%);
          }
          &::after {
            content: "";
            width: vwclamp(30,120);
            height: vwclamp(30,120);
            display: inline-block;
            background: url(../img/top/about_arrow.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            top: 50%;
            right: vwclamp(5,40);
            transform: translateY(-50%);
            @include animation-base;
          }
          @include mq(pc) {
            &:hover {
              &::after {
                transform: translate(5%, -50%);
              }
              .secAboutMain__img img {
                transform: scale(1.1);
              }
            }
          }
        }
        &:nth-child(n + 3) {
          width: calc((100% - vwclamp(70,140)) / 3);
          background: #F7F7F7;
          position: relative;
          overflow: hidden;
          .secAboutMain__cont {
            width: vwclamp(200,400);
            left: vwclamp(30,60);
            bottom: vwclamp(30,60);
          }
          &::after {
            content: "";
            width: vwclamp(30,120);
            height: vwclamp(30,120);
            display: inline-block;
            background: url(../img/top/about_arrow.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            right: vwclamp(5,60);
            bottom: vwclamp(5,60);
            @include animation-base;
          }
          @include mq(pc) {
            &:hover {
              &::after {
                transform: translateX(5%);
              }
              .secAboutMain__img img {
                transform: scale(1.1);
              }
            }
          }
        }
      }
    }
    &Sub {
      margin-top: vwclamp(55,110);
      &__video {
        width: 100%;
        text-align: center;
        background: #191919;
        iframe {
          width: 78%;
          height: 80%;
          aspect-ratio: 16 / 9;
          vertical-align: bottom;
        }
      }
    }
  }
  .secAccess {
    &__inner {
      padding: 0 vwclamp(20,170);
    }
    &__cont {
      padding: vwclamp(20,100);
      background: #fff;
      display: flex;
      gap: vwclamp(20,100);
    }
    &__col {
      width: calc(100% - vwclamp(20,100) / 2);
    }
    &__gMap {
      aspect-ratio: 1000 / 463;
      iframe {
        max-width: 100%;
        max-height: 350px;
        border: none;
      }
    }
    &__info {
      &--ruby {
        @include font-outfit;
        font-size: vwclamp(12,25);
        line-height: 2;
        letter-spacing: .05em;
        color: $color-theme;
      }
      &--name {
        font-size: vwclamp(22,45);
        font-weight: 500;
        line-height: 2.46666;
      }
      &--add {
        font-size: vwclamp(18,35);
        line-height: 1.71428;
      }
    }
  }
  .secMessage {
    margin-top: vwclamp(110,220);
    &__inner {
      padding: 0 vwclamp(20,170);
    }
    &__cont {
      display: flex;
      gap: vwclamp(15,114);
    }
    &__col {
      width: calc((100% - vwclamp(15,342)) / 4);
      padding: vwclamp(10,72) vwclamp(10,46) vwclamp(10,54);
      background: #fff;
      display: flex;
      flex-direction: column;
    }
    &__icon {
      width: calc(225 / 421 * 100%);
      margin: 0 auto vwclamp(29,58);
      display: block;
    }
    &__ttl {
      font-size: vwclamp(16,40);
      font-weight: 500;
      line-height: 2;
      color: $color-theme;
      margin-bottom: vwclamp(10,20);
    }
    &__text {
      font-size: vwclamp(14,35);
      line-height: 1.71428;
      margin-top: auto;
    }
    &__btn {
      font-size: vwclamp(19,38);
      font-weight: 500;
      line-height: 1.5;
      color: #fff;
      text-align: center;
      text-decoration: none;
      background: $color-theme;
      display: block;
      padding: vwclamp(42,84);
      width: vwclamp(19,1653);
      margin: vwclamp(115,230) auto 0;
      border: 1px solid transparent;
      @include mq(pc) {
        &:hover {
          color: $font-color-base;
          border-color: $font-color-base;
          background: #fff;
        }
      }
    }
  }
}

@include mq(sp) {
  /*=================================
    topPage
  =================================*/
  .home {
    .top--bgWrap {
      background: none;
      padding: 0;
    }
    .bottom--bgWrap {
      background: none;
      padding: 0;
    }
    /* メインビジュアル */
    .kv {
      height: vw(4500);
      &__logo {
        img {
          object-fit: contain;
        }
      }
    }
    /* サービス */
    .secService {
      padding: vwclamp(47,94,$sp-width) 0 vwclamp(50,100,$sp-width);
      &Main {
        margin-top: vwclamp(49,98,$sp-width);
        &__col {
          display: block;
          padding: 0 vwclamp(25,50,$sp-width);
          &:first-child {
            background: url(../img/top/top_bg01_sp.png) no-repeat left bottom / 100%;
            &::after {
              content: none;
            }
            .secServiceMain__cont {
              position: relative;
              &::before {
                content: "";
                width: vwclamp(200,714,$sp-width);
                height: vwclamp(200,782,$sp-width);
                display: block;
                background: url(../img/top/top_bg_service_sp.png) no-repeat right bottom;
                background-size: cover;
                position: absolute;
                top: vwclamp(-45,-90,$sp-width);
                right: vwclamp(-25,-50,$sp-width);
                z-index: 0;
                mix-blend-mode: darken;
              }
            }
          }
          &:nth-child(2) {
            background: url(../img/top/top_bg02_sp.png) no-repeat left top / 100%;
          }
          & + .secServiceMain__col {
            padding-top: vwclamp(50,100,$sp-width);
            margin-top: 0;
          }
        }
        &__img {
          width: 100%;
          margin-bottom: vwclamp(20,40,$sp-width);
          display: block;
        }
        &__cont {
          width: 100%;
        }
        &__ttl {
          &--ruby {
            font-size: vwclamp(10,20,$sp-width);
            line-height: 2.5;
          }
          &--main {
            font-size: vwclamp(35,70,$sp-width);
            line-height: 2;
            letter-spacing: 0;
          }
        }
        &__text {
          font-size: vwclamp(15,30,$sp-width);
          line-height: 2;
        }
        &__btn {
          margin-top: vwclamp(25,50,$sp-width);
          max-width: calc(100% - vwclamp(20,40,$sp-width));
        }
        &__link {
          font-size: vwclamp(15,30,$sp-width);
          border: vwclamp(1,2,$sp-width) solid $color-theme;
          padding: vwclamp(29,45,$sp-width) vwclamp(30,60,$sp-width) vwclamp(29,45,$sp-width) vwclamp(2.5,5,$sp-width);
          &::before {
            width: vwclamp(15,30,$sp-width);
            height: vwclamp(10,20,$sp-width);
            right: vwclamp(5,20,$sp-width);
          }
          & + .secServiceMain__link {
            margin-top: vwclamp(15,30,$sp-width);
          }
        }
      }
      &Sub {
        padding: vwclamp(50,100,$sp-width) vwclamp(25,50,$sp-width) 0;
        margin-top: 0;
        background: url(../img/top/top_bg03_sp.png) no-repeat left top;
        background-size: cover;
        &__inner {
          padding: vwclamp(40,80,$sp-width) vwclamp(25,50,$sp-width);
          gap: vwclamp(50,100,$sp-width) 0;
        }
        &__col {
          width: 100%;
        }
        &__img {
          margin: vwclamp(20,40,$sp-width) 0;
        }
        &__ttl {
          margin-bottom: vwclamp(20,40,$sp-width);
          &--ruby {
            font-size: vwclamp(10,20,$sp-width);
            line-height: 2;
            line-height: 3.3;
          }
          &--main {
            font-size: vwclamp(30,60,$sp-width);
            letter-spacing: 0;
          }
        }
        &__text {
          font-size: vwclamp(15,30,$sp-width);
          line-height: 2;
        }
        &__btn {
          margin-top: vwclamp(20,40,$sp-width);
          max-width: 70%;
        }
        &__link {
          font-size: vwclamp(15,30,$sp-width);
          border: vwclamp(1,2,$sp-width) solid $font-color-base;
          padding: vwclamp(20,40,$sp-width);
          display: block;
          &::before {
            width: vwclamp(15,30,$sp-width);
            height: vwclamp(10,20,$sp-width);
            right: vwclamp(5,40,$sp-width);
          }
        }
      }
    }
    /* お知らせ */
    .secInfo {
      padding: 0 vwclamp(25,50,$sp-width);
      margin-top: 0;
      margin-bottom: vwclamp(50,100,$sp-width);
      .infoBoxWrap {
        background: url(../img/top/top_bg_info_sp.png) no-repeat center bottom;
        background-size: cover;
        padding: vwclamp(50,100,$sp-width) vwclamp(25,50,$sp-width) vwclamp(25,50,$sp-width);
      }
      .infoBox {
        display: block;
        &__ttl {
          font-size: vwclamp(35,70,$sp-width);
          letter-spacing: 0;
          width: auto;
          &::before {
            font-size: vwclamp(10,20,$sp-width);
            margin-bottom: vwclamp(10,20,$sp-width);
          }
        }
        .infoList {
          width: 100%;
          height: auto;
          padding-right: 0;
          overflow-y: initial;
          display: block;
          &__day {
            font-size: vwclamp(15,30,$sp-width);
            width: initial;
            &::after {
              width: vwclamp(1,2,$sp-width);
              height: vwclamp(16,33,$sp-width);
              position: static;
              margin: 0 0 vwclamp(-4,-8,$sp-width) vwclamp(20,40,$sp-width);
            }
            &:nth-of-type(n + 2) {
              margin-top: vwclamp(25,50,$sp-width);
            }
          }
          &__text {
            width: 100%;
            &:nth-of-type(n + 2) {
              margin-top: 0;
            }
            a {
              font-size: vwclamp(15,30,$sp-width);
            }
          }
        }
      }
      .infoLink {
        font-size: vwclamp(17,35,$sp-width);
        background: #239CEF;
        display: block;
        text-align: center;
        padding: vwclamp(20,40,$sp-width) vwclamp(10,15,$sp-width);
        margin-top: vwclamp(35,70,$sp-width);
        position: static;
        &::after {
          content: none;
        }
      }
    }
    .secMember {
      padding: 0 vwclamp(25,50,$sp-width) vwclamp(50,100,$sp-width);
      background: url(../img/top/top_bg04_sp.png) no-repeat left bottom / 100%;
    }
    .secAbout {
      padding: 0;
      margin: 0;
      overflow: hidden;
      &__inner {
        padding: vwclamp(50,100,$sp-width) vwclamp(25,50,$sp-width) 0;
      }
      &Main {
        gap: vwclamp(25,50,$sp-width) 0;
        &__ttl {
          &--ruby {
            font-size: vwclamp(10,20,$sp-width);
            line-height: 2.5;
          }
          &--main {
            font-size: vwclamp(25,50,$sp-width);
            line-height: 1.33333;
          }
        }
        &__cont {
          position: absolute;
        }
        &__col {
          &:first-child {
            background: #fff;
            position: relative;
            .secAboutMain__cont {
              top: initial;
              bottom: vwclamp(22,45,$sp-width);
              left: 0;
              transform: translateY(0);
              width: 100%!important;
              padding-left: vwclamp(24,47,$sp-width)!important;
            }
            &::after {
              width: vwclamp(40,80,$sp-width);
              height: vwclamp(40,80,$sp-width);
              top: initial;
              right: vwclamp(15,30,$sp-width);
              bottom: vwclamp(30,60,$sp-width);
              transform: translateY(0);
            }
          }
          &:nth-child(-n + 2) {
            width: 100%;
            .secAboutMain__cont {
              width: 80%;
              left: 0;
              padding-left: vwclamp(125,250,$sp-width);
            }
            &::after {
              width: vwclamp(40,80,$sp-width);
              height: vwclamp(40,80,$sp-width);
              right: vwclamp(15,30,$sp-width);
            }
          }
          &:nth-child(n + 3) {
            width: 100%;
            background: #fff;
            .secAboutMain__cont {
              width: 80%;
              bottom: initial;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              padding-left: vwclamp(125,250,$sp-width);
            }
            &::after {
              width: vwclamp(40,80,$sp-width);
              height: vwclamp(40,80,$sp-width);
              right: vwclamp(15,30,$sp-width);
              bottom: initial;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
      &Sub {
        margin-top: vwclamp(50,100,$sp-width);
        &__video {
          width: calc(100% + vwclamp(50,100,$sp-width));
          margin-left: vwclamp(-25,-50,$sp-width);
          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .secAccess {
      background: url(../img/top/top_bg05_sp.png) no-repeat center top / 100%;
      padding-top: vwclamp(50,100,$sp-width);
      &__inner {
        padding: 0 vwclamp(25,50,$sp-width);
      }
      &__cont {
        padding: vwclamp(25,50,$sp-width);
        flex-wrap: wrap;
        gap: vwclamp(50,100,$sp-width) 0;
      }
      &__col {
        width: 100%;
      }
      &__gMap {
        aspect-ratio: auto;
        iframe {
          max-height: 250px;
        }
      }
      &__info {
        &--ruby {
          font-size: vwclamp(10,20,$sp-width);
          line-height: 3.3;
        }
        &--name {
          font-size: vwclamp(20,40,$sp-width);
          line-height: 2.425;
        }
        &--add {
          font-size: vwclamp(15,30,$sp-width);
          line-height: 2;
        }
      }
    }
    .secMessage {
      margin-top: vwclamp(50,100,$sp-width);
      background: url(../img/top/top_bg06_sp.png) no-repeat center bottom vwclamp(130,260,$sp-width) / 100%;
      padding-bottom: vwclamp(50,100,$sp-width);
      &__inner {
        padding: 0 vwclamp(25,50,$sp-width);
      }
      &__cont {
        display: flex;
        flex-wrap: wrap;
        gap: vwclamp(25,50,$sp-width) 0;
      }
      &__col {
        width: 100%;
        padding: vwclamp(20,40,$sp-width);
        display: block;
        position: relative;
      }
      &__icon {
        width: calc(150 / 658 * 100%);
        margin: 0;
        position: absolute;
        top: vwclamp(20,40,$sp-width);
        left: vwclamp(20,40,$sp-width);
      }
      &__ttl {
        font-size: vwclamp(20,40,$sp-width);
        line-height: 1.5;
        margin-bottom: vwclamp(15,30,$sp-width);
        width: calc(100% - (200 / 658 * 100%));
        margin-left: auto;
      }
      &__text {
        font-size: vwclamp(15,30,$sp-width);
        line-height: 2;
        width: calc(100% - (200 / 658 * 100%));
        margin-left: auto;
      }
      &__btn {
        font-size: vwclamp(15,30,$sp-width);
        padding: vwclamp(18,36,$sp-width) vwclamp(10,20,$sp-width);
        width: 70%;
        margin: vwclamp(50,100,$sp-width) auto 0;
      }
    }
  }
}