@use "variables" as *;
@use "breakpoint" as *;
@use "font-family" as *;
@mixin singleStyle-base {
  h1,h2,h3,h4,h5,h6 {
    clear: both;
  }
  a:not([class]) {
    font-weight: 700;
  }
  p:not([class]) {
    font-size: vwclamp(18,35);
    line-height: 2;
    margin-bottom: vwclamp(50,100);
  }
  h1:not([class]),h1.wp-block-heading {
    font-size: vwclamp(35,70);
    font-weight: 400;
    color: #fff;
    line-height: 1.75;
    margin-bottom: vwclamp(50,100);
    padding: vwclamp(20,40) vwclamp(50,100);
    background: $color-theme;
    width: calc(100% + vwclamp(100,200));
    margin-left: vwclamp(-50,-100);
    margin-top: vwclamp(50,100);
    margin-bottom: vwclamp(50,100);
    @include mq(tab) {
      width: calc(100% + 200px);
      padding: vwclamp(20,40) 100px;
      margin-left: -100px;
    }
  }
  h2:not([class]),h2.wp-block-heading {
    font-size: vwclamp(35,70);
    font-weight: 400;
    color: #fff;
    line-height: 1.75;
    margin-bottom: vwclamp(50,100);
    padding: vwclamp(20,40) vwclamp(50,100);
    background: $color-theme;
    width: calc(100% + vwclamp(100,200));
    margin-left: vwclamp(-50,-100);
    margin-top: vwclamp(50,100);
    margin-bottom: vwclamp(50,100);
    @include mq(tab) {
      width: calc(100% + 200px);
      padding: vwclamp(20,40) 100px;
      margin-left: -100px;
    }
  }
  h3:not([class]),h3.wp-block-heading {
    font-size: vwclamp(30,60);
    font-weight: 500;
    line-height: 1.616666;
    color: $color-theme;
    border-bottom: 2px solid #AAAAAA;
    padding-bottom: vwclamp(20,40);
    margin-top: vwclamp(50,100);
    margin-bottom: vwclamp(50,100);
  }
  h4:not([class]),h4.wp-block-heading {
    font-size: vwclamp(25,50);
    font-weight: 500;
    border-left: vwclamp(10,20) solid $color-theme;
    padding-left: vwclamp(10,20);
    margin-top: vwclamp(50,100);
    margin-bottom: vwclamp(25,50);
  }
  h5:not([class]),h5.wp-block-heading {
    font-size: vwclamp(20,40);
    font-weight: 500;
    color: $color-theme;
    margin-top: vwclamp(50,100);
    margin-bottom: vwclamp(25,50);
  }
  h6:not([class]),h6.wp-block-heading {
    font-size: vwclamp(18,35);
    font-weight: 500;
    line-height: 1.77777;
    color: $color-theme;
    margin-top: vwclamp(50,100);
    margin-bottom: vwclamp(25,50);
    padding-left: vwclamp(20,40);
    position: relative;
    &::before {
      content: "";
      width: vwclamp(10,20);
      height: vwclamp(10,20);
      display: inline-block;
      background: $color-theme;
      transform: rotate(45deg);
      position: absolute;
      top: vwclamp(12,24);
      left: 0;
    }
  }
  blockquote {
    position: relative;
    padding: vwclamp(50,100) vwclamp(25,50);
    margin-top: vwclamp(50,100);
    margin-bottom: vwclamp(25,50);
    box-sizing: border-box;
    font-style: italic;
    color: #464646;
    border: 2px solid $color-gray;
    &::before {
      content: "“";
      font-size: vwclamp(50,100);
      font-weight: 700;
      color: $color-theme;
      display: inline-block;
      font-style: normal;
      width: vwclamp(50,100);
      height: vwclamp(50,100);
      line-height: 1.3;
      text-align: center;
      background: $color-gray;
      border-radius: 50px;
      position: absolute;
      top: -25px;
      left: 15px;
    }
    @for $i from 1 through 6 {
      h#{$i}{
        font-size: vwclamp(35,70)!important;
        width: 100%!important;
        margin: 0 0 vwclamp(25,50)!important;
        position: relative;
      }
    }
    p {
      font-style: normal;
    }
    cite {
      display: block;
      text-align: right;
      line-height: 1;
      color: #888888;
      font-size: 0.9em;
      margin: 10px 0 0;
    }
  }
  .btnWrap {
    clear: both;
  }
  img:not([class]),&.wp-block-heading {
    display: block;
    margin: vwclamp(40,80) auto vwclamp(50,100);
  }
  img {
    &.alignleft,&.imgleft {
      float: left;
      margin: 0 vwclamp(50,100) vwclamp(50,100) 0;
      max-width: 38%;
    }
    &.alignright,&.imgright {
      float: right;
      margin: 0 0 vwclamp(50,100) vwclamp(50,100);
      max-width: 38%;
    }
  }
  figure {
    &.alignleft,&.imgleft {
      float: left;
      margin: 0 vwclamp(50,100) vwclamp(50,100) 0;
      max-width: 38%;
    }
    &.alignright,&.imgright {
      float: right;
      margin: 0 0 vwclamp(50,100) vwclamp(50,100);
      max-width: 38%;
    }
  }
  ul:not([class]) {
    margin: vwclamp(25,50) 0;
    padding: vwclamp(35,70);
    border: 2px solid $font-color-base;
    clear: both;
    background: #fff;
    li {
      font-size: vwclamp(18,35);
      padding-left: vwclamp(29,58);
      position: relative;
      &::before {
        content: "";
        width: vwclamp(15,30);
        height: vwclamp(15,30);
        border-radius: 50px;
        background: $color-theme;
        display: inline-block;
        position: absolute;
        top: vwclamp(5,22);
        left: 0;
      }
      & + li {
        margin-top: vwclamp(20,40);
      }
    }
    @for $i from 1 through 6 {
      h#{$i}::before{
        content: none;
      }
    }
  }
  ol:not([class]) {
    margin: vwclamp(25,50) 0;
    padding: vwclamp(35,70);
    border: 2px solid $font-color-base;
    counter-reset: listNum;
    clear: both;
    background: #fff;
    li {
      font-size: vwclamp(18,35);
      padding-left: vwclamp(21,42);
      position: relative;
      &::before {
        @include font-outfit;
        counter-increment: listNum;
        content: counter(listNum);
        font-size: vwclamp(18,35);
        color: $color-theme;
        line-height: 2;
        display: inline-block;
        position: absolute;
        top: vwclamp(1,2);
        left: 0;
      }
      & + li {
        margin-top: vwclamp(20,40);
      }
    }
    @for $i from 1 through 6 {
      h#{$i}::before{
        content: none;
      }
    }
  }
  iframe {
    max-width: 100%;
    border: none;
  }
  //default style
  .wp-block-file:not(.wp-element-button) {
    font-size: vwclamp(18,35);
  }
  .wp-block-file__button {
    padding: 11px 30px;
    display: inline-block;
  }
  @include mq(sp) {
    p:not([class]) {
      font-size: vwclamp(15,30,$sp-width);
      margin-bottom: vwclamp(20,40,$sp-width);
    }
    h1:not([class]),h1.wp-block-heading {
      font-size: vwclamp(22,45,$sp-width);
      line-height: 1.77777;
      margin-bottom: vwclamp(50,100,$sp-width);
      padding: vwclamp(18,35,$sp-width) vwclamp(23,47,$sp-width);
      width: calc(100% + vwclamp(50,100,$sp-width));
      margin-left: vwclamp(-25,-50,$sp-width);
      margin-top: vwclamp(50,100,$sp-width);
      margin-bottom: vwclamp(50,100,$sp-width);
    }
    h2:not([class]),h2.wp-block-heading {
      font-size: vwclamp(22,45,$sp-width);
      line-height: 1.77777;
      margin-bottom: vwclamp(50,100,$sp-width);
      padding: vwclamp(18,35,$sp-width) vwclamp(23,47,$sp-width);
      width: calc(100% + vwclamp(50,100,$sp-width));
      margin-left: vwclamp(-25,-50,$sp-width);
      margin-top: vwclamp(50,100,$sp-width);
      margin-bottom: vwclamp(50,100,$sp-width);
    }
    h3:not([class]),h3.wp-block-heading {
      font-size: vwclamp(20,40,$sp-width);
      line-height: 1.5;
      padding-bottom: vwclamp(20,40,$sp-width);
      margin-top: vwclamp(50,100,$sp-width);
      margin-bottom: vwclamp(50,100,$sp-width);
    }
    h4:not([class]),h4.wp-block-heading {
      font-size: vwclamp(18,35,$sp-width);
      border-left: vwclamp(7,15,$sp-width) solid $color-theme;
      padding-left: vwclamp(7,15,$sp-width);
      margin-top: vwclamp(50,100,$sp-width);
      margin-bottom: vwclamp(25,50,$sp-width);
    }
    h5:not([class]),h5.wp-block-heading {
      font-size: vwclamp(18,35,$sp-width);
      margin-top: vwclamp(50,100,$sp-width);
      margin-bottom: vwclamp(25,50,$sp-width);
    }
    h6:not([class]),h6.wp-block-heading {
      font-size: vwclamp(18,35,$sp-width);
      margin-top: vwclamp(50,100,$sp-width);
      margin-bottom: vwclamp(25,50,$sp-width);
      padding-left: vwclamp(20,40,$sp-width);
      &::before {
        width: vwclamp(10,20,$sp-width);
        height: vwclamp(10,20,$sp-width);
        top: vwclamp(12,24,$sp-width);
      }
    }
    blockquote {
      position: relative;
      padding: vwclamp(50,100,$sp-width) vwclamp(25,50),$sp-width;
      margin-top: vwclamp(50,100,$sp-width);
      margin-bottom: vwclamp(25,50,$sp-width);
      &::before {
        font-size: vwclamp(50,100,$sp-width);
        width: vwclamp(50,100,$sp-width);
        height: vwclamp(50,100,$sp-width);
      }
      @for $i from 1 through 6 {
        h#{$i}{
          font-size: vwclamp(20,40,$sp-width)!important;
          margin: 0 0 vwclamp(25,50,$sp-width)!important;
        }
      }
    }
    img:not([class]),&.wp-block-heading {
      margin: vwclamp(50,100,$sp-width) auto;

    }
    img {
      &.alignleft,&.imgleft {
        float: none;
        margin: 0 auto;
        display: block;
        max-width: 100%;
      }
      &.alignright,&.imgright {
        float: none;
        margin: 0 auto;
        display: block;
        max-width: 100%;
      }
    }
    figure {
      &.alignleft,&.imgleft {
        float: none;
        margin: 0 auto;
        display: block;
        max-width: 100%;
      }
      &.alignright,&.imgright {
        float: none;
        margin: 0 auto;
        display: block;
        max-width: 100%;
      }
    }
    ul:not([class]) {
      margin: vwclamp(25,50,$sp-width) 0;
      padding: vwclamp(25,50,$sp-width);
      li {
        font-size: vwclamp(18,35,$sp-width);
        padding-left: vwclamp(29,58,$sp-width);
        &::before {
          width: vwclamp(15,30,$sp-width);
          height: vwclamp(15,30,$sp-width);
          top: vwclamp(5,22,$sp-width);
        }
        & + li {
          margin-top: vwclamp(20,40,$sp-width);
        }
      }
    }
    ol:not([class]) {
      margin: vwclamp(25,50,$sp-width) 0;
      padding: vwclamp(25,50,$sp-width);
      li {
        font-size: vwclamp(18,35,$sp-width);
        padding-left: vwclamp(21,42,$sp-width);
        &::before {
          font-size: vwclamp(18,35,$sp-width);
          top: vwclamp(1,2,$sp-width);
        }
        & + li {
          margin-top: vwclamp(20,40,$sp-width);
        }
      }
    }
    //default style
    .wp-block-file:not(.wp-element-button) {
      font-size: vwclamp(18,35,$sp-width);
    }
    .wp-block-file__button {
      padding: 20px 15px;
    }
  }
}
@mixin singleStyle-parts {
  /*---------------------
    メインビジュアル
  ---------------------*/
  .articleMv {
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    img {
      margin: vwclamp(25,50) auto!important;
    }
  }
  .date {
    display: flex;
    justify-content: flex-end;
    * {
      font-size: vwclamp(12,20)!important;
      line-height: 1;
      color: #AAAAAA;
      & + * {
        margin: 0 0 0 1rem!important;
      }
    }
  }
  @include mq(sp) {
    /*---------------------
      メインビジュアル
    ---------------------*/
    .date {
      justify-content: flex-start;
      * {
        font-size: 1.1rem!important;
      }
    }
  }
}