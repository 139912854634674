@charset "UTF-8";


@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes vibrate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes vibrate02 {
  0% {
    transform: rotate(0deg) scale(1.2);
  }
  5% {
    transform: rotate(15deg) scale(1.2);
  }
  10% {
    transform: rotate(-15deg) scale(1.2);
  }
  15% {
    transform: rotate(15deg) scale(1.2);
  }
  20% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
@keyframes burger-show {
  0% {
    right: -50%;
  }
  100% {
    right: 0%;
  }
}
@keyframes header-show {
  0% {
    opacity: 0;
    top: -100px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
a:hover {
  outline: none;
}
a:active {
  outline: none;
}
a:focus {
  outline: none;
}

@media screen and (min-width: 860px) {
  /* =================================
    hover
  ================================= */
  a {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .widelink {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    cursor: pointer;
  }
  .widelink .widelink__cover {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__cover::before {
    content: "詳しく見る";
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: 0.06em;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 10px 0;
    width: 55%;
    border: 1px solid #fff;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__cover::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(31, 31, 31, 0.5);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__menu {
    position: relative;
    overflow: hidden;
    display: block;
  }
  .widelink .widelink__menu::before {
    content: "詳しく見る";
    font-size: 1.3rem;
    font-weight: 700;
    color: #fff;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 15px 9%;
    border: 1px solid #fff;
    border-radius: 50px;
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
    transition-property: opacity, bottom;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__article::before {
    content: "";
    display: inline-block;
    width: 130px;
    height: 24px;
    background: url(../img/link_more.png) no-repeat center center;
    opacity: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(0, 84, 142, 0.7);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink:hover a {
    text-decoration: none;
  }
  .widelink:hover .widelink__cover::before, .widelink:hover .widelink__article::before {
    opacity: 1;
    top: 50%;
  }
  .widelink:hover .widelink__cover::after, .widelink:hover .widelink__article::after {
    opacity: 1;
  }
  .widelink:hover .widelink__menu::before {
    opacity: 1;
    bottom: 10%;
  }
  .hoverBg .widelink__cover::before {
    content: none;
  }
  .hoverBg .widelink__cover::after {
    content: none;
  }
  .hoverBg:hover {
    background: #e7edf8;
  }
}
/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
  text-align: center;
}
.btnWrap.center {
  text-align: center;
}
.btnWrap.right {
  text-align: right;
}
.btnWrap > * + * {
  margin-top: 9px;
}

.btn--border {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  font-weight: 400;
  line-height: 1;
  color: #191919;
  border: clamp(0.1rem, 0.073vw, 0.2rem) solid #191919;
  background: #fff;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: clamp(2.9rem, 2.123vw, 5.8rem) 1.7441860465%;
  position: relative;
}
.btn--border::before {
  content: "";
  display: inline-block;
  width: clamp(1.9rem, 1.391vw, 3.8rem);
  height: clamp(1.3rem, 0.952vw, 2.6rem);
  mask-image: url(../img/icon_arrow.svg);
  background-color: #0080D9;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  position: absolute;
  top: 50%;
  right: clamp(2.1rem, 1.537vw, 4.2rem);
  transform: translateY(-50%);
}

@media screen and (min-width: 860px) {
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
  }
  .btnWrap > * + * {
    margin: 0 20px;
  }
  .btn--border {
    font-size: clamp(2rem, 1.464vw, 4rem);
    border-width: clamp(0.2rem, 0.146vw, 0.4rem);
    padding: clamp(3.5rem, 2.562vw, 7rem) clamp(2rem, 2.562vw, 7rem);
    max-width: 70%;
  }
  .btn--border::before {
    width: clamp(2.7rem, 1.977vw, 5.4rem);
    height: clamp(1.8rem, 1.354vw, 3.7rem);
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .btn--border:hover {
    color: #fff;
    background: #0080D9;
  }
  .btn--border:hover::before {
    background-color: #fff;
    transform: translate(15%, -50%);
  }
}
/*=================================
  title
=================================*/
.ttl--base {
  font-size: clamp(4rem, 2.928vw, 8rem);
  font-weight: 400;
  color: #191919;
  line-height: 1.2125;
  text-align: center;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}

.ttl--box {
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
.ttl--box__ruby {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.3rem, 0.915vw, 2.5rem);
  letter-spacing: 0.05em;
  line-height: 1;
}
.ttl--box__main {
  font-size: clamp(4rem, 2.928vw, 8rem);
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.05em;
  color: #0080D9;
}
.ttl--box__text {
  font-size: clamp(1.7rem, 1.281vw, 3.5rem);
  line-height: 1.71428;
}
.ttl--box.white .ttl--box__ruby {
  color: #fff;
}
.ttl--box.white .ttl--box__main {
  color: #fff;
}
.ttl--box.white .ttl--box__text {
  color: #fff;
}

.ttl--page {
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
.ttl--page__ruby {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.3rem, 0.915vw, 2.5rem);
  letter-spacing: 0.05em;
  line-height: 1;
}
.ttl--page__main {
  font-size: clamp(4.5rem, 3.294vw, 9rem);
  font-weight: 500;
  line-height: 1.6666;
  color: #0080D9;
}

.singlePage__mainTtl {
  font-size: clamp(4rem, 2.928vw, 8rem);
  font-weight: 400;
  line-height: 1.75;
  text-align: center;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}

@media screen and (min-width: 0) and (max-width: 860px) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: clamp(2.8rem, 6.395vw, 5.5rem);
    line-height: 1.454545;
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
    padding: 0 clamp(2.5rem, 5.814vw, 5rem);
  }
  .ttl--box {
    margin-bottom: clamp(4rem, 9.302vw, 8rem);
  }
  .ttl--box__ruby {
    font-size: clamp(1rem, 2.326vw, 2rem);
  }
  .ttl--box__main {
    font-size: clamp(3.5rem, 8.14vw, 7rem);
    line-height: 2;
    letter-spacing: 0;
  }
  .ttl--box__text {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    line-height: 2;
  }
  .ttl--page {
    padding: 0 clamp(2.5rem, 5.814vw, 5rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .ttl--page__ruby {
    font-size: clamp(1rem, 2.326vw, 2rem);
  }
  .ttl--page__main {
    font-size: clamp(3rem, 6.977vw, 6rem);
  }
  .singlePage__mainTtl {
    font-size: clamp(2.8rem, 6.395vw, 5.5rem);
    line-height: 1.454545;
    text-align: left;
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
    padding: 0 clamp(2.5rem, 5.814vw, 5rem);
  }
}
/*=================================
  tab
=================================*/
.tabMenu {
  display: flex;
}
.tabMenu__item {
  font-size: 2.2rem;
  line-height: 1.363636;
  font-weight: 500;
  color: #191919;
  background: #F3F3F3;
  padding: 30px 24px;
  text-align: center;
  width: 100%;
}
.tabMenu__item:not(.active) {
  cursor: pointer;
}
.tabMenu__item.active {
  background: #0080D9;
  color: #fff;
}
@media screen and (min-width: 860px) {
  .tabMenu__item:hover:not(.active) {
    background: #e7edf8;
  }
}

.tabItem {
  background: #fff;
  min-height: 190px;
}
.tabItem .tabList {
  display: none;
}
.tabItem .tabList.show {
  display: block !important;
}

@media screen and (max-width: 860px) {
  /*=================================
    tab
  =================================*/
  .tabMenu__item {
    font-size: 1.6rem;
    padding: 27px 1.976744186%;
  }
  .tabItem {
    min-height: inherit;
  }
}
/*=================================
  accordion
=================================*/
.js-accordion__btn {
  position: relative;
  cursor: pointer;
}

.js-accordion__item {
  display: none;
}

@media screen and (min-width: 0) and (max-width: 860px) {
  /*=================================
    accordion
  =================================*/
}
/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: clamp(5rem, 3.66vw, 10rem) 0;
  width: 100%;
  background: #fff;
  border-left: clamp(0.1rem, 0.073vw, 0.2rem) solid #AAAAAA;
  border-right: clamp(0.1rem, 0.073vw, 0.2rem) solid #AAAAAA;
}
table caption {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  font-weight: 700;
  padding: clamp(1rem, 0.732vw, 2rem) clamp(2.2rem, 1.647vw, 4.5rem);
  text-align: left;
}
table thead th, table thead td {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  font-weight: 700;
  color: #fff;
  padding: clamp(2.2rem, 1.647vw, 4.5rem);
  background: #0080D9;
}
table th {
  font-weight: 500;
  background: #EFF6F7;
  width: 30%;
}
table td {
  background: #fff;
  width: 70%;
}
table th, table td {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  line-height: 1.5;
  text-align: left;
  padding: clamp(2.2rem, 1.647vw, 4.5rem);
  vertical-align: top;
  border-top: clamp(0.1rem, 0.073vw, 0.2rem) solid #AAAAAA;
  border-bottom: clamp(0.1rem, 0.073vw, 0.2rem) solid #AAAAAA;
}
table.scroll {
  white-space: nowrap;
  overflow: auto;
  display: block;
  border: none;
}
table.scroll ::-webkit-scrollbar {
  height: 5px;
}
table.scroll ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
table.scroll ::-webkit-scrollbar-thumb {
  background: #bcbcbc;
}

.scrollAnnounce {
  display: none;
}

.wp-block-table {
  margin: clamp(5rem, 3.66vw, 10rem) 0;
}
.wp-block-table table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
  background: #fff;
  border-left: clamp(0.1rem, 0.073vw, 0.2rem) solid #AAAAAA !important;
  border-right: clamp(0.1rem, 0.073vw, 0.2rem) solid #AAAAAA !important;
}
.wp-block-table table caption {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  font-weight: 700;
  margin: clamp(2.5rem, 1.83vw, 5rem) 0;
  text-align: left;
}
.wp-block-table table thead th, .wp-block-table table thead td {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  font-weight: 700;
  color: #fff;
  padding: clamp(2.2rem, 1.647vw, 4.5rem);
  background: #0080D9;
}
.wp-block-table table th {
  font-weight: 500;
  background: #EFF6F7;
  width: 30%;
}
.wp-block-table table td {
  background: #fff;
  width: 70%;
}
.wp-block-table table th, .wp-block-table table td {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  line-height: 1.5;
  text-align: left;
  padding: clamp(2.2rem, 1.647vw, 4.5rem);
  vertical-align: top;
  border-top: clamp(0.1rem, 0.073vw, 0.2rem) solid #AAAAAA !important;
  border-bottom: clamp(0.1rem, 0.073vw, 0.2rem) solid #AAAAAA !important;
  border-left: none !important;
  border-right: none !important;
}
.wp-block-table table.scroll {
  white-space: nowrap;
  overflow: auto;
  display: block;
  border: none;
}
.wp-block-table table.scroll::-webkit-scrollbar {
  height: clamp(0.5rem, 0.366vw, 1rem);
}
.wp-block-table table.scroll::-webkit-scrollbar-track {
  background-color: #4DB0F5;
}
.wp-block-table table.scroll::-webkit-scrollbar-thumb {
  background-color: #fff;
}

.wp-block-table td, .wp-block-table th {
  border: initial;
}

@media screen and (min-width: 0) and (max-width: 860px) {
  /*=================================
    table
  =================================*/
  table {
    margin: clamp(2rem, 4.651vw, 4rem) 0 clamp(2.5rem, 5.814vw, 5rem);
    border-left: clamp(0.1rem, 0.233vw, 0.2rem) solid #AAAAAA;
    border-right: clamp(0.1rem, 0.233vw, 0.2rem) solid #AAAAAA;
  }
  table:not(.noscroll) {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
  }
  table caption {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    padding: clamp(1.1rem, 2.326vw, 2rem) clamp(1.1rem, 2.558vw, 2.2rem);
  }
  table thead th, table thead td {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    padding: clamp(2.2rem, 5.233vw, 4.5rem) clamp(1.1rem, 2.558vw, 2.2rem);
  }
  table th {
    width: 35%;
  }
  table td {
    width: 65%;
  }
  table th, table td {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    padding: clamp(2.2rem, 5.233vw, 4.5rem) clamp(1.1rem, 2.558vw, 2.2rem);
    border-top: clamp(0.1rem, 0.233vw, 0.2rem) solid #AAAAAA;
    border-bottom: clamp(0.1rem, 0.233vw, 0.2rem) solid #AAAAAA;
  }
  table::-webkit-scrollbar {
    height: clamp(0.5rem, 1.163vw, 1rem);
  }
  table::-webkit-scrollbar-track {
    background-color: #4DB0F5;
  }
  table::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
  .wp-block-table {
    margin: clamp(2rem, 4.651vw, 4rem) 0 clamp(2.5rem, 5.814vw, 5rem);
  }
  .wp-block-table table {
    margin: 0;
    border-left: clamp(0.1rem, 0.233vw, 0.2rem) solid #AAAAAA !important;
    border-right: clamp(0.1rem, 0.233vw, 0.2rem) solid #AAAAAA !important;
  }
  .wp-block-table table:not(.noscroll) {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
  }
  .wp-block-table table caption {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    margin: clamp(1.5rem, 3.488vw, 3rem) 0;
  }
  .wp-block-table table thead th, .wp-block-table table thead td {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    padding: clamp(2.2rem, 5.233vw, 4.5rem);
  }
  .wp-block-table table th, .wp-block-table table td {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    padding: clamp(2.2rem, 5.233vw, 4.5rem) clamp(1.1rem, 2.558vw, 2.2rem);
    border-top: clamp(0.1rem, 0.233vw, 0.2rem) solid #AAAAAA !important;
    border-bottom: clamp(0.1rem, 0.233vw, 0.2rem) solid #AAAAAA !important;
    border-left: none !important;
    border-right: none !important;
  }
  .wp-block-table table::-webkit-scrollbar {
    height: clamp(0.5rem, 1.163vw, 1rem);
  }
  .wp-block-table table::-webkit-scrollbar-track {
    background-color: #4DB0F5;
  }
  .wp-block-table table::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
  .scrollAnnounce {
    display: block;
    text-align: center;
  }
  .scrollAnnounce .text {
    font-size: clamp(1.2rem, 2.791vw, 2.4rem);
    font-weight: 600;
    color: #fff;
    background: #0080D9;
    padding: 3px 6px;
    position: relative;
  }
  .scrollAnnounce .text::after {
    content: "";
    border-style: solid;
    border-width: 7px 6.5px 0 6.5px;
    border-color: #0080D9 transparent transparent transparent;
    display: inline-block;
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
}
/*=================================
  breadcrumb
=================================*/
.breadcrumb ul li {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  color: #191919;
  line-height: 2;
  display: inline-block;
  padding-right: clamp(2.2rem, 1.647vw, 4.5rem);
  position: relative;
}
.breadcrumb ul li:not(:first-child) {
  padding-left: clamp(2.2rem, 1.647vw, 4.5rem);
}
.breadcrumb ul li:not(:first-child)::before {
  content: "";
  width: clamp(0.1rem, 0.073vw, 0.2rem);
  height: clamp(2.1rem, 1.537vw, 4.2rem);
  background: #191919;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(-45deg);
}
.breadcrumb ul li a {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  color: #191919;
  line-height: 2;
  display: inline-block;
  text-decoration: none;
}
@media screen and (min-width: 860px) {
  .breadcrumb ul li a:hover {
    color: #0080D9;
    text-decoration: none;
  }
}

@media screen and (min-width: 0) and (max-width: 860px) {
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb {
    padding: 0 clamp(2.5rem, 5.814vw, 5rem);
  }
  .breadcrumb ul li {
    font-size: clamp(1.2rem, 2.326vw, 2rem);
    line-height: 2.2;
    padding-right: clamp(1.5rem, 3.488vw, 3rem);
  }
  .breadcrumb ul li:not(:first-child) {
    padding-left: clamp(1.5rem, 3.488vw, 3rem);
  }
  .breadcrumb ul li:not(:first-child)::before {
    height: clamp(1.2rem, 2.791vw, 2.4rem);
  }
  .breadcrumb ul li a {
    font-size: clamp(1.2rem, 2.326vw, 2rem);
    line-height: 2.2;
  }
}
/*=================================
  toc
=================================*/
.tocBox {
  margin: 40px auto;
  padding: 100px 3%;
  background: #0080D9;
}
.tocBox__inner {
  max-width: clamp(119.5rem, 87.482vw, 239rem);
  margin: 0 auto;
}
.tocBox__ttl {
  font-size: clamp(1.8rem, 1.3543191801vw, 3.7rem);
  font-weight: 500;
  line-height: 1;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 60px;
}
.tocBox .tocList {
  counter-reset: tocNum;
  padding: 0 1.5%;
}
.tocBox .tocList__item {
  position: relative;
}
.tocBox .tocList__item::before {
  counter-increment: tocNum;
  content: "0" counter(tocNum);
  font-size: clamp(1.5rem, 0.7320644217vw, 2rem);
  font-weight: 700;
  color: #fff;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 0;
}
.tocBox .tocList__item a {
  font-size: clamp(1.5rem, 0.7320644217vw, 2rem);
  font-weight: 700;
  color: #fff;
  display: inline-block;
  padding: 0 0 0 38px;
}
@media screen and (min-width: 860px) {
  .tocBox .tocList__item a:hover {
    text-decoration: none;
  }
}
.tocBox .tocList__item + .tocList__item {
  margin-top: 1.5em;
}
.tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 2.5em;
  margin-top: 1em;
}
.tocBox .tocList__item .tocList__item::before {
  counter-increment: tocChildNum;
  content: "0" counter(tocChildNum);
}
.tocBox .tocList__item .tocList__item a {
  padding-left: 2em;
}
.tocBox .tocList__item .tocList__item + .tocList__item {
  margin-top: 0.5em;
}
.tocBox .tocMore {
  font-size: 1.5rem;
  font-weight: 500;
  background: #ffce08;
  text-align: center;
  margin-top: 20px;
  padding: 8px 20px;
  display: inline-block;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media screen and (min-width: 860px) {
  .tocBox .tocMore:hover {
    color: #fff;
    background: #0080D9;
  }
}

@media screen and (min-width: 0) and (max-width: 860px) {
  .tocBox {
    margin: 35px auto;
    padding: 30px 1.976744186% 60px;
  }
  .tocBox__ttl {
    font-size: 2.5rem;
    margin-bottom: 22px;
  }
  .tocBox .tocList {
    padding: 0;
  }
  .tocBox .tocList__item::before {
    font-size: 1.8rem;
  }
  .tocBox .tocList__item a {
    font-size: 1.8rem;
  }
  .tocBox .tocMore {
    font-size: 1.4rem;
  }
}
/*=================================
  pager
=================================*/
.pager {
  margin-top: clamp(4rem, 2.928vw, 8rem);
}
.pagerList {
  display: flex;
  justify-content: center;
}
.pagerList__item {
  margin-right: clamp(1rem, 0.732vw, 2rem);
}
.pagerList__item a, .pagerList__item span {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  line-height: 1;
  padding: clamp(1.5rem, 1.098vw, 3rem) clamp(1.7rem, 1.281vw, 3.5rem);
  display: block;
}
.pagerList__item a {
  color: #0080D9;
  text-decoration: none;
  background: #e7edf8;
}
@media screen and (min-width: 860px) {
  .pagerList__item a:hover {
    color: #fff;
    background: #0080D9;
  }
}
.pagerList__item span {
  color: #fff;
  background: #0080D9;
}
.pager .wp-pagenavi {
  display: flex;
  justify-content: center;
}
.pager .wp-pagenavi .pages {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  line-height: 1;
  padding: clamp(1.5rem, 1.098vw, 3rem) clamp(1.7rem, 1.281vw, 3.5rem);
  margin-right: clamp(1rem, 0.732vw, 2rem);
  border: 1px solid #0080D9;
}
.pager .wp-pagenavi .current {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  line-height: 1;
  color: #fff;
  padding: clamp(1.5rem, 1.098vw, 3rem) clamp(1.7rem, 1.281vw, 3.5rem);
  background: #0080D9;
  display: block;
  text-decoration: none;
  border: 1px solid #0080D9;
}
.pager .wp-pagenavi .page {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  color: #0080D9;
  line-height: 1;
  padding: clamp(1.5rem, 1.098vw, 3rem) clamp(1.7rem, 1.281vw, 3.5rem);
  display: block;
  background: #e7edf8;
  text-decoration: none;
  margin-left: clamp(1rem, 0.732vw, 2rem);
  border: 1px solid #e7edf8;
}
@media screen and (min-width: 860px) {
  .pager .wp-pagenavi .page:hover {
    color: #fff;
    background: #0080D9;
  }
}
.pager .wp-pagenavi .nextpostslink {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  color: #0080D9;
  line-height: 1;
  padding: clamp(1.5rem, 1.098vw, 3rem) clamp(1.7rem, 1.281vw, 3.5rem);
  display: block;
  background: #fff;
  text-decoration: none;
  margin-left: clamp(1rem, 0.732vw, 2rem);
  border: 1px solid #0080D9;
}
@media screen and (min-width: 860px) {
  .pager .wp-pagenavi .nextpostslink:hover {
    color: #fff;
    background: #0080D9;
  }
}
.pager .wp-pagenavi .previouspostslink {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  color: #0080D9;
  line-height: 1;
  padding: clamp(1.5rem, 1.098vw, 3rem) clamp(1.7rem, 1.281vw, 3.5rem);
  display: block;
  background: #fff;
  text-decoration: none;
  border: 1px solid #0080D9;
}
@media screen and (min-width: 860px) {
  .pager .wp-pagenavi .previouspostslink:hover {
    color: #fff;
    background: #0080D9;
  }
}

@media screen and (max-width: 860px) {
  /*=================================
    pager
  =================================*/
  .pager {
    display: block;
    text-align: center;
    margin-top: clamp(2rem, 4.651vw, 4rem);
  }
  .pagerList {
    justify-content: center;
  }
  .pagerList__item {
    margin-right: clamp(0.8rem, 1.86vw, 1.6rem);
  }
  .pagerList__item a, .pagerList__item span {
    padding: clamp(1rem, 2.326vw, 2rem) clamp(1.5rem, 3.488vw, 3rem);
  }
  .pager .wp-pagenavi .pages {
    padding: clamp(1rem, 2.326vw, 2rem) clamp(1.5rem, 3.488vw, 3rem);
    margin-right: clamp(0.8rem, 1.86vw, 1.6rem);
  }
  .pager .wp-pagenavi .current {
    padding: clamp(1rem, 2.326vw, 2rem) clamp(1.5rem, 3.488vw, 3rem);
  }
  .pager .wp-pagenavi .page {
    padding: clamp(1rem, 2.326vw, 2rem) clamp(1.5rem, 3.488vw, 3rem);
    margin-left: clamp(0.8rem, 1.86vw, 1.6rem);
  }
  .pager .wp-pagenavi .nextpostslink {
    padding: clamp(1rem, 2.326vw, 2rem) clamp(1.5rem, 3.488vw, 3rem);
    margin-left: clamp(0.8rem, 1.86vw, 1.6rem);
  }
  .pager .wp-pagenavi .previouspostslink {
    padding: clamp(1rem, 2.326vw, 2rem) clamp(1.5rem, 3.488vw, 3rem);
  }
}
/*=================================
  kv
=================================*/
.underKv {
  margin: clamp(5rem, 3.66vw, 10rem) auto clamp(7rem, 5.124vw, 14rem);
  display: block;
}
.underKv__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 0) and (max-width: 860px) {
  /*=================================
    kv
  =================================*/
  .underKv {
    margin: clamp(5rem, 11.628vw, 10rem) auto;
  }
}
/*=================================
  memberSlider
=================================*/
.memberSlider {
  width: calc(100% + clamp(2rem, 6.223vw, 17rem));
  overflow: hidden;
  position: relative;
}
.memberSlider__wrap {
  overflow: visible !important;
  width: calc(100% + clamp(17rem, 12.445vw, 34rem));
  position: relative;
}
.memberSlider__item {
  display: block;
  text-decoration: none;
  position: relative;
}
@media screen and (min-width: 860px) {
  .memberSlider__item:hover .memberSlider__img img {
    transform: scale(1.1);
  }
  .memberSlider__item:hover .memberSlider__profile {
    opacity: 1;
  }
}
.memberSlider__img {
  width: 100%;
  display: block;
  overflow: hidden;
}
.memberSlider__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.memberSlider__profile {
  width: 72.5%;
  background: #fff;
  padding: clamp(2rem, 1.464vw, 4rem) clamp(1.5rem, 1.098vw, 3rem);
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.memberSlider__profile--name {
  font-size: clamp(2rem, 2.196vw, 6rem);
  font-weight: 500;
  line-height: 1.85;
  color: #191919;
}
.memberSlider__profile--ruby {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.2rem, 0.915vw, 2.5rem);
  letter-spacing: 0.05em;
  line-height: 2;
  color: #0080D9;
  display: block;
}
.memberSlider__profile--post {
  font-size: clamp(1.4rem, 1.281vw, 3.5rem);
  line-height: 2;
  color: #191919;
  display: block;
}
.memberSlider .swiper-wrapper {
  box-sizing: border-box;
  align-items: flex-end;
}
.memberSlider .swiper-slide {
  transform: scale(0.835);
  transform-origin: top right;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.memberSlider .swiper-slide.swiper-slide-active {
  transform: scale(1);
}
.memberSlider .swiper-slide.swiper-slide-active .memberSlider__profile {
  opacity: 1;
}
.memberSlider .swiper-controller {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: clamp(1.4rem, 1.757vw, 4.8rem);
  position: absolute;
  bottom: 0;
  right: clamp(2rem, 6.223vw, 17rem);
}
.memberSlider .swiper-pagination {
  margin-right: auto;
}
.memberSlider .swiper-button-next, .memberSlider .swiper-button-prev {
  width: clamp(3.4rem, 3.953vw, 10.8rem);
  height: clamp(3.4rem, 3.953vw, 10.8rem);
  margin: 0;
  position: static;
  z-index: 100;
}
.memberSlider .swiper-button-next::after, .memberSlider .swiper-button-prev::after {
  content: "";
  width: clamp(3.4rem, 3.953vw, 10.8rem);
  height: clamp(3.4rem, 3.953vw, 10.8rem);
  display: inline-block;
  background: url(../img/slider_arrow.png) no-repeat center center;
  background-size: contain;
}
.memberSlider .swiper-button-next.swiper-button-disabled, .memberSlider .swiper-button-prev.swiper-button-disabled {
  opacity: 0.3 !important;
}
.memberSlider .swiper-button-next::after {
  transform: rotate(180deg);
}

@media screen and (min-width: 0) and (max-width: 860px) {
  .memberSlider {
    width: 100%;
    padding-bottom: calc(clamp(6.3rem, 14.535vw, 12.5rem) + clamp(3.5rem, 8.14vw, 7rem));
  }
  .memberSlider__wrap {
    width: calc(100% + clamp(2rem, 11.628vw, 10rem));
  }
  .memberSlider__profile {
    width: 84.0336134454%;
    padding: clamp(0.5rem, 2.326vw, 2rem) clamp(0.5rem, 2.326vw, 2rem);
  }
  .memberSlider__profile--name {
    font-size: clamp(2rem, 5.814vw, 5rem);
    line-height: 1.62;
  }
  .memberSlider__profile--ruby {
    font-size: clamp(1rem, 2.326vw, 2rem);
    line-height: 1.7;
  }
  .memberSlider__profile--post {
    font-size: clamp(1.2rem, 1.86vw, 1.6rem);
    line-height: 1.73333;
  }
  .memberSlider .swiper-slide {
    transform: scale(0.735);
  }
  .memberSlider .swiper-controller {
    gap: clamp(2.7rem, 6.395vw, 5.5rem);
    right: clamp(2.5rem, 5.814vw, 5rem);
  }
  .memberSlider .swiper-button-next, .memberSlider .swiper-button-prev {
    width: clamp(6.3rem, 14.535vw, 12.5rem);
    height: clamp(6.3rem, 14.535vw, 12.5rem);
  }
  .memberSlider .swiper-button-next::after, .memberSlider .swiper-button-prev::after {
    width: clamp(6.3rem, 14.535vw, 12.5rem);
    height: clamp(6.3rem, 14.535vw, 12.5rem);
  }
}
/*=================================
  topPage
=================================*/
.home {
  /* メインビジュアル */
  /* サービス */
  /* お知らせ */
}
.home .top--bgWrap {
  background: url(../img/top/top_bg01.png) no-repeat right top/100%, url(../img/top/top_bg02.png) no-repeat left bottom -1px/100%;
  position: relative;
  padding: clamp(7rem, 5.124vw, 14rem) 0 clamp(9rem, 6.589vw, 18rem);
}
.home .bottom--bgWrap {
  background: url(../img/top/top_bg03.png) no-repeat right top clamp(42.5rem, 31.113vw, 85rem)/100%, url(../img/top/top_bg04.png) no-repeat left bottom/100%;
  position: relative;
  padding: clamp(9rem, 6.589vw, 18rem) 0 clamp(17rem, 7.833vw, 21.4rem);
}
.home .kv {
  width: 100%;
  height: 48.5358711567vw;
  background: #fff;
  position: relative;
  opacity: 0;
}
.home .kv__logo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.home .kv__logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home .kv__imgBox {
  position: absolute;
  width: 100%;
  height: 100%;
}
.home .kv__img01 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.home .kv__img01 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home .kv__img02 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.home .kv__img02 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home .kv__img03 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.home .kv__img03 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home .secServiceMain {
  margin-top: clamp(7.4rem, 5.417vw, 14.8rem);
}
.home .secServiceMain__col {
  display: flex;
  gap: 0 clamp(7rem, 5.124vw, 14rem);
  padding: 0 clamp(2rem, 6.223vw, 17rem);
}
.home .secServiceMain__col:first-child {
  position: relative;
}
.home .secServiceMain__col:first-child::after {
  content: "";
  width: 50%;
  height: calc(100% + clamp(19.2rem, 14.056vw, 38.4rem));
  display: block;
  background: url(../img/top/top_bg_service_pc.png) no-repeat right bottom;
  background-size: cover;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  mix-blend-mode: darken;
}
.home .secServiceMain__col:nth-child(2n) {
  flex-direction: row-reverse;
}
.home .secServiceMain__col + .secServiceMain__col {
  margin-top: clamp(10rem, 7.321vw, 20rem);
}
.home .secServiceMain__img {
  width: clamp(40rem, 51.464vw, 140.6rem);
}
.home .secServiceMain__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home .secServiceMain__cont {
  width: calc(100% - clamp(40rem, 51.464vw, 140.6rem) + clamp(7rem, 5.124vw, 14rem));
}
.home .secServiceMain__ttl--ruby {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.7rem, 0.915vw, 2.5rem);
  line-height: 2;
  letter-spacing: 0.05em;
}
.home .secServiceMain__ttl--main {
  font-size: clamp(4rem, 2.928vw, 8rem);
  font-weight: 500;
  color: #0080D9;
  line-height: 1.75;
  letter-spacing: 0.05em;
}
.home .secServiceMain__text {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  line-height: 1.714285;
}
.home .secServiceMain__btn {
  margin-top: clamp(4rem, 2.928vw, 8rem);
  max-width: calc(100% - clamp(3.3rem, 2.379vw, 6.5rem));
  position: relative;
  z-index: 1;
}
.home .secServiceMain__link {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  border: clamp(0.1rem, 0.073vw, 0.2rem) solid #0080D9;
  background: #0080D9;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: clamp(2.9rem, 2.123vw, 5.8rem) clamp(4rem, 2.928vw, 8rem) clamp(2.9rem, 2.123vw, 5.8rem) clamp(0.5rem, 0.366vw, 1rem);
  position: relative;
}
.home .secServiceMain__link::before {
  content: "";
  display: inline-block;
  width: clamp(2.2rem, 1.611vw, 4.4rem);
  height: clamp(1.5rem, 1.098vw, 3rem);
  mask-image: url(../img/icon_arrow.svg);
  background-color: #fff;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  position: absolute;
  top: 50%;
  right: clamp(0.5rem, 1.245vw, 3.4rem);
  transform: translateY(-50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 860px) {
  .home .secServiceMain__link:hover {
    color: #0080D9;
    background: #fff;
  }
  .home .secServiceMain__link:hover::before {
    background-color: #0080D9;
    transform: translate(15%, -50%);
  }
}
.home .secServiceMain__link + .secServiceMain__link {
  margin-top: clamp(4rem, 2.928vw, 8rem);
}
.home .secServiceSub {
  padding: 0 clamp(2rem, 6.223vw, 17rem);
  margin-top: clamp(10rem, 7.321vw, 20rem);
}
.home .secServiceSub__inner {
  background: #fff;
  padding: clamp(6rem, 4.392vw, 12rem) clamp(2rem, 6.223vw, 17rem);
  display: flex;
  flex-wrap: wrap;
  gap: 0 clamp(6rem, 4.392vw, 12rem);
}
.home .secServiceSub__col {
  width: calc((100% - clamp(12rem, 8.785vw, 24rem)) / 3);
}
.home .secServiceSub__img {
  width: 100%;
  display: block;
  margin: clamp(2rem, 1.464vw, 4rem) 0 clamp(2.5rem, 1.83vw, 5rem);
}
.home .secServiceSub__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home .secServiceSub__ttl {
  margin-bottom: clamp(2rem, 1.464vw, 4rem);
}
.home .secServiceSub__ttl--ruby {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.7rem, 0.915vw, 2.5rem);
  line-height: 2;
  line-height: 2.64;
  letter-spacing: 0.05em;
}
.home .secServiceSub__ttl--main {
  font-size: clamp(3rem, 2.196vw, 6rem);
  font-weight: 500;
  color: #0080D9;
  line-height: 1.616666;
  letter-spacing: 0.03em;
}
.home .secServiceSub__text {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  line-height: 1.714285;
}
.home .secServiceSub__btn {
  margin-top: clamp(2.5rem, 1.83vw, 5rem);
}
.home .secServiceSub__link {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  font-weight: 400;
  line-height: 1.5;
  color: #191919;
  border: clamp(0.1rem, 0.073vw, 0.2rem) solid #191919;
  background: #fff;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  padding: clamp(1.9rem, 1.391vw, 3.8rem) clamp(5rem, 4.026vw, 11rem);
  position: relative;
}
.home .secServiceSub__link::before {
  content: "";
  display: inline-block;
  width: clamp(1.5rem, 1.098vw, 3rem);
  height: clamp(1rem, 0.769vw, 2.1rem);
  mask-image: url(../img/icon_arrow.svg);
  background-color: #0080D9;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  position: absolute;
  top: 50%;
  right: clamp(0.5rem, 1.391vw, 3.8rem);
  transform: translateY(-50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 860px) {
  .home .secServiceSub__link:hover {
    color: #fff;
    background: #0080D9;
  }
  .home .secServiceSub__link:hover::before {
    background-color: #fff;
    transform: translate(15%, -50%);
  }
}
.home .secInfo {
  padding: 0 clamp(2rem, 6.223vw, 17rem);
  margin-top: clamp(10rem, 7.321vw, 20rem);
}
.home .secInfo .infoBoxWrap {
  background: url(../img/top/top_bg_info_pc.png) no-repeat center center;
  background-size: cover;
  padding: clamp(7.4rem, 5.417vw, 14.8rem) clamp(5.8rem, 4.246vw, 11.6rem);
  position: relative;
}
.home .secInfo .infoBox {
  display: flex;
  color: #fff;
}
.home .secInfo .infoBox__ttl {
  font-size: clamp(3.5rem, 2.562vw, 7rem);
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.04em;
  color: #fff;
  width: clamp(26.4rem, 19.29vw, 52.7rem);
}
.home .secInfo .infoBox__ttl::before {
  content: "NOTICE";
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.2rem, 0.915vw, 2.5rem);
  line-height: 1;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: clamp(1.7rem, 1.245vw, 3.4rem);
  display: block;
}
.home .secInfo .infoBox .infoList {
  width: calc(100% - clamp(26.4rem, 19.29vw, 52.7rem));
  height: clamp(13rem, 9.517vw, 26rem);
  padding-right: clamp(1rem, 0.732vw, 2rem);
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
}
.home .secInfo .infoBox .infoList::-webkit-scrollbar {
  width: clamp(0.4rem, 0.366vw, 1rem);
}
.home .secInfo .infoBox .infoList::-webkit-scrollbar-track {
  background-color: #4DB0F5;
}
.home .secInfo .infoBox .infoList::-webkit-scrollbar-thumb {
  background-color: #fff;
}
.home .secInfo .infoBox .infoList__day {
  font-size: clamp(1.7rem, 1.281vw, 3.5rem);
  color: #fff;
  width: clamp(13.3rem, 9.736vw, 26.6rem);
  position: relative;
}
.home .secInfo .infoBox .infoList__day::after {
  content: "";
  display: inline-block;
  width: clamp(0.15rem, 0.11vw, 0.3rem);
  height: clamp(1.7rem, 1.245vw, 3.4rem);
  background: #fff;
  position: absolute;
  top: clamp(1rem, 0.732vw, 2rem);
  right: clamp(2.2rem, 1.611vw, 4.4rem);
  transform: rotate(-45deg);
}
.home .secInfo .infoBox .infoList__day:nth-of-type(n + 2) {
  margin-top: clamp(1.7rem, 1.245vw, 3.4rem);
}
.home .secInfo .infoBox .infoList__text {
  width: calc(100% - clamp(13.3rem, 9.736vw, 26.6rem));
}
.home .secInfo .infoBox .infoList__text:nth-of-type(n + 2) {
  margin-top: clamp(1.7rem, 1.245vw, 3.4rem);
}
.home .secInfo .infoBox .infoList__text a {
  font-size: clamp(1.7rem, 1.281vw, 3.5rem);
  color: #fff;
  text-decoration: none;
}
@media screen and (min-width: 860px) {
  .home .secInfo .infoBox .infoList__text a:hover {
    text-decoration: underline;
  }
}
.home .secInfo .infoLink {
  font-size: clamp(1.7rem, 1.281vw, 3.5rem);
  line-height: 1;
  color: #fff;
  text-decoration: none;
  position: absolute;
  right: clamp(3.2rem, 2.343vw, 6.4rem);
  bottom: clamp(3rem, 2.196vw, 6rem);
}
.home .secInfo .infoLink::after {
  content: "";
  width: clamp(1.5rem, 1.098vw, 3rem);
  height: clamp(1rem, 0.732vw, 2rem);
  mask-image: url(../img/icon_arrow.svg);
  background-color: #fff;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  display: inline-block;
  vertical-align: middle;
  margin-left: clamp(0.7rem, 0.512vw, 1.4rem);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 860px) {
  .home .secInfo .infoLink:hover::after {
    transform: translateX(15%);
  }
}
.home .secMember {
  padding: 0 clamp(2rem, 6.223vw, 17rem);
}
.home .secMember .memberSlider {
  width: calc(100% + clamp(2.5rem, 5.814vw, 5rem));
}
.home .secAbout {
  padding: 0 clamp(2rem, 6.223vw, 17rem);
  margin: clamp(12rem, 8.785vw, 24rem) auto;
}
.home .secAbout__inner {
  padding: clamp(2rem, 5.747vw, 15.7rem) clamp(2rem, 6.223vw, 17rem) clamp(2rem, 6.223vw, 17rem);
  background: #0080D9;
}
.home .secAboutMain {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(6.4rem, 4.685vw, 12.8rem) clamp(3.5rem, 2.562vw, 7rem);
}
.home .secAboutMain__ttl {
  color: #191919;
  text-decoration: none;
}
.home .secAboutMain__ttl--ruby {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.3rem, 0.915vw, 2.5rem);
  line-height: 2;
  letter-spacing: 0.05em;
}
.home .secAboutMain__ttl--main {
  font-size: clamp(2rem, 2.562vw, 7rem);
  font-weight: 400;
  line-height: 1.42857;
}
.home .secAboutMain__ttl a {
  color: #191919;
  text-decoration: none;
}
.home .secAboutMain__img img {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.home .secAboutMain__cont {
  position: absolute;
}
.home .secAboutMain__col:nth-child(-n+2) {
  width: calc((100% - clamp(3.5rem, 2.562vw, 7rem)) / 2);
  background: #fff;
  position: relative;
  overflow: hidden;
}
.home .secAboutMain__col:nth-child(-n+2) .secAboutMain__cont {
  width: clamp(19rem, 13.909vw, 38rem);
  top: 50%;
  left: clamp(10rem, 15.739vw, 43rem);
  transform: translateY(-50%);
}
.home .secAboutMain__col:nth-child(-n+2)::after {
  content: "";
  width: clamp(3rem, 4.392vw, 12rem);
  height: clamp(3rem, 4.392vw, 12rem);
  display: inline-block;
  background: url(../img/top/about_arrow.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: clamp(0.5rem, 1.464vw, 4rem);
  transform: translateY(-50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 860px) {
  .home .secAboutMain__col:nth-child(-n+2):hover::after {
    transform: translate(5%, -50%);
  }
  .home .secAboutMain__col:nth-child(-n+2):hover .secAboutMain__img img {
    transform: scale(1.1);
  }
}
.home .secAboutMain__col:nth-child(n+3) {
  width: calc((100% - clamp(7rem, 5.124vw, 14rem)) / 3);
  background: #F7F7F7;
  position: relative;
  overflow: hidden;
}
.home .secAboutMain__col:nth-child(n+3) .secAboutMain__cont {
  width: clamp(20rem, 14.641vw, 40rem);
  left: clamp(3rem, 2.196vw, 6rem);
  bottom: clamp(3rem, 2.196vw, 6rem);
}
.home .secAboutMain__col:nth-child(n+3)::after {
  content: "";
  width: clamp(3rem, 4.392vw, 12rem);
  height: clamp(3rem, 4.392vw, 12rem);
  display: inline-block;
  background: url(../img/top/about_arrow.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  right: clamp(0.5rem, 2.196vw, 6rem);
  bottom: clamp(0.5rem, 2.196vw, 6rem);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 860px) {
  .home .secAboutMain__col:nth-child(n+3):hover::after {
    transform: translateX(5%);
  }
  .home .secAboutMain__col:nth-child(n+3):hover .secAboutMain__img img {
    transform: scale(1.1);
  }
}
.home .secAboutSub {
  margin-top: clamp(5.5rem, 4.026vw, 11rem);
}
.home .secAboutSub__video {
  width: 100%;
  text-align: center;
  background: #191919;
}
.home .secAboutSub__video iframe {
  width: 78%;
  height: 80%;
  aspect-ratio: 16/9;
  vertical-align: bottom;
}
.home .secAccess__inner {
  padding: 0 clamp(2rem, 6.223vw, 17rem);
}
.home .secAccess__cont {
  padding: clamp(2rem, 3.66vw, 10rem);
  background: #fff;
  display: flex;
  gap: clamp(2rem, 3.66vw, 10rem);
}
.home .secAccess__col {
  width: calc(100% - clamp(2rem, 3.66vw, 10rem) / 2);
}
.home .secAccess__gMap {
  aspect-ratio: 1000/463;
}
.home .secAccess__gMap iframe {
  max-width: 100%;
  max-height: 350px;
  border: none;
}
.home .secAccess__info--ruby {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.2rem, 0.915vw, 2.5rem);
  line-height: 2;
  letter-spacing: 0.05em;
  color: #0080D9;
}
.home .secAccess__info--name {
  font-size: clamp(2.2rem, 1.647vw, 4.5rem);
  font-weight: 500;
  line-height: 2.46666;
}
.home .secAccess__info--add {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  line-height: 1.71428;
}
.home .secMessage {
  margin-top: clamp(11rem, 8.053vw, 22rem);
}
.home .secMessage__inner {
  padding: 0 clamp(2rem, 6.223vw, 17rem);
}
.home .secMessage__cont {
  display: flex;
  gap: clamp(1.5rem, 4.173vw, 11.4rem);
}
.home .secMessage__col {
  width: calc((100% - clamp(1.5rem, 12.518vw, 34.2rem)) / 4);
  padding: clamp(1rem, 2.635vw, 7.2rem) clamp(1rem, 1.684vw, 4.6rem) clamp(1rem, 1.977vw, 5.4rem);
  background: #fff;
  display: flex;
  flex-direction: column;
}
.home .secMessage__icon {
  width: 53.4441805226%;
  margin: 0 auto clamp(2.9rem, 2.123vw, 5.8rem);
  display: block;
}
.home .secMessage__ttl {
  font-size: clamp(1.6rem, 1.464vw, 4rem);
  font-weight: 500;
  line-height: 2;
  color: #0080D9;
  margin-bottom: clamp(1rem, 0.732vw, 2rem);
}
.home .secMessage__text {
  font-size: clamp(1.4rem, 1.281vw, 3.5rem);
  line-height: 1.71428;
  margin-top: auto;
}
.home .secMessage__btn {
  font-size: clamp(1.9rem, 1.391vw, 3.8rem);
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background: #0080D9;
  display: block;
  padding: clamp(4.2rem, 3.075vw, 8.4rem);
  width: clamp(1.9rem, 60.505vw, 165.3rem);
  margin: clamp(11.5rem, 8.419vw, 23rem) auto 0;
  border: 1px solid transparent;
}
@media screen and (min-width: 860px) {
  .home .secMessage__btn:hover {
    color: #191919;
    border-color: #191919;
    background: #fff;
  }
}

@media screen and (min-width: 0) and (max-width: 860px) {
  /*=================================
    topPage
  =================================*/
  .home {
    /* メインビジュアル */
    /* サービス */
    /* お知らせ */
  }
  .home .top--bgWrap {
    background: none;
    padding: 0;
  }
  .home .bottom--bgWrap {
    background: none;
    padding: 0;
  }
  .home .kv {
    height: 164.7144948755vw;
  }
  .home .kv__logo img {
    object-fit: contain;
  }
  .home .secService {
    padding: clamp(4.7rem, 10.93vw, 9.4rem) 0 clamp(5rem, 11.628vw, 10rem);
  }
  .home .secServiceMain {
    margin-top: clamp(4.9rem, 11.395vw, 9.8rem);
  }
  .home .secServiceMain__col {
    display: block;
    padding: 0 clamp(2.5rem, 5.814vw, 5rem);
  }
  .home .secServiceMain__col:first-child {
    background: url(../img/top/top_bg01_sp.png) no-repeat left bottom/100%;
  }
  .home .secServiceMain__col:first-child::after {
    content: none;
  }
  .home .secServiceMain__col:first-child .secServiceMain__cont {
    position: relative;
  }
  .home .secServiceMain__col:first-child .secServiceMain__cont::before {
    content: "";
    width: clamp(20rem, 83.023vw, 71.4rem);
    height: clamp(20rem, 90.93vw, 78.2rem);
    display: block;
    background: url(../img/top/top_bg_service_sp.png) no-repeat right bottom;
    background-size: cover;
    position: absolute;
    top: clamp(-4.5rem, -10.465vw, -9rem);
    right: clamp(-2.5rem, -5.814vw, -5rem);
    z-index: 0;
    mix-blend-mode: darken;
  }
  .home .secServiceMain__col:nth-child(2) {
    background: url(../img/top/top_bg02_sp.png) no-repeat left top/100%;
  }
  .home .secServiceMain__col + .secServiceMain__col {
    padding-top: clamp(5rem, 11.628vw, 10rem);
    margin-top: 0;
  }
  .home .secServiceMain__img {
    width: 100%;
    margin-bottom: clamp(2rem, 4.651vw, 4rem);
    display: block;
  }
  .home .secServiceMain__cont {
    width: 100%;
  }
  .home .secServiceMain__ttl--ruby {
    font-size: clamp(1rem, 2.326vw, 2rem);
    line-height: 2.5;
  }
  .home .secServiceMain__ttl--main {
    font-size: clamp(3.5rem, 8.14vw, 7rem);
    line-height: 2;
    letter-spacing: 0;
  }
  .home .secServiceMain__text {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    line-height: 2;
  }
  .home .secServiceMain__btn {
    margin-top: clamp(2.5rem, 5.814vw, 5rem);
    max-width: calc(100% - clamp(2rem, 4.651vw, 4rem));
  }
  .home .secServiceMain__link {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    border: clamp(0.1rem, 0.233vw, 0.2rem) solid #0080D9;
    padding: clamp(2.9rem, 5.233vw, 4.5rem) clamp(3rem, 6.977vw, 6rem) clamp(2.9rem, 5.233vw, 4.5rem) clamp(0.25rem, 0.581vw, 0.5rem);
  }
  .home .secServiceMain__link::before {
    width: clamp(1.5rem, 3.488vw, 3rem);
    height: clamp(1rem, 2.326vw, 2rem);
    right: clamp(0.5rem, 2.326vw, 2rem);
  }
  .home .secServiceMain__link + .secServiceMain__link {
    margin-top: clamp(1.5rem, 3.488vw, 3rem);
  }
  .home .secServiceSub {
    padding: clamp(5rem, 11.628vw, 10rem) clamp(2.5rem, 5.814vw, 5rem) 0;
    margin-top: 0;
    background: url(../img/top/top_bg03_sp.png) no-repeat left top;
    background-size: cover;
  }
  .home .secServiceSub__inner {
    padding: clamp(4rem, 9.302vw, 8rem) clamp(2.5rem, 5.814vw, 5rem);
    gap: clamp(5rem, 11.628vw, 10rem) 0;
  }
  .home .secServiceSub__col {
    width: 100%;
  }
  .home .secServiceSub__img {
    margin: clamp(2rem, 4.651vw, 4rem) 0;
  }
  .home .secServiceSub__ttl {
    margin-bottom: clamp(2rem, 4.651vw, 4rem);
  }
  .home .secServiceSub__ttl--ruby {
    font-size: clamp(1rem, 2.326vw, 2rem);
    line-height: 2;
    line-height: 3.3;
  }
  .home .secServiceSub__ttl--main {
    font-size: clamp(3rem, 6.977vw, 6rem);
    letter-spacing: 0;
  }
  .home .secServiceSub__text {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    line-height: 2;
  }
  .home .secServiceSub__btn {
    margin-top: clamp(2rem, 4.651vw, 4rem);
    max-width: 70%;
  }
  .home .secServiceSub__link {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    border: clamp(0.1rem, 0.233vw, 0.2rem) solid #191919;
    padding: clamp(2rem, 4.651vw, 4rem);
    display: block;
  }
  .home .secServiceSub__link::before {
    width: clamp(1.5rem, 3.488vw, 3rem);
    height: clamp(1rem, 2.326vw, 2rem);
    right: clamp(0.5rem, 4.651vw, 4rem);
  }
  .home .secInfo {
    padding: 0 clamp(2.5rem, 5.814vw, 5rem);
    margin-top: 0;
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .home .secInfo .infoBoxWrap {
    background: url(../img/top/top_bg_info_sp.png) no-repeat center bottom;
    background-size: cover;
    padding: clamp(5rem, 11.628vw, 10rem) clamp(2.5rem, 5.814vw, 5rem) clamp(2.5rem, 5.814vw, 5rem);
  }
  .home .secInfo .infoBox {
    display: block;
  }
  .home .secInfo .infoBox__ttl {
    font-size: clamp(3.5rem, 8.14vw, 7rem);
    letter-spacing: 0;
    width: auto;
  }
  .home .secInfo .infoBox__ttl::before {
    font-size: clamp(1rem, 2.326vw, 2rem);
    margin-bottom: clamp(1rem, 2.326vw, 2rem);
  }
  .home .secInfo .infoBox .infoList {
    width: 100%;
    height: auto;
    padding-right: 0;
    overflow-y: initial;
    display: block;
  }
  .home .secInfo .infoBox .infoList__day {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    width: initial;
  }
  .home .secInfo .infoBox .infoList__day::after {
    width: clamp(0.1rem, 0.233vw, 0.2rem);
    height: clamp(1.6rem, 3.837vw, 3.3rem);
    position: static;
    margin: 0 0 clamp(-0.4rem, -0.93vw, -0.8rem) clamp(2rem, 4.651vw, 4rem);
  }
  .home .secInfo .infoBox .infoList__day:nth-of-type(n + 2) {
    margin-top: clamp(2.5rem, 5.814vw, 5rem);
  }
  .home .secInfo .infoBox .infoList__text {
    width: 100%;
  }
  .home .secInfo .infoBox .infoList__text:nth-of-type(n + 2) {
    margin-top: 0;
  }
  .home .secInfo .infoBox .infoList__text a {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
  }
  .home .secInfo .infoLink {
    font-size: clamp(1.7rem, 4.07vw, 3.5rem);
    background: #239CEF;
    display: block;
    text-align: center;
    padding: clamp(2rem, 4.651vw, 4rem) clamp(1rem, 1.744vw, 1.5rem);
    margin-top: clamp(3.5rem, 8.14vw, 7rem);
    position: static;
  }
  .home .secInfo .infoLink::after {
    content: none;
  }
  .home .secMember {
    padding: 0 clamp(2.5rem, 5.814vw, 5rem) clamp(5rem, 11.628vw, 10rem);
    background: url(../img/top/top_bg04_sp.png) no-repeat left bottom/100%;
  }
  .home .secAbout {
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  .home .secAbout__inner {
    padding: clamp(5rem, 11.628vw, 10rem) clamp(2.5rem, 5.814vw, 5rem) 0;
  }
  .home .secAboutMain {
    gap: clamp(2.5rem, 5.814vw, 5rem) 0;
  }
  .home .secAboutMain__ttl--ruby {
    font-size: clamp(1rem, 2.326vw, 2rem);
    line-height: 2.5;
  }
  .home .secAboutMain__ttl--main {
    font-size: clamp(2.5rem, 5.814vw, 5rem);
    line-height: 1.33333;
  }
  .home .secAboutMain__cont {
    position: absolute;
  }
  .home .secAboutMain__col:first-child {
    background: #fff;
    position: relative;
  }
  .home .secAboutMain__col:first-child .secAboutMain__cont {
    top: initial;
    bottom: clamp(2.2rem, 5.233vw, 4.5rem);
    left: 0;
    transform: translateY(0);
    width: 100% !important;
    padding-left: clamp(2.4rem, 5.465vw, 4.7rem) !important;
  }
  .home .secAboutMain__col:first-child::after {
    width: clamp(4rem, 9.302vw, 8rem);
    height: clamp(4rem, 9.302vw, 8rem);
    top: initial;
    right: clamp(1.5rem, 3.488vw, 3rem);
    bottom: clamp(3rem, 6.977vw, 6rem);
    transform: translateY(0);
  }
  .home .secAboutMain__col:nth-child(-n+2) {
    width: 100%;
  }
  .home .secAboutMain__col:nth-child(-n+2) .secAboutMain__cont {
    width: 80%;
    left: 0;
    padding-left: clamp(12.5rem, 29.07vw, 25rem);
  }
  .home .secAboutMain__col:nth-child(-n+2)::after {
    width: clamp(4rem, 9.302vw, 8rem);
    height: clamp(4rem, 9.302vw, 8rem);
    right: clamp(1.5rem, 3.488vw, 3rem);
  }
  .home .secAboutMain__col:nth-child(n+3) {
    width: 100%;
    background: #fff;
  }
  .home .secAboutMain__col:nth-child(n+3) .secAboutMain__cont {
    width: 80%;
    bottom: initial;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding-left: clamp(12.5rem, 29.07vw, 25rem);
  }
  .home .secAboutMain__col:nth-child(n+3)::after {
    width: clamp(4rem, 9.302vw, 8rem);
    height: clamp(4rem, 9.302vw, 8rem);
    right: clamp(1.5rem, 3.488vw, 3rem);
    bottom: initial;
    top: 50%;
    transform: translateY(-50%);
  }
  .home .secAboutSub {
    margin-top: clamp(5rem, 11.628vw, 10rem);
  }
  .home .secAboutSub__video {
    width: calc(100% + clamp(5rem, 11.628vw, 10rem));
    margin-left: clamp(-2.5rem, -5.814vw, -5rem);
  }
  .home .secAboutSub__video iframe {
    width: 100%;
    height: 100%;
  }
  .home .secAccess {
    background: url(../img/top/top_bg05_sp.png) no-repeat center top/100%;
    padding-top: clamp(5rem, 11.628vw, 10rem);
  }
  .home .secAccess__inner {
    padding: 0 clamp(2.5rem, 5.814vw, 5rem);
  }
  .home .secAccess__cont {
    padding: clamp(2.5rem, 5.814vw, 5rem);
    flex-wrap: wrap;
    gap: clamp(5rem, 11.628vw, 10rem) 0;
  }
  .home .secAccess__col {
    width: 100%;
  }
  .home .secAccess__gMap {
    aspect-ratio: auto;
  }
  .home .secAccess__gMap iframe {
    max-height: 250px;
  }
  .home .secAccess__info--ruby {
    font-size: clamp(1rem, 2.326vw, 2rem);
    line-height: 3.3;
  }
  .home .secAccess__info--name {
    font-size: clamp(2rem, 4.651vw, 4rem);
    line-height: 2.425;
  }
  .home .secAccess__info--add {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    line-height: 2;
  }
  .home .secMessage {
    margin-top: clamp(5rem, 11.628vw, 10rem);
    background: url(../img/top/top_bg06_sp.png) no-repeat center bottom clamp(13rem, 30.233vw, 26rem)/100%;
    padding-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .home .secMessage__inner {
    padding: 0 clamp(2.5rem, 5.814vw, 5rem);
  }
  .home .secMessage__cont {
    display: flex;
    flex-wrap: wrap;
    gap: clamp(2.5rem, 5.814vw, 5rem) 0;
  }
  .home .secMessage__col {
    width: 100%;
    padding: clamp(2rem, 4.651vw, 4rem);
    display: block;
    position: relative;
  }
  .home .secMessage__icon {
    width: 22.7963525836%;
    margin: 0;
    position: absolute;
    top: clamp(2rem, 4.651vw, 4rem);
    left: clamp(2rem, 4.651vw, 4rem);
  }
  .home .secMessage__ttl {
    font-size: clamp(2rem, 4.651vw, 4rem);
    line-height: 1.5;
    margin-bottom: clamp(1.5rem, 3.488vw, 3rem);
    width: 69.6048632219%;
    margin-left: auto;
  }
  .home .secMessage__text {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    line-height: 2;
    width: 69.6048632219%;
    margin-left: auto;
  }
  .home .secMessage__btn {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    padding: clamp(1.8rem, 4.186vw, 3.6rem) clamp(1rem, 2.326vw, 2rem);
    width: 70%;
    margin: clamp(5rem, 11.628vw, 10rem) auto 0;
  }
}
/*=================================
  page--article
=================================*/
/* 記事一覧 */
.articleList {
  position: relative;
  z-index: 1;
}
.articleList__item {
  border-bottom: clamp(0.1rem, 0.073vw, 0.2rem) solid #AAAAAA;
}
@media screen and (min-width: 860px) {
  .articleList__item:hover .textWrap__ttl {
    text-decoration: underline !important;
  }
}
.articleList__item .itemWrap {
  padding: clamp(2.5rem, 1.83vw, 5rem) 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.articleList__item .itemWrap .thumb {
  width: 30%;
  margin-left: 5%;
  overflow: hidden;
}
.articleList__item .itemWrap .thumb img {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.articleList__item .itemWrap .thumb + .textWrap {
  width: 65%;
}
.articleList__item .itemWrap .textWrap {
  width: 100%;
}
.articleList__item .itemWrap .textWrap__date {
  font-size: clamp(1.2rem, 0.915vw, 2.5rem);
  margin-bottom: clamp(1.4rem, 1.025vw, 2.8rem);
}
.articleList__item .itemWrap .textWrap__ttl {
  font-size: clamp(1.8rem, 1.83vw, 5rem);
  font-weight: 400;
  line-height: 1.66666;
  color: #0080D9;
  padding-left: clamp(2rem, 1.464vw, 4rem);
  display: block;
  text-decoration: none;
  position: relative;
}
.articleList__item .itemWrap .textWrap__ttl::before {
  content: "";
  width: clamp(1rem, 0.732vw, 2rem);
  height: clamp(1rem, 0.732vw, 2rem);
  display: inline-block;
  background: #0080D9;
  border-radius: 50px;
  position: absolute;
  top: clamp(1.5rem, 1.098vw, 3rem);
  left: 0;
}
.articleList__item .itemWrap .textWrap__text {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  font-weight: 400;
  line-height: 2;
  margin-top: clamp(1.5rem, 1.098vw, 3rem);
}

.articleListWrap__inner {
  padding: clamp(2.5rem, 1.83vw, 5rem);
  background: #fff;
  max-width: clamp(119.5rem, 87.482vw, 239rem);
  margin: 0 auto;
}
.articleListWrap__inner h1, .articleListWrap__inner h2, .articleListWrap__inner h3, .articleListWrap__inner h4, .articleListWrap__inner h5, .articleListWrap__inner h6 {
  clear: both;
}
.articleListWrap__inner a:not([class]) {
  font-weight: 700;
}
.articleListWrap__inner p:not([class]) {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  line-height: 2;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
.articleListWrap__inner h1:not([class]), .articleListWrap__inner h1.wp-block-heading {
  font-size: clamp(3.5rem, 2.562vw, 7rem);
  font-weight: 400;
  color: #fff;
  line-height: 1.75;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
  padding: clamp(2rem, 1.464vw, 4rem) clamp(5rem, 3.66vw, 10rem);
  background: #0080D9;
  width: calc(100% + clamp(10rem, 7.321vw, 20rem));
  margin-left: clamp(-5rem, -3.66vw, -10rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .articleListWrap__inner h1:not([class]), .articleListWrap__inner h1.wp-block-heading {
    width: calc(100% + 200px);
    padding: clamp(2rem, 1.464vw, 4rem) 100px;
    margin-left: -100px;
  }
}
.articleListWrap__inner h2:not([class]), .articleListWrap__inner h2.wp-block-heading {
  font-size: clamp(3.5rem, 2.562vw, 7rem);
  font-weight: 400;
  color: #fff;
  line-height: 1.75;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
  padding: clamp(2rem, 1.464vw, 4rem) clamp(5rem, 3.66vw, 10rem);
  background: #0080D9;
  width: calc(100% + clamp(10rem, 7.321vw, 20rem));
  margin-left: clamp(-5rem, -3.66vw, -10rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .articleListWrap__inner h2:not([class]), .articleListWrap__inner h2.wp-block-heading {
    width: calc(100% + 200px);
    padding: clamp(2rem, 1.464vw, 4rem) 100px;
    margin-left: -100px;
  }
}
.articleListWrap__inner h3:not([class]), .articleListWrap__inner h3.wp-block-heading {
  font-size: clamp(3rem, 2.196vw, 6rem);
  font-weight: 500;
  line-height: 1.616666;
  color: #0080D9;
  border-bottom: 2px solid #AAAAAA;
  padding-bottom: clamp(2rem, 1.464vw, 4rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
.articleListWrap__inner h4:not([class]), .articleListWrap__inner h4.wp-block-heading {
  font-size: clamp(2.5rem, 1.83vw, 5rem);
  font-weight: 500;
  border-left: clamp(1rem, 0.732vw, 2rem) solid #0080D9;
  padding-left: clamp(1rem, 0.732vw, 2rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
}
.articleListWrap__inner h5:not([class]), .articleListWrap__inner h5.wp-block-heading {
  font-size: clamp(2rem, 1.464vw, 4rem);
  font-weight: 500;
  color: #0080D9;
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
}
.articleListWrap__inner h6:not([class]), .articleListWrap__inner h6.wp-block-heading {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  font-weight: 500;
  line-height: 1.77777;
  color: #0080D9;
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
  padding-left: clamp(2rem, 1.464vw, 4rem);
  position: relative;
}
.articleListWrap__inner h6:not([class])::before, .articleListWrap__inner h6.wp-block-heading::before {
  content: "";
  width: clamp(1rem, 0.732vw, 2rem);
  height: clamp(1rem, 0.732vw, 2rem);
  display: inline-block;
  background: #0080D9;
  transform: rotate(45deg);
  position: absolute;
  top: clamp(1.2rem, 0.878vw, 2.4rem);
  left: 0;
}
.articleListWrap__inner blockquote {
  position: relative;
  padding: clamp(5rem, 3.66vw, 10rem) clamp(2.5rem, 1.83vw, 5rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #F6F6F6;
}
.articleListWrap__inner blockquote::before {
  content: "“";
  font-size: clamp(5rem, 3.66vw, 10rem);
  font-weight: 700;
  color: #0080D9;
  display: inline-block;
  font-style: normal;
  width: clamp(5rem, 3.66vw, 10rem);
  height: clamp(5rem, 3.66vw, 10rem);
  line-height: 1.3;
  text-align: center;
  background: #F6F6F6;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.articleListWrap__inner blockquote h1 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.articleListWrap__inner blockquote h2 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.articleListWrap__inner blockquote h3 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.articleListWrap__inner blockquote h4 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.articleListWrap__inner blockquote h5 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.articleListWrap__inner blockquote h6 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.articleListWrap__inner blockquote p {
  font-style: normal;
}
.articleListWrap__inner blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.articleListWrap__inner .btnWrap {
  clear: both;
}
.articleListWrap__inner img:not([class]), .articleListWrap__inner.wp-block-heading {
  display: block;
  margin: clamp(4rem, 2.928vw, 8rem) auto clamp(5rem, 3.66vw, 10rem);
}
.articleListWrap__inner img.alignleft, .articleListWrap__inner img.imgleft {
  float: left;
  margin: 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem) 0;
  max-width: 38%;
}
.articleListWrap__inner img.alignright, .articleListWrap__inner img.imgright {
  float: right;
  margin: 0 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem);
  max-width: 38%;
}
.articleListWrap__inner figure.alignleft, .articleListWrap__inner figure.imgleft {
  float: left;
  margin: 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem) 0;
  max-width: 38%;
}
.articleListWrap__inner figure.alignright, .articleListWrap__inner figure.imgright {
  float: right;
  margin: 0 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem);
  max-width: 38%;
}
.articleListWrap__inner ul:not([class]) {
  margin: clamp(2.5rem, 1.83vw, 5rem) 0;
  padding: clamp(3.5rem, 2.562vw, 7rem);
  border: 2px solid #191919;
  clear: both;
  background: #fff;
}
.articleListWrap__inner ul:not([class]) li {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  padding-left: clamp(2.9rem, 2.123vw, 5.8rem);
  position: relative;
}
.articleListWrap__inner ul:not([class]) li::before {
  content: "";
  width: clamp(1.5rem, 1.098vw, 3rem);
  height: clamp(1.5rem, 1.098vw, 3rem);
  border-radius: 50px;
  background: #0080D9;
  display: inline-block;
  position: absolute;
  top: clamp(0.5rem, 0.805vw, 2.2rem);
  left: 0;
}
.articleListWrap__inner ul:not([class]) li + li {
  margin-top: clamp(2rem, 1.464vw, 4rem);
}
.articleListWrap__inner ul:not([class]) h1::before {
  content: none;
}
.articleListWrap__inner ul:not([class]) h2::before {
  content: none;
}
.articleListWrap__inner ul:not([class]) h3::before {
  content: none;
}
.articleListWrap__inner ul:not([class]) h4::before {
  content: none;
}
.articleListWrap__inner ul:not([class]) h5::before {
  content: none;
}
.articleListWrap__inner ul:not([class]) h6::before {
  content: none;
}
.articleListWrap__inner ol:not([class]) {
  margin: clamp(2.5rem, 1.83vw, 5rem) 0;
  padding: clamp(3.5rem, 2.562vw, 7rem);
  border: 2px solid #191919;
  counter-reset: listNum;
  clear: both;
  background: #fff;
}
.articleListWrap__inner ol:not([class]) li {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  padding-left: clamp(2.1rem, 1.537vw, 4.2rem);
  position: relative;
}
.articleListWrap__inner ol:not([class]) li::before {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  counter-increment: listNum;
  content: counter(listNum);
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  color: #0080D9;
  line-height: 2;
  display: inline-block;
  position: absolute;
  top: clamp(0.1rem, 0.073vw, 0.2rem);
  left: 0;
}
.articleListWrap__inner ol:not([class]) li + li {
  margin-top: clamp(2rem, 1.464vw, 4rem);
}
.articleListWrap__inner ol:not([class]) h1::before {
  content: none;
}
.articleListWrap__inner ol:not([class]) h2::before {
  content: none;
}
.articleListWrap__inner ol:not([class]) h3::before {
  content: none;
}
.articleListWrap__inner ol:not([class]) h4::before {
  content: none;
}
.articleListWrap__inner ol:not([class]) h5::before {
  content: none;
}
.articleListWrap__inner ol:not([class]) h6::before {
  content: none;
}
.articleListWrap__inner iframe {
  max-width: 100%;
  border: none;
}
.articleListWrap__inner .wp-block-file:not(.wp-element-button) {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
}
.articleListWrap__inner .wp-block-file__button {
  padding: 11px 30px;
  display: inline-block;
}
@media screen and (min-width: 0) and (max-width: 860px) {
  .articleListWrap__inner p:not([class]) {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    margin-bottom: clamp(2rem, 4.651vw, 4rem);
  }
  .articleListWrap__inner h1:not([class]), .articleListWrap__inner h1.wp-block-heading {
    font-size: clamp(2.2rem, 5.233vw, 4.5rem);
    line-height: 1.77777;
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
    padding: clamp(1.8rem, 4.07vw, 3.5rem) clamp(2.3rem, 5.465vw, 4.7rem);
    width: calc(100% + clamp(5rem, 11.628vw, 10rem));
    margin-left: clamp(-2.5rem, -5.814vw, -5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .articleListWrap__inner h2:not([class]), .articleListWrap__inner h2.wp-block-heading {
    font-size: clamp(2.2rem, 5.233vw, 4.5rem);
    line-height: 1.77777;
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
    padding: clamp(1.8rem, 4.07vw, 3.5rem) clamp(2.3rem, 5.465vw, 4.7rem);
    width: calc(100% + clamp(5rem, 11.628vw, 10rem));
    margin-left: clamp(-2.5rem, -5.814vw, -5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .articleListWrap__inner h3:not([class]), .articleListWrap__inner h3.wp-block-heading {
    font-size: clamp(2rem, 4.651vw, 4rem);
    line-height: 1.5;
    padding-bottom: clamp(2rem, 4.651vw, 4rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .articleListWrap__inner h4:not([class]), .articleListWrap__inner h4.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    border-left: clamp(0.7rem, 1.744vw, 1.5rem) solid #0080D9;
    padding-left: clamp(0.7rem, 1.744vw, 1.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleListWrap__inner h5:not([class]), .articleListWrap__inner h5.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleListWrap__inner h6:not([class]), .articleListWrap__inner h6.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
    padding-left: clamp(2rem, 4.651vw, 4rem);
  }
  .articleListWrap__inner h6:not([class])::before, .articleListWrap__inner h6.wp-block-heading::before {
    width: clamp(1rem, 2.326vw, 2rem);
    height: clamp(1rem, 2.326vw, 2rem);
    top: clamp(1.2rem, 2.791vw, 2.4rem);
  }
  .articleListWrap__inner blockquote {
    position: relative;
    padding: clamp(5rem, 11.628vw, 10rem) clamp(2.5rem, 1.83vw, 5rem), 860;
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleListWrap__inner blockquote::before {
    font-size: clamp(5rem, 11.628vw, 10rem);
    width: clamp(5rem, 11.628vw, 10rem);
    height: clamp(5rem, 11.628vw, 10rem);
  }
  .articleListWrap__inner blockquote h1 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .articleListWrap__inner blockquote h2 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .articleListWrap__inner blockquote h3 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .articleListWrap__inner blockquote h4 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .articleListWrap__inner blockquote h5 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .articleListWrap__inner blockquote h6 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .articleListWrap__inner img:not([class]), .articleListWrap__inner.wp-block-heading {
    margin: clamp(5rem, 11.628vw, 10rem) auto;
  }
  .articleListWrap__inner img.alignleft, .articleListWrap__inner img.imgleft {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .articleListWrap__inner img.alignright, .articleListWrap__inner img.imgright {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .articleListWrap__inner figure.alignleft, .articleListWrap__inner figure.imgleft {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .articleListWrap__inner figure.alignright, .articleListWrap__inner figure.imgright {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .articleListWrap__inner ul:not([class]) {
    margin: clamp(2.5rem, 5.814vw, 5rem) 0;
    padding: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleListWrap__inner ul:not([class]) li {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    padding-left: clamp(2.9rem, 6.744vw, 5.8rem);
  }
  .articleListWrap__inner ul:not([class]) li::before {
    width: clamp(1.5rem, 3.488vw, 3rem);
    height: clamp(1.5rem, 3.488vw, 3rem);
    top: clamp(0.5rem, 2.558vw, 2.2rem);
  }
  .articleListWrap__inner ul:not([class]) li + li {
    margin-top: clamp(2rem, 4.651vw, 4rem);
  }
  .articleListWrap__inner ol:not([class]) {
    margin: clamp(2.5rem, 5.814vw, 5rem) 0;
    padding: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleListWrap__inner ol:not([class]) li {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    padding-left: clamp(2.1rem, 4.884vw, 4.2rem);
  }
  .articleListWrap__inner ol:not([class]) li::before {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    top: clamp(0.1rem, 0.233vw, 0.2rem);
  }
  .articleListWrap__inner ol:not([class]) li + li {
    margin-top: clamp(2rem, 4.651vw, 4rem);
  }
  .articleListWrap__inner .wp-block-file:not(.wp-element-button) {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
  }
  .articleListWrap__inner .wp-block-file__button {
    padding: 20px 15px;
  }
}
.articleListWrap__inner > h1:first-child {
  margin-top: 0 !important;
}
.articleListWrap__inner > h2:first-child {
  margin-top: 0 !important;
}
.articleListWrap__inner > h3:first-child {
  margin-top: 0 !important;
}
.articleListWrap__inner > h4:first-child {
  margin-top: 0 !important;
}
.articleListWrap__inner > h5:first-child {
  margin-top: 0 !important;
}
.articleListWrap__inner > h6:first-child {
  margin-top: 0 !important;
}
.articleListWrap__inner .pager + h1 {
  margin-top: clamp(5rem, 3.66vw, 10rem);
}
.articleListWrap__inner .pager + h2 {
  margin-top: clamp(5rem, 3.66vw, 10rem);
}
.articleListWrap__inner .pager + h3 {
  margin-top: clamp(5rem, 3.66vw, 10rem);
}
.articleListWrap__inner .pager + h4 {
  margin-top: clamp(5rem, 3.66vw, 10rem);
}
.articleListWrap__inner .pager + h5 {
  margin-top: clamp(5rem, 3.66vw, 10rem);
}
.articleListWrap__inner .pager + h6 {
  margin-top: clamp(5rem, 3.66vw, 10rem);
}

/* singlePage */
.singlePage {
  /*---------------------
    メインビジュアル
  ---------------------*/
}
.singlePage .articleMv {
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}
.singlePage .articleMv img {
  margin: clamp(2.5rem, 1.83vw, 5rem) auto !important;
}
.singlePage .date {
  display: flex;
  justify-content: flex-end;
}
.singlePage .date * {
  font-size: clamp(1.2rem, 0.732vw, 2rem) !important;
  line-height: 1;
  color: #AAAAAA;
}
.singlePage .date * + * {
  margin: 0 0 0 1rem !important;
}
@media screen and (min-width: 0) and (max-width: 860px) {
  .singlePage {
    /*---------------------
      メインビジュアル
    ---------------------*/
  }
  .singlePage .date {
    justify-content: flex-start;
  }
  .singlePage .date * {
    font-size: 1.1rem !important;
  }
}
.singlePage__inner {
  padding: clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem);
  background: #fff;
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .singlePage__inner {
    padding: 100px 100px;
  }
}
.singlePage__inner h1, .singlePage__inner h2, .singlePage__inner h3, .singlePage__inner h4, .singlePage__inner h5, .singlePage__inner h6 {
  clear: both;
}
.singlePage__inner a:not([class]) {
  font-weight: 700;
}
.singlePage__inner p:not([class]) {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  line-height: 2;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
.singlePage__inner h1:not([class]), .singlePage__inner h1.wp-block-heading {
  font-size: clamp(3.5rem, 2.562vw, 7rem);
  font-weight: 400;
  color: #fff;
  line-height: 1.75;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
  padding: clamp(2rem, 1.464vw, 4rem) clamp(5rem, 3.66vw, 10rem);
  background: #0080D9;
  width: calc(100% + clamp(10rem, 7.321vw, 20rem));
  margin-left: clamp(-5rem, -3.66vw, -10rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .singlePage__inner h1:not([class]), .singlePage__inner h1.wp-block-heading {
    width: calc(100% + 200px);
    padding: clamp(2rem, 1.464vw, 4rem) 100px;
    margin-left: -100px;
  }
}
.singlePage__inner h2:not([class]), .singlePage__inner h2.wp-block-heading {
  font-size: clamp(3.5rem, 2.562vw, 7rem);
  font-weight: 400;
  color: #fff;
  line-height: 1.75;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
  padding: clamp(2rem, 1.464vw, 4rem) clamp(5rem, 3.66vw, 10rem);
  background: #0080D9;
  width: calc(100% + clamp(10rem, 7.321vw, 20rem));
  margin-left: clamp(-5rem, -3.66vw, -10rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .singlePage__inner h2:not([class]), .singlePage__inner h2.wp-block-heading {
    width: calc(100% + 200px);
    padding: clamp(2rem, 1.464vw, 4rem) 100px;
    margin-left: -100px;
  }
}
.singlePage__inner h3:not([class]), .singlePage__inner h3.wp-block-heading {
  font-size: clamp(3rem, 2.196vw, 6rem);
  font-weight: 500;
  line-height: 1.616666;
  color: #0080D9;
  border-bottom: 2px solid #AAAAAA;
  padding-bottom: clamp(2rem, 1.464vw, 4rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
.singlePage__inner h4:not([class]), .singlePage__inner h4.wp-block-heading {
  font-size: clamp(2.5rem, 1.83vw, 5rem);
  font-weight: 500;
  border-left: clamp(1rem, 0.732vw, 2rem) solid #0080D9;
  padding-left: clamp(1rem, 0.732vw, 2rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
}
.singlePage__inner h5:not([class]), .singlePage__inner h5.wp-block-heading {
  font-size: clamp(2rem, 1.464vw, 4rem);
  font-weight: 500;
  color: #0080D9;
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
}
.singlePage__inner h6:not([class]), .singlePage__inner h6.wp-block-heading {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  font-weight: 500;
  line-height: 1.77777;
  color: #0080D9;
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
  padding-left: clamp(2rem, 1.464vw, 4rem);
  position: relative;
}
.singlePage__inner h6:not([class])::before, .singlePage__inner h6.wp-block-heading::before {
  content: "";
  width: clamp(1rem, 0.732vw, 2rem);
  height: clamp(1rem, 0.732vw, 2rem);
  display: inline-block;
  background: #0080D9;
  transform: rotate(45deg);
  position: absolute;
  top: clamp(1.2rem, 0.878vw, 2.4rem);
  left: 0;
}
.singlePage__inner blockquote {
  position: relative;
  padding: clamp(5rem, 3.66vw, 10rem) clamp(2.5rem, 1.83vw, 5rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #F6F6F6;
}
.singlePage__inner blockquote::before {
  content: "“";
  font-size: clamp(5rem, 3.66vw, 10rem);
  font-weight: 700;
  color: #0080D9;
  display: inline-block;
  font-style: normal;
  width: clamp(5rem, 3.66vw, 10rem);
  height: clamp(5rem, 3.66vw, 10rem);
  line-height: 1.3;
  text-align: center;
  background: #F6F6F6;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.singlePage__inner blockquote h1 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.singlePage__inner blockquote h2 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.singlePage__inner blockquote h3 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.singlePage__inner blockquote h4 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.singlePage__inner blockquote h5 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.singlePage__inner blockquote h6 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.singlePage__inner blockquote p {
  font-style: normal;
}
.singlePage__inner blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.singlePage__inner .btnWrap {
  clear: both;
}
.singlePage__inner img:not([class]), .singlePage__inner.wp-block-heading {
  display: block;
  margin: clamp(4rem, 2.928vw, 8rem) auto clamp(5rem, 3.66vw, 10rem);
}
.singlePage__inner img.alignleft, .singlePage__inner img.imgleft {
  float: left;
  margin: 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem) 0;
  max-width: 38%;
}
.singlePage__inner img.alignright, .singlePage__inner img.imgright {
  float: right;
  margin: 0 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem);
  max-width: 38%;
}
.singlePage__inner figure.alignleft, .singlePage__inner figure.imgleft {
  float: left;
  margin: 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem) 0;
  max-width: 38%;
}
.singlePage__inner figure.alignright, .singlePage__inner figure.imgright {
  float: right;
  margin: 0 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem);
  max-width: 38%;
}
.singlePage__inner ul:not([class]) {
  margin: clamp(2.5rem, 1.83vw, 5rem) 0;
  padding: clamp(3.5rem, 2.562vw, 7rem);
  border: 2px solid #191919;
  clear: both;
  background: #fff;
}
.singlePage__inner ul:not([class]) li {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  padding-left: clamp(2.9rem, 2.123vw, 5.8rem);
  position: relative;
}
.singlePage__inner ul:not([class]) li::before {
  content: "";
  width: clamp(1.5rem, 1.098vw, 3rem);
  height: clamp(1.5rem, 1.098vw, 3rem);
  border-radius: 50px;
  background: #0080D9;
  display: inline-block;
  position: absolute;
  top: clamp(0.5rem, 0.805vw, 2.2rem);
  left: 0;
}
.singlePage__inner ul:not([class]) li + li {
  margin-top: clamp(2rem, 1.464vw, 4rem);
}
.singlePage__inner ul:not([class]) h1::before {
  content: none;
}
.singlePage__inner ul:not([class]) h2::before {
  content: none;
}
.singlePage__inner ul:not([class]) h3::before {
  content: none;
}
.singlePage__inner ul:not([class]) h4::before {
  content: none;
}
.singlePage__inner ul:not([class]) h5::before {
  content: none;
}
.singlePage__inner ul:not([class]) h6::before {
  content: none;
}
.singlePage__inner ol:not([class]) {
  margin: clamp(2.5rem, 1.83vw, 5rem) 0;
  padding: clamp(3.5rem, 2.562vw, 7rem);
  border: 2px solid #191919;
  counter-reset: listNum;
  clear: both;
  background: #fff;
}
.singlePage__inner ol:not([class]) li {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  padding-left: clamp(2.1rem, 1.537vw, 4.2rem);
  position: relative;
}
.singlePage__inner ol:not([class]) li::before {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  counter-increment: listNum;
  content: counter(listNum);
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  color: #0080D9;
  line-height: 2;
  display: inline-block;
  position: absolute;
  top: clamp(0.1rem, 0.073vw, 0.2rem);
  left: 0;
}
.singlePage__inner ol:not([class]) li + li {
  margin-top: clamp(2rem, 1.464vw, 4rem);
}
.singlePage__inner ol:not([class]) h1::before {
  content: none;
}
.singlePage__inner ol:not([class]) h2::before {
  content: none;
}
.singlePage__inner ol:not([class]) h3::before {
  content: none;
}
.singlePage__inner ol:not([class]) h4::before {
  content: none;
}
.singlePage__inner ol:not([class]) h5::before {
  content: none;
}
.singlePage__inner ol:not([class]) h6::before {
  content: none;
}
.singlePage__inner iframe {
  max-width: 100%;
  border: none;
}
.singlePage__inner .wp-block-file:not(.wp-element-button) {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
}
.singlePage__inner .wp-block-file__button {
  padding: 11px 30px;
  display: inline-block;
}
@media screen and (min-width: 0) and (max-width: 860px) {
  .singlePage__inner p:not([class]) {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    margin-bottom: clamp(2rem, 4.651vw, 4rem);
  }
  .singlePage__inner h1:not([class]), .singlePage__inner h1.wp-block-heading {
    font-size: clamp(2.2rem, 5.233vw, 4.5rem);
    line-height: 1.77777;
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
    padding: clamp(1.8rem, 4.07vw, 3.5rem) clamp(2.3rem, 5.465vw, 4.7rem);
    width: calc(100% + clamp(5rem, 11.628vw, 10rem));
    margin-left: clamp(-2.5rem, -5.814vw, -5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .singlePage__inner h2:not([class]), .singlePage__inner h2.wp-block-heading {
    font-size: clamp(2.2rem, 5.233vw, 4.5rem);
    line-height: 1.77777;
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
    padding: clamp(1.8rem, 4.07vw, 3.5rem) clamp(2.3rem, 5.465vw, 4.7rem);
    width: calc(100% + clamp(5rem, 11.628vw, 10rem));
    margin-left: clamp(-2.5rem, -5.814vw, -5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .singlePage__inner h3:not([class]), .singlePage__inner h3.wp-block-heading {
    font-size: clamp(2rem, 4.651vw, 4rem);
    line-height: 1.5;
    padding-bottom: clamp(2rem, 4.651vw, 4rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .singlePage__inner h4:not([class]), .singlePage__inner h4.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    border-left: clamp(0.7rem, 1.744vw, 1.5rem) solid #0080D9;
    padding-left: clamp(0.7rem, 1.744vw, 1.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .singlePage__inner h5:not([class]), .singlePage__inner h5.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .singlePage__inner h6:not([class]), .singlePage__inner h6.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
    padding-left: clamp(2rem, 4.651vw, 4rem);
  }
  .singlePage__inner h6:not([class])::before, .singlePage__inner h6.wp-block-heading::before {
    width: clamp(1rem, 2.326vw, 2rem);
    height: clamp(1rem, 2.326vw, 2rem);
    top: clamp(1.2rem, 2.791vw, 2.4rem);
  }
  .singlePage__inner blockquote {
    position: relative;
    padding: clamp(5rem, 11.628vw, 10rem) clamp(2.5rem, 1.83vw, 5rem), 860;
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .singlePage__inner blockquote::before {
    font-size: clamp(5rem, 11.628vw, 10rem);
    width: clamp(5rem, 11.628vw, 10rem);
    height: clamp(5rem, 11.628vw, 10rem);
  }
  .singlePage__inner blockquote h1 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .singlePage__inner blockquote h2 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .singlePage__inner blockquote h3 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .singlePage__inner blockquote h4 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .singlePage__inner blockquote h5 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .singlePage__inner blockquote h6 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .singlePage__inner img:not([class]), .singlePage__inner.wp-block-heading {
    margin: clamp(5rem, 11.628vw, 10rem) auto;
  }
  .singlePage__inner img.alignleft, .singlePage__inner img.imgleft {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .singlePage__inner img.alignright, .singlePage__inner img.imgright {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .singlePage__inner figure.alignleft, .singlePage__inner figure.imgleft {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .singlePage__inner figure.alignright, .singlePage__inner figure.imgright {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .singlePage__inner ul:not([class]) {
    margin: clamp(2.5rem, 5.814vw, 5rem) 0;
    padding: clamp(2.5rem, 5.814vw, 5rem);
  }
  .singlePage__inner ul:not([class]) li {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    padding-left: clamp(2.9rem, 6.744vw, 5.8rem);
  }
  .singlePage__inner ul:not([class]) li::before {
    width: clamp(1.5rem, 3.488vw, 3rem);
    height: clamp(1.5rem, 3.488vw, 3rem);
    top: clamp(0.5rem, 2.558vw, 2.2rem);
  }
  .singlePage__inner ul:not([class]) li + li {
    margin-top: clamp(2rem, 4.651vw, 4rem);
  }
  .singlePage__inner ol:not([class]) {
    margin: clamp(2.5rem, 5.814vw, 5rem) 0;
    padding: clamp(2.5rem, 5.814vw, 5rem);
  }
  .singlePage__inner ol:not([class]) li {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    padding-left: clamp(2.1rem, 4.884vw, 4.2rem);
  }
  .singlePage__inner ol:not([class]) li::before {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    top: clamp(0.1rem, 0.233vw, 0.2rem);
  }
  .singlePage__inner ol:not([class]) li + li {
    margin-top: clamp(2rem, 4.651vw, 4rem);
  }
  .singlePage__inner .wp-block-file:not(.wp-element-button) {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
  }
  .singlePage__inner .wp-block-file__button {
    padding: 20px 15px;
  }
}
.singlePage__inner > h1:first-child {
  margin-top: clamp(-5rem, -3.66vw, -10rem) !important;
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .singlePage__inner > h1:first-child {
    margin-top: -100px !important;
  }
}
.singlePage__inner > h2:first-child {
  margin-top: clamp(-5rem, -3.66vw, -10rem) !important;
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .singlePage__inner > h2:first-child {
    margin-top: -100px !important;
  }
}

/* 記事詳細ページ */
.articleDetail {
  /*---------------------
    メインビジュアル
  ---------------------*/
}
.articleDetail .articleMv {
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}
.articleDetail .articleMv img {
  margin: clamp(2.5rem, 1.83vw, 5rem) auto !important;
}
.articleDetail .date {
  display: flex;
  justify-content: flex-end;
}
.articleDetail .date * {
  font-size: clamp(1.2rem, 0.732vw, 2rem) !important;
  line-height: 1;
  color: #AAAAAA;
}
.articleDetail .date * + * {
  margin: 0 0 0 1rem !important;
}
@media screen and (min-width: 0) and (max-width: 860px) {
  .articleDetail {
    /*---------------------
      メインビジュアル
    ---------------------*/
  }
  .articleDetail .date {
    justify-content: flex-start;
  }
  .articleDetail .date * {
    font-size: 1.1rem !important;
  }
}
.articleDetail__inner {
  padding: clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem);
  background: #fff;
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .articleDetail__inner {
    padding: 100px 100px;
  }
}
.articleDetail__inner h1, .articleDetail__inner h2, .articleDetail__inner h3, .articleDetail__inner h4, .articleDetail__inner h5, .articleDetail__inner h6 {
  clear: both;
}
.articleDetail__inner a:not([class]) {
  font-weight: 700;
}
.articleDetail__inner p:not([class]) {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  line-height: 2;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
.articleDetail__inner h1:not([class]), .articleDetail__inner h1.wp-block-heading {
  font-size: clamp(3.5rem, 2.562vw, 7rem);
  font-weight: 400;
  color: #fff;
  line-height: 1.75;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
  padding: clamp(2rem, 1.464vw, 4rem) clamp(5rem, 3.66vw, 10rem);
  background: #0080D9;
  width: calc(100% + clamp(10rem, 7.321vw, 20rem));
  margin-left: clamp(-5rem, -3.66vw, -10rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .articleDetail__inner h1:not([class]), .articleDetail__inner h1.wp-block-heading {
    width: calc(100% + 200px);
    padding: clamp(2rem, 1.464vw, 4rem) 100px;
    margin-left: -100px;
  }
}
.articleDetail__inner h2:not([class]), .articleDetail__inner h2.wp-block-heading {
  font-size: clamp(3.5rem, 2.562vw, 7rem);
  font-weight: 400;
  color: #fff;
  line-height: 1.75;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
  padding: clamp(2rem, 1.464vw, 4rem) clamp(5rem, 3.66vw, 10rem);
  background: #0080D9;
  width: calc(100% + clamp(10rem, 7.321vw, 20rem));
  margin-left: clamp(-5rem, -3.66vw, -10rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .articleDetail__inner h2:not([class]), .articleDetail__inner h2.wp-block-heading {
    width: calc(100% + 200px);
    padding: clamp(2rem, 1.464vw, 4rem) 100px;
    margin-left: -100px;
  }
}
.articleDetail__inner h3:not([class]), .articleDetail__inner h3.wp-block-heading {
  font-size: clamp(3rem, 2.196vw, 6rem);
  font-weight: 500;
  line-height: 1.616666;
  color: #0080D9;
  border-bottom: 2px solid #AAAAAA;
  padding-bottom: clamp(2rem, 1.464vw, 4rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
.articleDetail__inner h4:not([class]), .articleDetail__inner h4.wp-block-heading {
  font-size: clamp(2.5rem, 1.83vw, 5rem);
  font-weight: 500;
  border-left: clamp(1rem, 0.732vw, 2rem) solid #0080D9;
  padding-left: clamp(1rem, 0.732vw, 2rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
}
.articleDetail__inner h5:not([class]), .articleDetail__inner h5.wp-block-heading {
  font-size: clamp(2rem, 1.464vw, 4rem);
  font-weight: 500;
  color: #0080D9;
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
}
.articleDetail__inner h6:not([class]), .articleDetail__inner h6.wp-block-heading {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  font-weight: 500;
  line-height: 1.77777;
  color: #0080D9;
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
  padding-left: clamp(2rem, 1.464vw, 4rem);
  position: relative;
}
.articleDetail__inner h6:not([class])::before, .articleDetail__inner h6.wp-block-heading::before {
  content: "";
  width: clamp(1rem, 0.732vw, 2rem);
  height: clamp(1rem, 0.732vw, 2rem);
  display: inline-block;
  background: #0080D9;
  transform: rotate(45deg);
  position: absolute;
  top: clamp(1.2rem, 0.878vw, 2.4rem);
  left: 0;
}
.articleDetail__inner blockquote {
  position: relative;
  padding: clamp(5rem, 3.66vw, 10rem) clamp(2.5rem, 1.83vw, 5rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #F6F6F6;
}
.articleDetail__inner blockquote::before {
  content: "“";
  font-size: clamp(5rem, 3.66vw, 10rem);
  font-weight: 700;
  color: #0080D9;
  display: inline-block;
  font-style: normal;
  width: clamp(5rem, 3.66vw, 10rem);
  height: clamp(5rem, 3.66vw, 10rem);
  line-height: 1.3;
  text-align: center;
  background: #F6F6F6;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.articleDetail__inner blockquote h1 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.articleDetail__inner blockquote h2 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.articleDetail__inner blockquote h3 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.articleDetail__inner blockquote h4 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.articleDetail__inner blockquote h5 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.articleDetail__inner blockquote h6 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.articleDetail__inner blockquote p {
  font-style: normal;
}
.articleDetail__inner blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.articleDetail__inner .btnWrap {
  clear: both;
}
.articleDetail__inner img:not([class]), .articleDetail__inner.wp-block-heading {
  display: block;
  margin: clamp(4rem, 2.928vw, 8rem) auto clamp(5rem, 3.66vw, 10rem);
}
.articleDetail__inner img.alignleft, .articleDetail__inner img.imgleft {
  float: left;
  margin: 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem) 0;
  max-width: 38%;
}
.articleDetail__inner img.alignright, .articleDetail__inner img.imgright {
  float: right;
  margin: 0 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem);
  max-width: 38%;
}
.articleDetail__inner figure.alignleft, .articleDetail__inner figure.imgleft {
  float: left;
  margin: 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem) 0;
  max-width: 38%;
}
.articleDetail__inner figure.alignright, .articleDetail__inner figure.imgright {
  float: right;
  margin: 0 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem);
  max-width: 38%;
}
.articleDetail__inner ul:not([class]) {
  margin: clamp(2.5rem, 1.83vw, 5rem) 0;
  padding: clamp(3.5rem, 2.562vw, 7rem);
  border: 2px solid #191919;
  clear: both;
  background: #fff;
}
.articleDetail__inner ul:not([class]) li {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  padding-left: clamp(2.9rem, 2.123vw, 5.8rem);
  position: relative;
}
.articleDetail__inner ul:not([class]) li::before {
  content: "";
  width: clamp(1.5rem, 1.098vw, 3rem);
  height: clamp(1.5rem, 1.098vw, 3rem);
  border-radius: 50px;
  background: #0080D9;
  display: inline-block;
  position: absolute;
  top: clamp(0.5rem, 0.805vw, 2.2rem);
  left: 0;
}
.articleDetail__inner ul:not([class]) li + li {
  margin-top: clamp(2rem, 1.464vw, 4rem);
}
.articleDetail__inner ul:not([class]) h1::before {
  content: none;
}
.articleDetail__inner ul:not([class]) h2::before {
  content: none;
}
.articleDetail__inner ul:not([class]) h3::before {
  content: none;
}
.articleDetail__inner ul:not([class]) h4::before {
  content: none;
}
.articleDetail__inner ul:not([class]) h5::before {
  content: none;
}
.articleDetail__inner ul:not([class]) h6::before {
  content: none;
}
.articleDetail__inner ol:not([class]) {
  margin: clamp(2.5rem, 1.83vw, 5rem) 0;
  padding: clamp(3.5rem, 2.562vw, 7rem);
  border: 2px solid #191919;
  counter-reset: listNum;
  clear: both;
  background: #fff;
}
.articleDetail__inner ol:not([class]) li {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  padding-left: clamp(2.1rem, 1.537vw, 4.2rem);
  position: relative;
}
.articleDetail__inner ol:not([class]) li::before {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  counter-increment: listNum;
  content: counter(listNum);
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  color: #0080D9;
  line-height: 2;
  display: inline-block;
  position: absolute;
  top: clamp(0.1rem, 0.073vw, 0.2rem);
  left: 0;
}
.articleDetail__inner ol:not([class]) li + li {
  margin-top: clamp(2rem, 1.464vw, 4rem);
}
.articleDetail__inner ol:not([class]) h1::before {
  content: none;
}
.articleDetail__inner ol:not([class]) h2::before {
  content: none;
}
.articleDetail__inner ol:not([class]) h3::before {
  content: none;
}
.articleDetail__inner ol:not([class]) h4::before {
  content: none;
}
.articleDetail__inner ol:not([class]) h5::before {
  content: none;
}
.articleDetail__inner ol:not([class]) h6::before {
  content: none;
}
.articleDetail__inner iframe {
  max-width: 100%;
  border: none;
}
.articleDetail__inner .wp-block-file:not(.wp-element-button) {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
}
.articleDetail__inner .wp-block-file__button {
  padding: 11px 30px;
  display: inline-block;
}
@media screen and (min-width: 0) and (max-width: 860px) {
  .articleDetail__inner p:not([class]) {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    margin-bottom: clamp(2rem, 4.651vw, 4rem);
  }
  .articleDetail__inner h1:not([class]), .articleDetail__inner h1.wp-block-heading {
    font-size: clamp(2.2rem, 5.233vw, 4.5rem);
    line-height: 1.77777;
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
    padding: clamp(1.8rem, 4.07vw, 3.5rem) clamp(2.3rem, 5.465vw, 4.7rem);
    width: calc(100% + clamp(5rem, 11.628vw, 10rem));
    margin-left: clamp(-2.5rem, -5.814vw, -5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .articleDetail__inner h2:not([class]), .articleDetail__inner h2.wp-block-heading {
    font-size: clamp(2.2rem, 5.233vw, 4.5rem);
    line-height: 1.77777;
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
    padding: clamp(1.8rem, 4.07vw, 3.5rem) clamp(2.3rem, 5.465vw, 4.7rem);
    width: calc(100% + clamp(5rem, 11.628vw, 10rem));
    margin-left: clamp(-2.5rem, -5.814vw, -5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .articleDetail__inner h3:not([class]), .articleDetail__inner h3.wp-block-heading {
    font-size: clamp(2rem, 4.651vw, 4rem);
    line-height: 1.5;
    padding-bottom: clamp(2rem, 4.651vw, 4rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .articleDetail__inner h4:not([class]), .articleDetail__inner h4.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    border-left: clamp(0.7rem, 1.744vw, 1.5rem) solid #0080D9;
    padding-left: clamp(0.7rem, 1.744vw, 1.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleDetail__inner h5:not([class]), .articleDetail__inner h5.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleDetail__inner h6:not([class]), .articleDetail__inner h6.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
    padding-left: clamp(2rem, 4.651vw, 4rem);
  }
  .articleDetail__inner h6:not([class])::before, .articleDetail__inner h6.wp-block-heading::before {
    width: clamp(1rem, 2.326vw, 2rem);
    height: clamp(1rem, 2.326vw, 2rem);
    top: clamp(1.2rem, 2.791vw, 2.4rem);
  }
  .articleDetail__inner blockquote {
    position: relative;
    padding: clamp(5rem, 11.628vw, 10rem) clamp(2.5rem, 1.83vw, 5rem), 860;
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleDetail__inner blockquote::before {
    font-size: clamp(5rem, 11.628vw, 10rem);
    width: clamp(5rem, 11.628vw, 10rem);
    height: clamp(5rem, 11.628vw, 10rem);
  }
  .articleDetail__inner blockquote h1 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .articleDetail__inner blockquote h2 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .articleDetail__inner blockquote h3 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .articleDetail__inner blockquote h4 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .articleDetail__inner blockquote h5 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .articleDetail__inner blockquote h6 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .articleDetail__inner img:not([class]), .articleDetail__inner.wp-block-heading {
    margin: clamp(5rem, 11.628vw, 10rem) auto;
  }
  .articleDetail__inner img.alignleft, .articleDetail__inner img.imgleft {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .articleDetail__inner img.alignright, .articleDetail__inner img.imgright {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .articleDetail__inner figure.alignleft, .articleDetail__inner figure.imgleft {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .articleDetail__inner figure.alignright, .articleDetail__inner figure.imgright {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .articleDetail__inner ul:not([class]) {
    margin: clamp(2.5rem, 5.814vw, 5rem) 0;
    padding: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleDetail__inner ul:not([class]) li {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    padding-left: clamp(2.9rem, 6.744vw, 5.8rem);
  }
  .articleDetail__inner ul:not([class]) li::before {
    width: clamp(1.5rem, 3.488vw, 3rem);
    height: clamp(1.5rem, 3.488vw, 3rem);
    top: clamp(0.5rem, 2.558vw, 2.2rem);
  }
  .articleDetail__inner ul:not([class]) li + li {
    margin-top: clamp(2rem, 4.651vw, 4rem);
  }
  .articleDetail__inner ol:not([class]) {
    margin: clamp(2.5rem, 5.814vw, 5rem) 0;
    padding: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleDetail__inner ol:not([class]) li {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    padding-left: clamp(2.1rem, 4.884vw, 4.2rem);
  }
  .articleDetail__inner ol:not([class]) li::before {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    top: clamp(0.1rem, 0.233vw, 0.2rem);
  }
  .articleDetail__inner ol:not([class]) li + li {
    margin-top: clamp(2rem, 4.651vw, 4rem);
  }
  .articleDetail__inner .wp-block-file:not(.wp-element-button) {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
  }
  .articleDetail__inner .wp-block-file__button {
    padding: 20px 15px;
  }
}
.articleDetail__inner > h1:first-child {
  margin-top: 0 !important;
}
.articleDetail__inner > h2:first-child {
  margin-top: 0 !important;
}
.articleDetail__inner > h3:first-child {
  margin-top: 0 !important;
}
.articleDetail__inner > h4:first-child {
  margin-top: 0 !important;
}
.articleDetail__inner > h5:first-child {
  margin-top: 0 !important;
}
.articleDetail__inner > h6:first-child {
  margin-top: 0 !important;
}
.articleDetail__inner .tocBox + * {
  margin-top: 0 !important;
}

@media screen and (max-width: 860px) {
  /* 記事一覧 */
  .articleList__item {
    border-bottom: clamp(0.1rem, 0.233vw, 0.2rem) solid #AAAAAA;
  }
  .articleList__item .itemWrap {
    padding: clamp(2.5rem, 5.814vw, 5rem) 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
  .articleList__item .itemWrap .thumb {
    width: 100%;
    margin: 0 0 clamp(1.5rem, 3.488vw, 3rem);
  }
  .articleList__item .itemWrap .thumb + .textWrap {
    width: 100%;
  }
  .articleList__item .itemWrap .textWrap {
    width: 100%;
  }
  .articleList__item .itemWrap .textWrap__date {
    font-size: clamp(1.2rem, 2.791vw, 2.4rem);
    margin-bottom: clamp(2rem, 4.651vw, 4rem);
  }
  .articleList__item .itemWrap .textWrap__ttl {
    font-size: clamp(2rem, 4.651vw, 4rem);
    padding-left: clamp(2rem, 4.651vw, 4rem);
  }
  .articleList__item .itemWrap .textWrap__ttl::before {
    content: "";
    width: clamp(1rem, 2.326vw, 2rem);
    height: clamp(1rem, 2.326vw, 2rem);
    top: clamp(1.5rem, 3.488vw, 3rem);
  }
  .articleList__item .itemWrap .textWrap__text {
    font-size: clamp(1.6rem, 3.721vw, 3.2rem);
    margin-top: clamp(1.5rem, 3.488vw, 3rem);
  }
  .articleListWrap__inner {
    padding: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleListWrap__inner .pager + h1 {
    margin-top: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleListWrap__inner .pager + h2 {
    margin-top: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleListWrap__inner .pager + h3 {
    margin-top: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleListWrap__inner .pager + h4 {
    margin-top: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleListWrap__inner .pager + h5 {
    margin-top: clamp(2.5rem, 5.814vw, 5rem);
  }
  .articleListWrap__inner .pager + h6 {
    margin-top: clamp(2.5rem, 5.814vw, 5rem);
  }
  /* singlePage */
  .singlePage__inner {
    padding: 0 clamp(2.5rem, 5.814vw, 5rem);
    background: transparent;
  }
  /* 記事詳細ページ */
  .articleDetail__inner {
    padding: 0 clamp(2.5rem, 5.814vw, 5rem);
    background: transparent;
  }
}
/*=================================
  お問い合わせ
=================================*/
.cvWrap .cvBnr {
  background: url(../img/contact/contact_bnr_img_pc.png) no-repeat left clamp(5.5rem, 4.026vw, 11rem) bottom, #0080D9;
  background-size: auto calc(100% - clamp(2rem, 1.83vw, 5rem));
  mix-blend-mode: multiply;
  padding: clamp(8.5rem, 6.223vw, 17rem) clamp(2rem, 1.83vw, 5rem) clamp(8.5rem, 6.223vw, 17rem) clamp(20rem, 29.283vw, 80rem);
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .cvWrap .cvBnr {
    padding: clamp(8.5rem, 6.223vw, 17rem) clamp(2rem, 1.83vw, 5rem) clamp(8.5rem, 6.223vw, 17rem) clamp(47rem, 34.407vw, 94rem);
    background-size: auto calc(100% - clamp(2rem, 1.83vw, 5rem));
  }
}
.cvWrap .cvBnr__ttl {
  font-size: clamp(2.5rem, 1.83vw, 5rem);
  line-height: 1.5;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: clamp(2.2rem, 1.611vw, 4.4rem);
}
.cvWrap .cvBnr__tel {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(6rem, 4.392vw, 12rem);
  line-height: 1;
  color: #fff;
  text-decoration: none;
  padding-left: clamp(7rem, 7.613vw, 20.8rem);
  position: relative;
  pointer-events: none;
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .cvWrap .cvBnr__tel {
    font-size: clamp(8.2rem, 5.966vw, 16.3rem);
  }
}
.cvWrap .cvBnr__tel::before {
  content: "";
  width: clamp(5.6rem, 6.149vw, 16.8rem);
  height: clamp(3.6rem, 4.026vw, 11rem);
  display: inline-block;
  background: url(../img/icon_free.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.cvWrap .cvBnr .businessHours {
  display: flex;
  margin-top: clamp(2rem, 1.464vw, 4rem);
}
.cvWrap .cvBnr .businessHours__ttl {
  font-size: clamp(2rem, 1.464vw, 4rem);
  line-height: 1;
  color: #fff;
  padding-right: clamp(4.5rem, 3.294vw, 9rem);
  position: relative;
}
.cvWrap .cvBnr .businessHours__ttl::after {
  content: "";
  width: clamp(0.2rem, 0.146vw, 0.4rem);
  height: clamp(3.7rem, 2.709vw, 7.4rem);
  display: inline-block;
  background: #fff;
  position: absolute;
  top: 50%;
  right: clamp(2.2rem, 1.647vw, 4.5rem);
  transform: translateY(-50%) rotate(-45deg);
}
.cvWrap .cvBnr .businessHours__text {
  font-size: clamp(2rem, 1.464vw, 4rem);
  line-height: 1;
  color: #fff;
}
.cvWrap .cvTtl {
  font-size: clamp(4rem, 2.928vw, 8rem);
  font-weight: 400;
  line-height: 1.75;
  text-align: center;
  margin: clamp(7rem, 5.124vw, 14rem) 0 clamp(7.7rem, 5.637vw, 15.4rem);
}

.contactPage {
  /*---------------------
    メインビジュアル
  ---------------------*/
}
.contactPage .articleMv {
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}
.contactPage .articleMv img {
  margin: clamp(2.5rem, 1.83vw, 5rem) auto !important;
}
.contactPage .date {
  display: flex;
  justify-content: flex-end;
}
.contactPage .date * {
  font-size: clamp(1.2rem, 0.732vw, 2rem) !important;
  line-height: 1;
  color: #AAAAAA;
}
.contactPage .date * + * {
  margin: 0 0 0 1rem !important;
}
@media screen and (min-width: 0) and (max-width: 860px) {
  .contactPage {
    /*---------------------
      メインビジュアル
    ---------------------*/
  }
  .contactPage .date {
    justify-content: flex-start;
  }
  .contactPage .date * {
    font-size: 1.1rem !important;
  }
}
.contactPage__inner {
  padding: 0 clamp(5rem, 3.66vw, 10rem) clamp(4.1rem, 3.001vw, 8.2rem);
  background: #fff;
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .contactPage__inner {
    padding: 0 100px 80px;
  }
}
.contactPage__inner h1, .contactPage__inner h2, .contactPage__inner h3, .contactPage__inner h4, .contactPage__inner h5, .contactPage__inner h6 {
  clear: both;
}
.contactPage__inner a:not([class]) {
  font-weight: 700;
}
.contactPage__inner p:not([class]) {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  line-height: 2;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
.contactPage__inner h1:not([class]), .contactPage__inner h1.wp-block-heading {
  font-size: clamp(3.5rem, 2.562vw, 7rem);
  font-weight: 400;
  color: #fff;
  line-height: 1.75;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
  padding: clamp(2rem, 1.464vw, 4rem) clamp(5rem, 3.66vw, 10rem);
  background: #0080D9;
  width: calc(100% + clamp(10rem, 7.321vw, 20rem));
  margin-left: clamp(-5rem, -3.66vw, -10rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .contactPage__inner h1:not([class]), .contactPage__inner h1.wp-block-heading {
    width: calc(100% + 200px);
    padding: clamp(2rem, 1.464vw, 4rem) 100px;
    margin-left: -100px;
  }
}
.contactPage__inner h2:not([class]), .contactPage__inner h2.wp-block-heading {
  font-size: clamp(3.5rem, 2.562vw, 7rem);
  font-weight: 400;
  color: #fff;
  line-height: 1.75;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
  padding: clamp(2rem, 1.464vw, 4rem) clamp(5rem, 3.66vw, 10rem);
  background: #0080D9;
  width: calc(100% + clamp(10rem, 7.321vw, 20rem));
  margin-left: clamp(-5rem, -3.66vw, -10rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .contactPage__inner h2:not([class]), .contactPage__inner h2.wp-block-heading {
    width: calc(100% + 200px);
    padding: clamp(2rem, 1.464vw, 4rem) 100px;
    margin-left: -100px;
  }
}
.contactPage__inner h3:not([class]), .contactPage__inner h3.wp-block-heading {
  font-size: clamp(3rem, 2.196vw, 6rem);
  font-weight: 500;
  line-height: 1.616666;
  color: #0080D9;
  border-bottom: 2px solid #AAAAAA;
  padding-bottom: clamp(2rem, 1.464vw, 4rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
.contactPage__inner h4:not([class]), .contactPage__inner h4.wp-block-heading {
  font-size: clamp(2.5rem, 1.83vw, 5rem);
  font-weight: 500;
  border-left: clamp(1rem, 0.732vw, 2rem) solid #0080D9;
  padding-left: clamp(1rem, 0.732vw, 2rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
}
.contactPage__inner h5:not([class]), .contactPage__inner h5.wp-block-heading {
  font-size: clamp(2rem, 1.464vw, 4rem);
  font-weight: 500;
  color: #0080D9;
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
}
.contactPage__inner h6:not([class]), .contactPage__inner h6.wp-block-heading {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  font-weight: 500;
  line-height: 1.77777;
  color: #0080D9;
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
  padding-left: clamp(2rem, 1.464vw, 4rem);
  position: relative;
}
.contactPage__inner h6:not([class])::before, .contactPage__inner h6.wp-block-heading::before {
  content: "";
  width: clamp(1rem, 0.732vw, 2rem);
  height: clamp(1rem, 0.732vw, 2rem);
  display: inline-block;
  background: #0080D9;
  transform: rotate(45deg);
  position: absolute;
  top: clamp(1.2rem, 0.878vw, 2.4rem);
  left: 0;
}
.contactPage__inner blockquote {
  position: relative;
  padding: clamp(5rem, 3.66vw, 10rem) clamp(2.5rem, 1.83vw, 5rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #F6F6F6;
}
.contactPage__inner blockquote::before {
  content: "“";
  font-size: clamp(5rem, 3.66vw, 10rem);
  font-weight: 700;
  color: #0080D9;
  display: inline-block;
  font-style: normal;
  width: clamp(5rem, 3.66vw, 10rem);
  height: clamp(5rem, 3.66vw, 10rem);
  line-height: 1.3;
  text-align: center;
  background: #F6F6F6;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.contactPage__inner blockquote h1 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.contactPage__inner blockquote h2 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.contactPage__inner blockquote h3 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.contactPage__inner blockquote h4 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.contactPage__inner blockquote h5 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.contactPage__inner blockquote h6 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.contactPage__inner blockquote p {
  font-style: normal;
}
.contactPage__inner blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.contactPage__inner .btnWrap {
  clear: both;
}
.contactPage__inner img:not([class]), .contactPage__inner.wp-block-heading {
  display: block;
  margin: clamp(4rem, 2.928vw, 8rem) auto clamp(5rem, 3.66vw, 10rem);
}
.contactPage__inner img.alignleft, .contactPage__inner img.imgleft {
  float: left;
  margin: 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem) 0;
  max-width: 38%;
}
.contactPage__inner img.alignright, .contactPage__inner img.imgright {
  float: right;
  margin: 0 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem);
  max-width: 38%;
}
.contactPage__inner figure.alignleft, .contactPage__inner figure.imgleft {
  float: left;
  margin: 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem) 0;
  max-width: 38%;
}
.contactPage__inner figure.alignright, .contactPage__inner figure.imgright {
  float: right;
  margin: 0 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem);
  max-width: 38%;
}
.contactPage__inner ul:not([class]) {
  margin: clamp(2.5rem, 1.83vw, 5rem) 0;
  padding: clamp(3.5rem, 2.562vw, 7rem);
  border: 2px solid #191919;
  clear: both;
  background: #fff;
}
.contactPage__inner ul:not([class]) li {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  padding-left: clamp(2.9rem, 2.123vw, 5.8rem);
  position: relative;
}
.contactPage__inner ul:not([class]) li::before {
  content: "";
  width: clamp(1.5rem, 1.098vw, 3rem);
  height: clamp(1.5rem, 1.098vw, 3rem);
  border-radius: 50px;
  background: #0080D9;
  display: inline-block;
  position: absolute;
  top: clamp(0.5rem, 0.805vw, 2.2rem);
  left: 0;
}
.contactPage__inner ul:not([class]) li + li {
  margin-top: clamp(2rem, 1.464vw, 4rem);
}
.contactPage__inner ul:not([class]) h1::before {
  content: none;
}
.contactPage__inner ul:not([class]) h2::before {
  content: none;
}
.contactPage__inner ul:not([class]) h3::before {
  content: none;
}
.contactPage__inner ul:not([class]) h4::before {
  content: none;
}
.contactPage__inner ul:not([class]) h5::before {
  content: none;
}
.contactPage__inner ul:not([class]) h6::before {
  content: none;
}
.contactPage__inner ol:not([class]) {
  margin: clamp(2.5rem, 1.83vw, 5rem) 0;
  padding: clamp(3.5rem, 2.562vw, 7rem);
  border: 2px solid #191919;
  counter-reset: listNum;
  clear: both;
  background: #fff;
}
.contactPage__inner ol:not([class]) li {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  padding-left: clamp(2.1rem, 1.537vw, 4.2rem);
  position: relative;
}
.contactPage__inner ol:not([class]) li::before {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  counter-increment: listNum;
  content: counter(listNum);
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  color: #0080D9;
  line-height: 2;
  display: inline-block;
  position: absolute;
  top: clamp(0.1rem, 0.073vw, 0.2rem);
  left: 0;
}
.contactPage__inner ol:not([class]) li + li {
  margin-top: clamp(2rem, 1.464vw, 4rem);
}
.contactPage__inner ol:not([class]) h1::before {
  content: none;
}
.contactPage__inner ol:not([class]) h2::before {
  content: none;
}
.contactPage__inner ol:not([class]) h3::before {
  content: none;
}
.contactPage__inner ol:not([class]) h4::before {
  content: none;
}
.contactPage__inner ol:not([class]) h5::before {
  content: none;
}
.contactPage__inner ol:not([class]) h6::before {
  content: none;
}
.contactPage__inner iframe {
  max-width: 100%;
  border: none;
}
.contactPage__inner .wp-block-file:not(.wp-element-button) {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
}
.contactPage__inner .wp-block-file__button {
  padding: 11px 30px;
  display: inline-block;
}
@media screen and (min-width: 0) and (max-width: 860px) {
  .contactPage__inner p:not([class]) {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    margin-bottom: clamp(2rem, 4.651vw, 4rem);
  }
  .contactPage__inner h1:not([class]), .contactPage__inner h1.wp-block-heading {
    font-size: clamp(2.2rem, 5.233vw, 4.5rem);
    line-height: 1.77777;
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
    padding: clamp(1.8rem, 4.07vw, 3.5rem) clamp(2.3rem, 5.465vw, 4.7rem);
    width: calc(100% + clamp(5rem, 11.628vw, 10rem));
    margin-left: clamp(-2.5rem, -5.814vw, -5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .contactPage__inner h2:not([class]), .contactPage__inner h2.wp-block-heading {
    font-size: clamp(2.2rem, 5.233vw, 4.5rem);
    line-height: 1.77777;
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
    padding: clamp(1.8rem, 4.07vw, 3.5rem) clamp(2.3rem, 5.465vw, 4.7rem);
    width: calc(100% + clamp(5rem, 11.628vw, 10rem));
    margin-left: clamp(-2.5rem, -5.814vw, -5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .contactPage__inner h3:not([class]), .contactPage__inner h3.wp-block-heading {
    font-size: clamp(2rem, 4.651vw, 4rem);
    line-height: 1.5;
    padding-bottom: clamp(2rem, 4.651vw, 4rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .contactPage__inner h4:not([class]), .contactPage__inner h4.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    border-left: clamp(0.7rem, 1.744vw, 1.5rem) solid #0080D9;
    padding-left: clamp(0.7rem, 1.744vw, 1.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .contactPage__inner h5:not([class]), .contactPage__inner h5.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .contactPage__inner h6:not([class]), .contactPage__inner h6.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
    padding-left: clamp(2rem, 4.651vw, 4rem);
  }
  .contactPage__inner h6:not([class])::before, .contactPage__inner h6.wp-block-heading::before {
    width: clamp(1rem, 2.326vw, 2rem);
    height: clamp(1rem, 2.326vw, 2rem);
    top: clamp(1.2rem, 2.791vw, 2.4rem);
  }
  .contactPage__inner blockquote {
    position: relative;
    padding: clamp(5rem, 11.628vw, 10rem) clamp(2.5rem, 1.83vw, 5rem), 860;
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .contactPage__inner blockquote::before {
    font-size: clamp(5rem, 11.628vw, 10rem);
    width: clamp(5rem, 11.628vw, 10rem);
    height: clamp(5rem, 11.628vw, 10rem);
  }
  .contactPage__inner blockquote h1 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .contactPage__inner blockquote h2 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .contactPage__inner blockquote h3 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .contactPage__inner blockquote h4 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .contactPage__inner blockquote h5 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .contactPage__inner blockquote h6 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .contactPage__inner img:not([class]), .contactPage__inner.wp-block-heading {
    margin: clamp(5rem, 11.628vw, 10rem) auto;
  }
  .contactPage__inner img.alignleft, .contactPage__inner img.imgleft {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .contactPage__inner img.alignright, .contactPage__inner img.imgright {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .contactPage__inner figure.alignleft, .contactPage__inner figure.imgleft {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .contactPage__inner figure.alignright, .contactPage__inner figure.imgright {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .contactPage__inner ul:not([class]) {
    margin: clamp(2.5rem, 5.814vw, 5rem) 0;
    padding: clamp(2.5rem, 5.814vw, 5rem);
  }
  .contactPage__inner ul:not([class]) li {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    padding-left: clamp(2.9rem, 6.744vw, 5.8rem);
  }
  .contactPage__inner ul:not([class]) li::before {
    width: clamp(1.5rem, 3.488vw, 3rem);
    height: clamp(1.5rem, 3.488vw, 3rem);
    top: clamp(0.5rem, 2.558vw, 2.2rem);
  }
  .contactPage__inner ul:not([class]) li + li {
    margin-top: clamp(2rem, 4.651vw, 4rem);
  }
  .contactPage__inner ol:not([class]) {
    margin: clamp(2.5rem, 5.814vw, 5rem) 0;
    padding: clamp(2.5rem, 5.814vw, 5rem);
  }
  .contactPage__inner ol:not([class]) li {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    padding-left: clamp(2.1rem, 4.884vw, 4.2rem);
  }
  .contactPage__inner ol:not([class]) li::before {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    top: clamp(0.1rem, 0.233vw, 0.2rem);
  }
  .contactPage__inner ol:not([class]) li + li {
    margin-top: clamp(2rem, 4.651vw, 4rem);
  }
  .contactPage__inner .wp-block-file:not(.wp-element-button) {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
  }
  .contactPage__inner .wp-block-file__button {
    padding: 20px 15px;
  }
}
.contactPage__inner > h1:first-child {
  margin-top: 0 !important;
}
.contactPage__inner > h2:first-child {
  margin-top: 0 !important;
}
.contactPage__inner > h3:first-child {
  margin-top: 0 !important;
}
.contactPage__inner > h4:first-child {
  margin-top: 0 !important;
}
.contactPage__inner > h5:first-child {
  margin-top: 0 !important;
}
.contactPage__inner > h6:first-child {
  margin-top: 0 !important;
}

.formBox h1 {
  margin-top: 0 !important;
  margin-bottom: clamp(5rem, 3.66vw, 10rem) !important;
}
.formBox h2 {
  margin-top: 0 !important;
  margin-bottom: clamp(5rem, 3.66vw, 10rem) !important;
}
.formBox h3 {
  margin-top: 0 !important;
  margin-bottom: clamp(5rem, 3.66vw, 10rem) !important;
}
.formBox h4 {
  margin-top: 0 !important;
  margin-bottom: clamp(5rem, 3.66vw, 10rem) !important;
}
.formBox h5 {
  margin-top: 0 !important;
  margin-bottom: clamp(5rem, 3.66vw, 10rem) !important;
}
.formBox h6 {
  margin-top: 0 !important;
  margin-bottom: clamp(5rem, 3.66vw, 10rem) !important;
}
.formBox .step {
  display: flex;
  justify-content: center;
  gap: 0 clamp(10.8rem, 7.87vw, 21.5rem);
  margin: clamp(5rem, 3.66vw, 10rem) 0;
}
.formBox .step__item {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  position: relative;
  padding-left: clamp(3.3rem, 2.379vw, 6.5rem);
}
.formBox .step__item:not(:last-child)::after {
  content: "";
  width: clamp(4.5rem, 3.294vw, 9rem);
  height: clamp(0.1rem, 0.073vw, 0.2rem);
  display: inline-block;
  background: #AAAAAA;
  position: absolute;
  top: 50%;
  right: clamp(-7.5rem, -5.49vw, -15rem);
  transform: translateY(-50%);
  z-index: 0;
}
.formBox .step__item::before {
  content: "";
  width: clamp(2rem, 1.464vw, 4rem);
  height: clamp(2rem, 1.464vw, 4rem);
  display: inline-block;
  background: #EFF6F7;
  border-radius: 50px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}
.formBox .step__item.active {
  font-weight: 700;
  color: #0080D9;
}
.formBox .step__item.active:before {
  background: #0080D9;
}
.formBox .requiredNotes {
  color: #FC0000;
  text-align: right;
  margin: clamp(5rem, 3.66vw, 10rem) 0 clamp(2.5rem, 1.83vw, 5rem);
}
.formBox .btn--form {
  display: block;
  text-align: center;
  width: 100%;
  max-width: 80%;
}
.formBox .btn--form:not(.back) input {
  font-size: clamp(1.9rem, 1.391vw, 3.8rem);
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  padding: clamp(3.4rem, 2.489vw, 6.8rem) clamp(0.5rem, 0.366vw, 1rem);
  background: #0080D9;
  display: block;
  width: 100%;
  border: clamp(0.1rem, 0.073vw, 0.2rem) solid transparent;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 860px) {
  .formBox .btn--form:not(.back):hover input {
    color: #191919;
    background: #fff;
    border-color: #191919;
  }
}
.formBox .btn--form.back {
  max-width: initial;
}
.formBox .btn--form.back input {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  color: #AAAAAA;
  cursor: pointer;
}

.mw_wp_form_input .step .step__item:nth-child(1):before {
  background: #0080D9;
}

.mw_wp_form_confirm .step .step__item:nth-child(2):before {
  background: #0080D9;
}

/* =================================
  お問い合わせフォーム
================================= */
.consultForm__item {
  display: flex;
  align-items: flex-start;
  padding: clamp(2.5rem, 1.83vw, 5rem) 0;
  border-bottom: clamp(0.1rem, 0.073vw, 0.2rem) solid #AAAAAA;
}
.consultForm__item:first-child {
  border-top: clamp(0.1rem, 0.073vw, 0.2rem) solid #AAAAAA;
}
.consultForm__ttl {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  font-weight: 400;
  width: 31.5%;
  position: relative;
}
.consultForm__ttl .sub {
  font-size: clamp(1.2rem, 0.732vw, 2rem);
  display: block;
}
.consultForm__ttl span:not(.sub) {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  color: #0080D9;
  line-height: 1;
  margin-left: clamp(0.9rem, 0.659vw, 1.8rem);
}
.consultForm__ttl .required {
  color: #FC0000 !important;
}
.consultForm__list {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  width: calc(68.5% - clamp(3.6rem, 2.635vw, 7.2rem));
}
.consultForm__list p {
  line-height: 1.65;
}
.consultForm__list * {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
}
.consultForm__list input:not([type=radio]):not([type=checkbox]), .consultForm__list textarea, .consultForm__list select {
  background: #F7F7F7;
  border-radius: 10px;
  padding: clamp(2.1rem, 1.537vw, 4.2rem) clamp(2.9rem, 2.123vw, 5.8rem);
  border: none;
}
.consultForm__list input:not([type=radio]):not([type=checkbox]):not([class=widthNormal]), .consultForm__list textarea, .consultForm__list select {
  width: 100%;
  box-sizing: border-box;
}
.consultForm__list .mwform-radio-field {
  display: inline-block;
  margin-bottom: 1rem;
  margin-right: 3rem;
}
.consultForm__list .mwform-radio-field label {
  display: inline-block;
}
.consultForm__list input[type=radio] + span {
  padding-left: clamp(4rem, 2.928vw, 8rem);
  display: inline-block;
  position: relative;
}
.consultForm__list input[type=radio] + span::before {
  content: "";
  width: clamp(3rem, 2.196vw, 6rem);
  height: clamp(3rem, 2.196vw, 6rem);
  box-sizing: border-box;
  margin: auto;
  border-radius: 50%;
  background-color: #fff;
  border: clamp(0.3rem, 0.22vw, 0.6rem) solid #c5c5c5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.consultForm__list input[type=radio]:checked + span::after {
  content: "";
  width: clamp(1.8rem, 1.318vw, 3.6rem);
  height: clamp(1.8rem, 1.318vw, 3.6rem);
  border-radius: 50%;
  margin: auto 0;
  background-color: #0080D9;
  position: absolute;
  top: 0;
  bottom: 0;
  left: clamp(0.6rem, 0.476vw, 1.3rem);
}
.consultForm__list .mwform-checkbox-field {
  display: inline-block;
  margin-bottom: 1rem;
  margin-right: 3rem;
}
.consultForm__list .mwform-checkbox-field label {
  display: inline-block;
}
.consultForm__list input[type=checkbox] + span {
  padding-left: clamp(4rem, 2.928vw, 8rem);
  display: inline-block;
  position: relative;
}
.consultForm__list input[type=checkbox] + span::before {
  content: "";
  width: clamp(3rem, 2.196vw, 6rem);
  height: clamp(3rem, 2.196vw, 6rem);
  box-sizing: border-box;
  margin: auto;
  border-radius: 3px;
  background-color: #fff;
  border: clamp(0.3rem, 0.22vw, 0.6rem) solid #c5c5c5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.consultForm__list input[type=checkbox]:checked + span::after {
  content: "";
  width: clamp(2rem, 1.464vw, 4rem);
  height: clamp(2rem, 1.464vw, 4rem);
  display: inline-block;
  mask-image: url(../img/icon_check_white.svg);
  mask-repeat: no-repeat;
  mask-size: contain;
  background-color: #0080D9;
  position: absolute;
  top: clamp(1rem, 0.732vw, 2rem);
  left: clamp(0.5rem, 0.366vw, 1rem);
}
.consultForm__list textarea {
  min-height: 280px;
}
.consultForm__list .error {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  color: #FC0000;
  margin-top: clamp(0.3rem, 0.183vw, 0.5rem);
  display: block;
}
.consultForm__list .yoyaku {
  display: flex;
  align-items: center;
}
.consultForm__list .yoyaku .rank {
  font-weight: bold;
  color: #0080D9;
  width: clamp(10rem, 7.321vw, 20rem);
}
.consultForm__list .yoyaku .yoyakuBox {
  width: calc(100% - clamp(10rem, 7.321vw, 20rem));
  display: flex;
}
.consultForm__list .yoyaku .yoyakudate {
  width: calc((100% - clamp(1rem, 0.732vw, 2rem)) / 2) !important;
}
.consultForm__list .yoyaku .yoyakujikan {
  width: calc((100% - clamp(1rem, 0.732vw, 2rem)) / 2) !important;
  margin-left: clamp(1rem, 0.732vw, 2rem);
}
.consultForm__list .yoyaku + .yoyaku {
  margin-top: clamp(1rem, 0.732vw, 2rem);
}
.consultForm + .consultForm {
  margin-top: clamp(5rem, 3.66vw, 10rem);
}

.confirmingTxt {
  text-align: center;
  margin: clamp(5rem, 3.66vw, 10rem) 0 clamp(4rem, 2.928vw, 8rem);
}
.confirmingTxt p {
  font-size: clamp(2rem, 1.464vw, 4rem);
}
.confirmingTxt input {
  -webkit-appearance: checkbox;
}
.confirmingTxt a {
  text-decoration: underline;
}
.confirmingTxt a:hover {
  color: #0080D9;
}

.checkText label {
  margin-right: clamp(1rem, 0.732vw, 2rem);
}

.completedForm .completedMessage {
  font-size: clamp(2.5rem, 1.83vw, 5rem);
  line-height: 1;
  letter-spacing: 0.05em;
  display: inline-block;
  margin-top: clamp(5rem, 3.66vw, 10rem);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.completedForm .completedMessage::after {
  content: "";
  width: 100%;
  height: clamp(0.2rem, 0.146vw, 0.4rem);
  background: #0080D9;
  display: inline-block;
}
.completedForm p {
  text-align: center;
  margin-top: clamp(2.5rem, 1.83vw, 5rem);
}

@media screen and (min-width: 0) and (max-width: 860px) {
  /*=================================
    お問い合わせ
  =================================*/
  .cvWrap {
    background: url(../img/contact/contact_bnr_img_sp.png) no-repeat right bottom;
    background-size: 40% auto;
    text-align: center;
    padding: 0 clamp(2.5rem, 5.814vw, 5rem) clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .cvWrap .cvBnr {
    background: #0080D9;
    padding: clamp(3.2rem, 7.442vw, 6.4rem) clamp(1rem, 2.326vw, 2rem);
  }
  .cvWrap .cvBnr__ttl {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    letter-spacing: 0;
    margin-bottom: clamp(1.5rem, 3.488vw, 3rem);
  }
  .cvWrap .cvBnr__tel {
    font-size: clamp(3.5rem, 8.14vw, 7rem);
    padding-left: clamp(4.5rem, 10.465vw, 9rem);
    pointer-events: initial;
  }
  .cvWrap .cvBnr__tel::before {
    width: clamp(3.9rem, 9.07vw, 7.8rem);
    height: clamp(2.4rem, 5.581vw, 4.8rem);
    left: 0;
  }
  .cvWrap .cvBnr .businessHours {
    justify-content: center;
    margin-top: clamp(1.5rem, 3.488vw, 3rem);
  }
  .cvWrap .cvBnr .businessHours__ttl {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    padding-right: clamp(3rem, 6.977vw, 6rem);
  }
  .cvWrap .cvBnr .businessHours__ttl::after {
    width: clamp(0.1rem, 0.233vw, 0.2rem);
    height: clamp(1.2rem, 2.791vw, 2.4rem);
    right: clamp(1.5rem, 3.488vw, 3rem);
  }
  .cvWrap .cvBnr .businessHours__text {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
  }
  .cvWrap .cvTtl {
    font-size: clamp(2.8rem, 6.395vw, 5.5rem);
    line-height: 1.454545;
    text-align: left;
    margin: clamp(5rem, 11.628vw, 10rem) 0 0;
  }
}
@media screen and (max-width: 1080px) {
  /*=================================
    お問い合わせ
  =================================*/
  .contactPage {
    margin-top: clamp(2.5rem, 5.814vw, 5rem);
  }
  .contactPage__inner {
    padding: 0 clamp(2.5rem, 5.814vw, 5rem);
  }
  .contactPage table {
    display: table;
  }
  .formBox {
    background: #fff;
    padding: clamp(2.5rem, 5.814vw, 5rem);
    border: clamp(2.5rem, 5.814vw, 5rem) solid #EFF6F7;
    width: calc(100% + clamp(5rem, 11.628vw, 10rem));
    margin-left: clamp(-2.5rem, -5.814vw, -5rem);
  }
  .formBox h1 {
    margin-top: 0 !important;
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .formBox h2 {
    margin-top: 0 !important;
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .formBox h3 {
    margin-top: 0 !important;
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .formBox h4 {
    margin-top: 0 !important;
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .formBox h5 {
    margin-top: 0 !important;
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .formBox h6 {
    margin-top: 0 !important;
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .formBox .step {
    gap: 0 clamp(3rem, 6.977vw, 6rem);
    margin: clamp(2.5rem, 5.814vw, 5rem) 0;
  }
  .formBox .step__item {
    font-size: clamp(1.3rem, 2.791vw, 2.4rem);
    padding-left: clamp(1.9rem, 4.419vw, 3.8rem);
  }
  .formBox .step__item:not(:last-child)::after {
    width: clamp(2rem, 4.651vw, 4rem);
    height: clamp(0.1rem, 0.233vw, 0.2rem);
    right: clamp(-2.2rem, -5.116vw, -4.4rem);
  }
  .formBox .step__item::before {
    width: clamp(1.5rem, 3.488vw, 3rem);
    height: clamp(1.5rem, 3.488vw, 3rem);
  }
  .formBox .requiredNotes {
    margin: clamp(2.5rem, 5.814vw, 5rem) 0;
  }
  .formBox .btn--form {
    max-width: 100%;
  }
  .formBox .btn--form:not(.back) input {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    padding: clamp(2.2rem, 5.116vw, 4.4rem) clamp(0.5rem, 1.163vw, 1rem);
    border: clamp(0.1rem, 0.233vw, 0.2rem) solid transparent;
  }
  .formBox .btn--form.back input {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
  }
  /* =================================
    お問い合わせフォーム
  ================================= */
  .consultForm__item {
    flex-wrap: wrap;
    gap: clamp(1.5rem, 3.488vw, 3rem) 0;
    padding: clamp(2.5rem, 5.814vw, 5rem) 0;
    border-bottom: clamp(0.1rem, 0.233vw, 0.2rem) solid #AAAAAA;
  }
  .consultForm__item:first-child {
    border-top: clamp(0.1rem, 0.233vw, 0.2rem) solid #AAAAAA;
  }
  .consultForm__ttl {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    width: 100%;
  }
  .consultForm__ttl .sub {
    font-size: clamp(1.2rem, 2.326vw, 2rem);
  }
  .consultForm__ttl span:not(.sub) {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    margin-left: clamp(1.2rem, 2.791vw, 2.4rem);
  }
  .consultForm__list {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    width: 100%;
  }
  .consultForm__list * {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
  }
  .consultForm__list input:not([type=radio]):not([type=checkbox]), .consultForm__list textarea, .consultForm__list select {
    border-radius: 5px;
    padding: clamp(1.9rem, 4.419vw, 3.8rem) clamp(1.5rem, 3.488vw, 3rem);
  }
  .consultForm__list .mwform-radio-field {
    margin-right: 1.5rem;
  }
  .consultForm__list input[type=radio] + span {
    padding-left: clamp(4rem, 9.302vw, 8rem);
  }
  .consultForm__list input[type=radio] + span::before {
    width: clamp(3rem, 6.977vw, 6rem);
    height: clamp(3rem, 6.977vw, 6rem);
    border: clamp(0.3rem, 0.698vw, 0.6rem) solid #c5c5c5;
  }
  .consultForm__list input[type=radio]:checked + span::after {
    width: clamp(1.8rem, 4.186vw, 3.6rem);
    height: clamp(1.8rem, 4.186vw, 3.6rem);
    left: clamp(0.6rem, 1.395vw, 1.2rem);
  }
  .consultForm__list .mwform-checkbox-field {
    margin-right: 1.5rem;
  }
  .consultForm__list input[type=checkbox] + span {
    padding-left: clamp(4rem, 9.302vw, 8rem);
  }
  .consultForm__list input[type=checkbox] + span::before {
    width: clamp(3rem, 6.977vw, 6rem);
    height: clamp(3rem, 6.977vw, 6rem);
    border: clamp(0.3rem, 0.698vw, 0.6rem) solid #c5c5c5;
  }
  .consultForm__list input[type=checkbox]:checked + span::after {
    content: "";
    width: clamp(2rem, 1.464vw, 4rem);
    height: clamp(2rem, 1.464vw, 4rem);
    display: inline-block;
    mask-image: url(../img/icon_check_white.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: #0080D9;
    position: absolute;
    top: clamp(0.8rem, 0.586vw, 1.6rem);
    left: clamp(0.5rem, 0.366vw, 1rem);
  }
  .consultForm__list textarea {
    min-height: 240px;
  }
  .consultForm__list .error {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    margin-top: clamp(0.3rem, 0.581vw, 0.5rem);
  }
  .consultForm__list .yoyaku .rank {
    width: clamp(7.5rem, 17.442vw, 15rem);
  }
  .consultForm__list .yoyaku .yoyakuBox {
    width: calc(100% - clamp(7.5rem, 17.442vw, 15rem));
    display: flex;
  }
  .consultForm__list .yoyaku .yoyakudate {
    width: calc((100% - clamp(1rem, 2.326vw, 2rem)) / 2) !important;
  }
  .consultForm__list .yoyaku .yoyakujikan {
    width: calc((100% - clamp(1rem, 2.326vw, 2rem)) / 2) !important;
    margin-left: clamp(1rem, 2.326vw, 2rem);
  }
  .consultForm__list .yoyaku + .yoyaku {
    margin-top: clamp(1rem, 2.326vw, 2rem);
  }
  .consultForm + .consultForm {
    margin-top: clamp(5rem, 11.628vw, 10rem);
  }
  .confirmingTxt {
    margin: clamp(3.5rem, 8.14vw, 7rem) 0;
  }
  .confirmingTxt p {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
  }
  .checkText label {
    margin-right: clamp(1rem, 2.326vw, 2rem);
  }
  .completedForm .completedMessage {
    font-size: clamp(2rem, 4.651vw, 4rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
  }
  .completedForm .completedMessage::after {
    height: clamp(0.2rem, 0.465vw, 0.4rem);
  }
  .completedForm p {
    margin-top: clamp(2.5rem, 5.814vw, 5rem);
  }
}
/*=================================
  member
=================================*/
.memberSec__position {
  font-size: clamp(3.5rem, 2.562vw, 7rem);
  font-weight: 500;
  line-height: 1.38571;
  color: #0080D9;
  margin-bottom: clamp(3.5rem, 2.562vw, 7rem);
}
.memberSec .memberList {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(5rem, 3.66vw, 10rem) clamp(2rem, 2.745vw, 7.5rem);
}
.memberSec .memberList__item {
  width: calc((100% - clamp(2rem, 2.745vw, 7.5rem) * 2) / 3);
}
.memberSec .memberList__thumb {
  width: 100%;
}
.memberSec .memberList__name {
  font-size: clamp(2rem, 2.196vw, 6rem);
  line-height: 1;
  color: #191919;
  margin: clamp(2rem, 1.464vw, 4rem) 0;
}
.memberSec .memberList__ruby {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.2rem, 0.915vw, 2.5rem);
  line-height: 2;
  letter-spacing: 0.05em;
  color: #0080D9;
  margin-bottom: clamp(0.7rem, 0.512vw, 1.4rem);
  display: block;
}
.memberSec .memberList__link {
  font-size: clamp(1.2rem, 1.098vw, 3rem);
  line-height: 1;
  color: #191919;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 72.7%;
  min-width: 100px;
  border: clamp(0.1rem, 0.073vw, 0.2rem) solid #191919;
  background: #fff;
  padding: clamp(1.9rem, 1.391vw, 3.8rem) clamp(2rem, 1.464vw, 4rem);
  margin-top: clamp(2rem, 1.464vw, 4rem);
  position: relative;
}
.memberSec .memberList__link::before {
  content: "";
  display: inline-block;
  width: clamp(1.5rem, 1.098vw, 3rem);
  height: clamp(1.1rem, 0.805vw, 2.2rem);
  mask-image: url(../img/icon_arrow.svg);
  background-color: #0080D9;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  position: absolute;
  top: 50%;
  right: clamp(0.5rem, 1.391vw, 3.8rem);
  transform: translateY(-50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 860px) {
  .memberSec .memberList__link:hover {
    color: #fff;
    background: #0080D9;
  }
  .memberSec .memberList__link:hover::before {
    background-color: #fff;
    transform: translate(30%, -50%);
  }
}
.memberSec + .memberSec {
  margin-top: clamp(7rem, 5.124vw, 14rem);
}

.memberKv {
  position: relative;
}
.memberKv__info {
  position: absolute;
  top: clamp(10rem, 7.321vw, 20rem);
  left: clamp(8.7rem, 6.369vw, 17.4rem);
}
.memberKv__name {
  font-size: clamp(5.4rem, 3.953vw, 10.8rem);
  font-weight: 500;
}
.memberKv__name .office {
  font-size: clamp(3.5rem, 2.562vw, 7rem);
  display: block;
  margin-bottom: clamp(1rem, 0.732vw, 2rem);
}
.memberKv__name .ruby {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.8rem, 1.318vw, 3.6rem);
  letter-spacing: 0.05em;
  display: block;
  margin-top: clamp(3rem, 2.196vw, 6rem);
}
.memberKv__name .slash {
  width: clamp(2rem, 1.464vw, 4rem);
  height: clamp(2rem, 1.464vw, 4rem);
  display: inline-block;
  margin: 0 clamp(1rem, 0.732vw, 2rem);
}
.memberKv__whiteBox {
  font-size: clamp(2.5rem, 1.83vw, 5rem);
  font-weight: 500;
  line-height: 1.8;
  color: #191919;
  background: rgba(255, 255, 255, 0.5);
  padding: clamp(2rem, 1.464vw, 4rem) clamp(3rem, 2.196vw, 6rem);
  margin-top: clamp(3.8rem, 2.782vw, 7.6rem);
  width: fit-content;
}
.memberKv__img {
  display: block;
}
.memberKv__img img {
  width: 100%;
  height: 100%;
  min-height: 460px;
  object-fit: cover;
}

.memberDetail {
  /*---------------------
    メインビジュアル
  ---------------------*/
}
.memberDetail .articleMv {
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}
.memberDetail .articleMv img {
  margin: clamp(2.5rem, 1.83vw, 5rem) auto !important;
}
.memberDetail .date {
  display: flex;
  justify-content: flex-end;
}
.memberDetail .date * {
  font-size: clamp(1.2rem, 0.732vw, 2rem) !important;
  line-height: 1;
  color: #AAAAAA;
}
.memberDetail .date * + * {
  margin: 0 0 0 1rem !important;
}
@media screen and (min-width: 0) and (max-width: 860px) {
  .memberDetail {
    /*---------------------
      メインビジュアル
    ---------------------*/
  }
  .memberDetail .date {
    justify-content: flex-start;
  }
  .memberDetail .date * {
    font-size: 1.1rem !important;
  }
}
.memberDetail__inner {
  padding: clamp(7rem, 5.124vw, 14rem) clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem);
  background: #fff;
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .memberDetail__inner {
    padding: 100px 100px 80px;
  }
}
.memberDetail__inner h1, .memberDetail__inner h2, .memberDetail__inner h3, .memberDetail__inner h4, .memberDetail__inner h5, .memberDetail__inner h6 {
  clear: both;
}
.memberDetail__inner a:not([class]) {
  font-weight: 700;
}
.memberDetail__inner p:not([class]) {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  line-height: 2;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
.memberDetail__inner h1:not([class]), .memberDetail__inner h1.wp-block-heading {
  font-size: clamp(3.5rem, 2.562vw, 7rem);
  font-weight: 400;
  color: #fff;
  line-height: 1.75;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
  padding: clamp(2rem, 1.464vw, 4rem) clamp(5rem, 3.66vw, 10rem);
  background: #0080D9;
  width: calc(100% + clamp(10rem, 7.321vw, 20rem));
  margin-left: clamp(-5rem, -3.66vw, -10rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .memberDetail__inner h1:not([class]), .memberDetail__inner h1.wp-block-heading {
    width: calc(100% + 200px);
    padding: clamp(2rem, 1.464vw, 4rem) 100px;
    margin-left: -100px;
  }
}
.memberDetail__inner h2:not([class]), .memberDetail__inner h2.wp-block-heading {
  font-size: clamp(3.5rem, 2.562vw, 7rem);
  font-weight: 400;
  color: #fff;
  line-height: 1.75;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
  padding: clamp(2rem, 1.464vw, 4rem) clamp(5rem, 3.66vw, 10rem);
  background: #0080D9;
  width: calc(100% + clamp(10rem, 7.321vw, 20rem));
  margin-left: clamp(-5rem, -3.66vw, -10rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .memberDetail__inner h2:not([class]), .memberDetail__inner h2.wp-block-heading {
    width: calc(100% + 200px);
    padding: clamp(2rem, 1.464vw, 4rem) 100px;
    margin-left: -100px;
  }
}
.memberDetail__inner h3:not([class]), .memberDetail__inner h3.wp-block-heading {
  font-size: clamp(3rem, 2.196vw, 6rem);
  font-weight: 500;
  line-height: 1.616666;
  color: #0080D9;
  border-bottom: 2px solid #AAAAAA;
  padding-bottom: clamp(2rem, 1.464vw, 4rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
.memberDetail__inner h4:not([class]), .memberDetail__inner h4.wp-block-heading {
  font-size: clamp(2.5rem, 1.83vw, 5rem);
  font-weight: 500;
  border-left: clamp(1rem, 0.732vw, 2rem) solid #0080D9;
  padding-left: clamp(1rem, 0.732vw, 2rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
}
.memberDetail__inner h5:not([class]), .memberDetail__inner h5.wp-block-heading {
  font-size: clamp(2rem, 1.464vw, 4rem);
  font-weight: 500;
  color: #0080D9;
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
}
.memberDetail__inner h6:not([class]), .memberDetail__inner h6.wp-block-heading {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  font-weight: 500;
  line-height: 1.77777;
  color: #0080D9;
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
  padding-left: clamp(2rem, 1.464vw, 4rem);
  position: relative;
}
.memberDetail__inner h6:not([class])::before, .memberDetail__inner h6.wp-block-heading::before {
  content: "";
  width: clamp(1rem, 0.732vw, 2rem);
  height: clamp(1rem, 0.732vw, 2rem);
  display: inline-block;
  background: #0080D9;
  transform: rotate(45deg);
  position: absolute;
  top: clamp(1.2rem, 0.878vw, 2.4rem);
  left: 0;
}
.memberDetail__inner blockquote {
  position: relative;
  padding: clamp(5rem, 3.66vw, 10rem) clamp(2.5rem, 1.83vw, 5rem);
  margin-top: clamp(5rem, 3.66vw, 10rem);
  margin-bottom: clamp(2.5rem, 1.83vw, 5rem);
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 2px solid #F6F6F6;
}
.memberDetail__inner blockquote::before {
  content: "“";
  font-size: clamp(5rem, 3.66vw, 10rem);
  font-weight: 700;
  color: #0080D9;
  display: inline-block;
  font-style: normal;
  width: clamp(5rem, 3.66vw, 10rem);
  height: clamp(5rem, 3.66vw, 10rem);
  line-height: 1.3;
  text-align: center;
  background: #F6F6F6;
  border-radius: 50px;
  position: absolute;
  top: -25px;
  left: 15px;
}
.memberDetail__inner blockquote h1 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.memberDetail__inner blockquote h2 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.memberDetail__inner blockquote h3 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.memberDetail__inner blockquote h4 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.memberDetail__inner blockquote h5 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.memberDetail__inner blockquote h6 {
  font-size: clamp(3.5rem, 2.562vw, 7rem) !important;
  width: 100% !important;
  margin: 0 0 clamp(2.5rem, 1.83vw, 5rem) !important;
  position: relative;
}
.memberDetail__inner blockquote p {
  font-style: normal;
}
.memberDetail__inner blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}
.memberDetail__inner .btnWrap {
  clear: both;
}
.memberDetail__inner img:not([class]), .memberDetail__inner.wp-block-heading {
  display: block;
  margin: clamp(4rem, 2.928vw, 8rem) auto clamp(5rem, 3.66vw, 10rem);
}
.memberDetail__inner img.alignleft, .memberDetail__inner img.imgleft {
  float: left;
  margin: 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem) 0;
  max-width: 38%;
}
.memberDetail__inner img.alignright, .memberDetail__inner img.imgright {
  float: right;
  margin: 0 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem);
  max-width: 38%;
}
.memberDetail__inner figure.alignleft, .memberDetail__inner figure.imgleft {
  float: left;
  margin: 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem) 0;
  max-width: 38%;
}
.memberDetail__inner figure.alignright, .memberDetail__inner figure.imgright {
  float: right;
  margin: 0 0 clamp(5rem, 3.66vw, 10rem) clamp(5rem, 3.66vw, 10rem);
  max-width: 38%;
}
.memberDetail__inner ul:not([class]) {
  margin: clamp(2.5rem, 1.83vw, 5rem) 0;
  padding: clamp(3.5rem, 2.562vw, 7rem);
  border: 2px solid #191919;
  clear: both;
  background: #fff;
}
.memberDetail__inner ul:not([class]) li {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  padding-left: clamp(2.9rem, 2.123vw, 5.8rem);
  position: relative;
}
.memberDetail__inner ul:not([class]) li::before {
  content: "";
  width: clamp(1.5rem, 1.098vw, 3rem);
  height: clamp(1.5rem, 1.098vw, 3rem);
  border-radius: 50px;
  background: #0080D9;
  display: inline-block;
  position: absolute;
  top: clamp(0.5rem, 0.805vw, 2.2rem);
  left: 0;
}
.memberDetail__inner ul:not([class]) li + li {
  margin-top: clamp(2rem, 1.464vw, 4rem);
}
.memberDetail__inner ul:not([class]) h1::before {
  content: none;
}
.memberDetail__inner ul:not([class]) h2::before {
  content: none;
}
.memberDetail__inner ul:not([class]) h3::before {
  content: none;
}
.memberDetail__inner ul:not([class]) h4::before {
  content: none;
}
.memberDetail__inner ul:not([class]) h5::before {
  content: none;
}
.memberDetail__inner ul:not([class]) h6::before {
  content: none;
}
.memberDetail__inner ol:not([class]) {
  margin: clamp(2.5rem, 1.83vw, 5rem) 0;
  padding: clamp(3.5rem, 2.562vw, 7rem);
  border: 2px solid #191919;
  counter-reset: listNum;
  clear: both;
  background: #fff;
}
.memberDetail__inner ol:not([class]) li {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  padding-left: clamp(2.1rem, 1.537vw, 4.2rem);
  position: relative;
}
.memberDetail__inner ol:not([class]) li::before {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  counter-increment: listNum;
  content: counter(listNum);
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  color: #0080D9;
  line-height: 2;
  display: inline-block;
  position: absolute;
  top: clamp(0.1rem, 0.073vw, 0.2rem);
  left: 0;
}
.memberDetail__inner ol:not([class]) li + li {
  margin-top: clamp(2rem, 1.464vw, 4rem);
}
.memberDetail__inner ol:not([class]) h1::before {
  content: none;
}
.memberDetail__inner ol:not([class]) h2::before {
  content: none;
}
.memberDetail__inner ol:not([class]) h3::before {
  content: none;
}
.memberDetail__inner ol:not([class]) h4::before {
  content: none;
}
.memberDetail__inner ol:not([class]) h5::before {
  content: none;
}
.memberDetail__inner ol:not([class]) h6::before {
  content: none;
}
.memberDetail__inner iframe {
  max-width: 100%;
  border: none;
}
.memberDetail__inner .wp-block-file:not(.wp-element-button) {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
}
.memberDetail__inner .wp-block-file__button {
  padding: 11px 30px;
  display: inline-block;
}
@media screen and (min-width: 0) and (max-width: 860px) {
  .memberDetail__inner p:not([class]) {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    margin-bottom: clamp(2rem, 4.651vw, 4rem);
  }
  .memberDetail__inner h1:not([class]), .memberDetail__inner h1.wp-block-heading {
    font-size: clamp(2.2rem, 5.233vw, 4.5rem);
    line-height: 1.77777;
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
    padding: clamp(1.8rem, 4.07vw, 3.5rem) clamp(2.3rem, 5.465vw, 4.7rem);
    width: calc(100% + clamp(5rem, 11.628vw, 10rem));
    margin-left: clamp(-2.5rem, -5.814vw, -5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .memberDetail__inner h2:not([class]), .memberDetail__inner h2.wp-block-heading {
    font-size: clamp(2.2rem, 5.233vw, 4.5rem);
    line-height: 1.77777;
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
    padding: clamp(1.8rem, 4.07vw, 3.5rem) clamp(2.3rem, 5.465vw, 4.7rem);
    width: calc(100% + clamp(5rem, 11.628vw, 10rem));
    margin-left: clamp(-2.5rem, -5.814vw, -5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .memberDetail__inner h3:not([class]), .memberDetail__inner h3.wp-block-heading {
    font-size: clamp(2rem, 4.651vw, 4rem);
    line-height: 1.5;
    padding-bottom: clamp(2rem, 4.651vw, 4rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .memberDetail__inner h4:not([class]), .memberDetail__inner h4.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    border-left: clamp(0.7rem, 1.744vw, 1.5rem) solid #0080D9;
    padding-left: clamp(0.7rem, 1.744vw, 1.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .memberDetail__inner h5:not([class]), .memberDetail__inner h5.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .memberDetail__inner h6:not([class]), .memberDetail__inner h6.wp-block-heading {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
    padding-left: clamp(2rem, 4.651vw, 4rem);
  }
  .memberDetail__inner h6:not([class])::before, .memberDetail__inner h6.wp-block-heading::before {
    width: clamp(1rem, 2.326vw, 2rem);
    height: clamp(1rem, 2.326vw, 2rem);
    top: clamp(1.2rem, 2.791vw, 2.4rem);
  }
  .memberDetail__inner blockquote {
    position: relative;
    padding: clamp(5rem, 11.628vw, 10rem) clamp(2.5rem, 1.83vw, 5rem), 860;
    margin-top: clamp(5rem, 11.628vw, 10rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .memberDetail__inner blockquote::before {
    font-size: clamp(5rem, 11.628vw, 10rem);
    width: clamp(5rem, 11.628vw, 10rem);
    height: clamp(5rem, 11.628vw, 10rem);
  }
  .memberDetail__inner blockquote h1 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .memberDetail__inner blockquote h2 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .memberDetail__inner blockquote h3 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .memberDetail__inner blockquote h4 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .memberDetail__inner blockquote h5 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .memberDetail__inner blockquote h6 {
    font-size: clamp(2rem, 4.651vw, 4rem) !important;
    margin: 0 0 clamp(2.5rem, 5.814vw, 5rem) !important;
  }
  .memberDetail__inner img:not([class]), .memberDetail__inner.wp-block-heading {
    margin: clamp(5rem, 11.628vw, 10rem) auto;
  }
  .memberDetail__inner img.alignleft, .memberDetail__inner img.imgleft {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .memberDetail__inner img.alignright, .memberDetail__inner img.imgright {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .memberDetail__inner figure.alignleft, .memberDetail__inner figure.imgleft {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .memberDetail__inner figure.alignright, .memberDetail__inner figure.imgright {
    float: none;
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
  .memberDetail__inner ul:not([class]) {
    margin: clamp(2.5rem, 5.814vw, 5rem) 0;
    padding: clamp(2.5rem, 5.814vw, 5rem);
  }
  .memberDetail__inner ul:not([class]) li {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    padding-left: clamp(2.9rem, 6.744vw, 5.8rem);
  }
  .memberDetail__inner ul:not([class]) li::before {
    width: clamp(1.5rem, 3.488vw, 3rem);
    height: clamp(1.5rem, 3.488vw, 3rem);
    top: clamp(0.5rem, 2.558vw, 2.2rem);
  }
  .memberDetail__inner ul:not([class]) li + li {
    margin-top: clamp(2rem, 4.651vw, 4rem);
  }
  .memberDetail__inner ol:not([class]) {
    margin: clamp(2.5rem, 5.814vw, 5rem) 0;
    padding: clamp(2.5rem, 5.814vw, 5rem);
  }
  .memberDetail__inner ol:not([class]) li {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    padding-left: clamp(2.1rem, 4.884vw, 4.2rem);
  }
  .memberDetail__inner ol:not([class]) li::before {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
    top: clamp(0.1rem, 0.233vw, 0.2rem);
  }
  .memberDetail__inner ol:not([class]) li + li {
    margin-top: clamp(2rem, 4.651vw, 4rem);
  }
  .memberDetail__inner .wp-block-file:not(.wp-element-button) {
    font-size: clamp(1.8rem, 4.07vw, 3.5rem);
  }
  .memberDetail__inner .wp-block-file__button {
    padding: 20px 15px;
  }
}
.memberDetail__inner > h1:first-child {
  margin-top: 0 !important;
}
.memberDetail__inner > h2:first-child {
  margin-top: 0 !important;
}
.memberDetail__inner > h3:first-child {
  margin-top: 0 !important;
}
.memberDetail__inner > h4:first-child {
  margin-top: 0 !important;
}
.memberDetail__inner > h5:first-child {
  margin-top: 0 !important;
}
.memberDetail__inner > h6:first-child {
  margin-top: 0 !important;
}
.memberDetail__inner h3:not([class]), .memberDetail__inner h3.wp-block-heading {
  font-size: clamp(3.5rem, 2.562vw, 7rem);
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: clamp(3.5rem, 2.562vw, 7rem);
}
.memberDetail__inner .wp-block-table tr td:nth-child(1) {
  background: #EFF6F7;
  width: 30%;
}
.memberDetail .sidebar {
  margin-top: clamp(7rem, 5.124vw, 14rem);
}
@media screen and (min-width: 1390px) and (max-width: 2732px) {
  .memberDetail .sidebar {
    margin-top: 100px;
  }
}
.memberDetail .memberSlider {
  margin-top: clamp(5.5rem, 4.026vw, 11rem);
}
.memberDetail .solution {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(2.2rem, 1.611vw, 4.4rem);
}
.memberDetail .solution__item {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  font-weight: 400;
  color: #191919;
  text-decoration: none;
  width: calc((100% - clamp(4.4rem, 3.221vw, 8.8rem)) / 3);
  border: clamp(0.1rem, 0.073vw, 0.2rem) solid #191919;
  padding: clamp(3.2rem, 2.343vw, 6.4rem) clamp(4.8rem, 3.477vw, 9.5rem) clamp(3.2rem, 2.343vw, 6.4rem) clamp(2.8rem, 2.05vw, 5.6rem);
  background: #fff;
  position: relative;
}
.memberDetail .solution__item::after {
  content: "";
  display: inline-block;
  width: clamp(1.5rem, 1.098vw, 3rem);
  height: clamp(1.1rem, 0.805vw, 2.2rem);
  mask-image: url(../img/icon_arrow.svg);
  background-color: #0080D9;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  position: absolute;
  top: 50%;
  right: clamp(0.5rem, 1.281vw, 3.5rem);
  transform: translateY(-50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 860px) {
  .memberDetail .solution__item:hover {
    color: #0080D9;
    background: #EFF6F7;
    border-color: #0080D9;
    text-decoration: underline;
  }
  .memberDetail .solution__item:hover::after {
    transform: translate(30%, -50%);
  }
}

.otherSite {
  display: flex;
  gap: clamp(5rem, 3.66vw, 10rem);
  margin-top: clamp(10.5rem, 7.687vw, 21rem);
}
.otherSite img {
  margin: 0 !important;
}
@media screen and (min-width: 860px) {
  .otherSite a:hover {
    opacity: 0.8;
  }
}

@media screen and (min-width: 0) and (max-width: 860px) {
  /*=================================
    member
  =================================*/
  .memberSec {
    padding: 0 clamp(2.5rem, 5.814vw, 5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
  }
  .memberSec__position {
    font-size: clamp(2.5rem, 5.814vw, 5rem);
    line-height: 1;
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .memberSec .memberList {
    gap: clamp(5rem, 11.628vw, 10rem) 0;
    padding: 0 clamp(2.5rem, 5.814vw, 5rem);
  }
  .memberSec .memberList__item {
    width: 100%;
  }
  .memberSec .memberList__name {
    font-size: clamp(3rem, 6.977vw, 6rem);
    margin: clamp(2.5rem, 5.814vw, 5rem) 0;
  }
  .memberSec .memberList__ruby {
    font-size: clamp(1.2rem, 2.326vw, 2rem);
    line-height: 2.5;
    margin-bottom: clamp(0.5rem, 1.163vw, 1rem);
  }
  .memberSec .memberList__link {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    border: clamp(0.1rem, 0.233vw, 0.2rem) solid #191919;
    padding: clamp(2rem, 4.651vw, 4rem) clamp(1rem, 2.326vw, 2rem);
    margin-top: clamp(2.5rem, 5.814vw, 5rem);
  }
  .memberSec .memberList__link::before {
    width: clamp(1.5rem, 3.488vw, 3rem);
    height: clamp(1.1rem, 2.558vw, 2.2rem);
    right: clamp(2.1rem, 4.884vw, 4.2rem);
  }
  .memberSec + .memberSec {
    margin-top: clamp(5rem, 11.628vw, 10rem);
  }
  .memberKv__info {
    top: clamp(4rem, 9.302vw, 8rem);
    left: clamp(2.7rem, 6.279vw, 5.4rem);
  }
  .memberKv__name {
    font-size: clamp(3rem, 6.977vw, 6rem);
    max-width: clamp(18rem, 41.86vw, 36rem);
  }
  .memberKv__name .office {
    font-size: clamp(2rem, 4.651vw, 4rem);
    margin-bottom: clamp(0.5rem, 1.163vw, 1rem);
  }
  .memberKv__name .ruby {
    font-size: clamp(1.2rem, 2.326vw, 2rem);
    margin-top: clamp(0.5rem, 1.163vw, 1rem);
  }
  .memberKv__name .slash {
    width: clamp(1.2rem, 2.791vw, 2.4rem);
    height: clamp(1.2rem, 2.791vw, 2.4rem);
    display: inline-block;
    margin: 0 clamp(0.6rem, 0.439vw, 1.2rem);
  }
  .memberKv__whiteBox {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    line-height: 2;
    padding: clamp(1.4rem, 1.025vw, 2.8rem) clamp(1.8rem, 1.318vw, 3.6rem);
    margin-top: clamp(2.5rem, 5.814vw, 5rem);
  }
  .memberKv__img img {
    min-height: 260px;
  }
  .memberDetail__inner {
    padding: clamp(5rem, 11.628vw, 10rem) clamp(2.5rem, 5.814vw, 5rem);
    background: transparent;
  }
  .memberDetail__inner h3:not([class]), .memberDetail__inner h3.wp-block-heading {
    font-size: clamp(2.5rem, 5.814vw, 5rem);
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .memberDetail__inner .wp-block-table tr td:nth-child(1) {
    width: 35%;
  }
  .memberDetail .sidebar {
    margin-top: 0;
  }
  .memberDetail .memberSlider {
    width: calc(100% - clamp(2.5rem, 5.814vw, 5rem));
    margin-top: clamp(5.5rem, 12.791vw, 11rem);
    margin-left: clamp(2.5rem, 5.814vw, 5rem);
  }
  .memberDetail .solution {
    gap: clamp(2.5rem, 5.814vw, 5rem) 0;
  }
  .memberDetail .solution__item {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    width: 100%;
    border: clamp(0.1rem, 0.233vw, 0.2rem) solid #191919;
    padding: clamp(2.9rem, 6.744vw, 5.8rem) clamp(4.1rem, 9.535vw, 8.2rem) clamp(2.9rem, 6.744vw, 5.8rem) clamp(2.5rem, 5.814vw, 5rem);
  }
  .memberDetail .solution__item::after {
    width: clamp(1.5rem, 3.488vw, 3rem);
    height: clamp(1.1rem, 2.558vw, 2.2rem);
    right: clamp(0.5rem, 4.07vw, 3.5rem);
  }
  .otherSite {
    gap: clamp(2.5rem, 5.814vw, 5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
  }
}
.remove-scrolling {
  height: 100%;
  overflow: hidden;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
  z-index: 999;
}
.loading svg {
  width: 100%;
  max-width: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

line {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 3s ease-in both;
}

@keyframes svg {
  0% {
    fill: transparent;
    stroke-dashoffset: 600px;
  }
  100% {
    fill: #0080D9;
    stroke-dashoffset: 0;
  }
}
@keyframes svg2 {
  0% {
    fill: transparent;
    stroke-dashoffset: 600px;
  }
  100% {
    fill: #fff;
    stroke-dashoffset: 0;
  }
}
@keyframes rect {
  0% {
    fill: transparent;
    stroke-dashoffset: 600px;
  }
  100% {
    fill: #198fd9;
    stroke-dashoffset: 0;
  }
}
.fo1 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 1s;
}

.fo2 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 1.3s;
}

.fo3 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 1.6s;
}

.fo4 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 1.9s;
}

.fo5 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 2.2s;
}

.fo6 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 2.5s;
}

.fo7 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 2.8s;
}

.fo8 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 3.1s;
}

.fo9 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 3.4s;
}

.fo10 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 3.7s;
}

.fo11 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 4s;
}

.fo12 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 4.3s;
}

.fo13 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 4.6s;
}

.fo14 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 4.9s;
}

.fo15 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 5.2s;
}

.fo16 {
  stroke: #0080D9;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 5.5s;
}

.loadingHide {
  opacity: 0;
  pointer-events: none;
}

body.appear .loadingHide {
  animation-name: PageAnime-content;
  animation-duration: 0.55s;
  animation-delay: 0.55s;
  animation-fill-mode: forwards;
  opacity: 0;
  pointer-events: auto;
}

.animation .loadingHide {
  opacity: 1;
  pointer-events: auto;
}

@keyframes PageAnime-content {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animation-bg {
  display: block;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

body.appear .animation-bg {
  background: #f8f8f8;
  animation-name: PageAnime-top-ltr;
  animation-duration: 1s;
}

@keyframes PageAnime-top-ltr {
  0% {
    transform-origin: center left;
    transform: scale(2, 2) translateX(-300%) skewX(-45deg);
  }
  50% {
    transform-origin: center left;
    transform: scale(2, 2) translateX(-17%) skewX(-45deg);
  }
  50.001% {
    transform-origin: center left;
    transform: scale(2, 2) translateX(-17%) skewX(-45deg);
  }
  100% {
    transform-origin: center left;
    transform: scale(2, 2) translateX(500%) skewX(-45deg);
  }
}
@media screen and (min-width: 0) and (max-width: 860px) {
  .loading svg {
    width: 100%;
    padding: 0 3.7209302326%;
  }
  body.appear .animation-bg {
    animation-duration: 0.5s;
  }
}
/* =================================
  header
================================= */
.home .header {
  padding: 0;
}
.home .header .burger {
  right: inherit;
  left: 0;
}

.header {
  width: 100%;
  padding: clamp(1.7rem, 1.245vw, 3.4rem) clamp(10rem, 9.883vw, 27rem) clamp(1.7rem, 1.245vw, 3.4rem) clamp(2rem, 6.223vw, 17rem);
  position: relative;
  z-index: 9999;
  /*バーガーボタン設定*/
}
.header__inner {
  display: flex;
  align-items: center;
}
.header__logo .logo {
  width: clamp(21.5rem, 15.739vw, 43rem);
  display: inline-block;
  vertical-align: middle;
}
.header__logo .logo a {
  display: block;
}
@media screen and (min-width: 860px) {
  .header__logo .logo a:hover {
    opacity: 0.8;
  }
}
.header .gNav {
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.header .gNavList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 10px;
}
.header .gNavList__item {
  display: inline-block;
  position: relative;
}
.header .gNavList__item:not(:first-child) {
  margin-left: clamp(2rem, 4.026vw, 11rem);
}
.header .gNavList__item a {
  font-size: clamp(1.6rem, 1.281vw, 3.5rem);
  font-weight: 400;
  color: #191919;
  text-decoration: none;
  display: block;
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 860px) {
  .header .gNavList__item a:hover {
    color: #0080D9;
  }
}
.header .burgerNav {
  width: 100%;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transform: scale(0.9);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, transform 0.3s ease-out, visibility 0.2s ease-out;
}
.header .burgerNav__logo {
  width: clamp(21.5rem, 15.739vw, 43rem);
  position: absolute;
  top: clamp(1.7rem, 1.245vw, 3.4rem);
  left: 50%;
  transform: translateX(-50%);
}
.header .burgerNavList {
  padding: clamp(23.8rem, 17.423vw, 47.6rem) clamp(2rem, 6.223vw, 17rem) 70px;
  display: flex;
  flex-wrap: wrap;
  gap: clamp(5rem, 3.66vw, 10rem) 0;
  width: 80vw;
  margin: 0 auto;
}
.header .burgerNavList__item {
  width: 50%;
}
.header .burgerNavList__item a {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  font-weight: 500;
  color: #0080D9;
  text-decoration: none;
}
@media screen and (min-width: 860px) {
  .header .burgerNavList__item a:hover {
    text-decoration: underline;
  }
}
.header .burgerNav.show {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
}
.header .burgerNav.hide {
  transform: scale(0.9);
  opacity: 0;
  visibility: hidden;
}
.header .headerNav__cover {
  content: "";
  width: 0;
  height: 100vh;
  display: block;
  background: url(../img/bg_burgerNav_pc.png) no-repeat right center, #fff;
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  opacity: 0;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.header .headerNav__cover.show {
  opacity: 1;
  width: 100%;
}
.header .headerNav__cover.hide {
  opacity: 0;
  width: 0;
}
.header .burger {
  width: clamp(8.5rem, 6.223vw, 17rem);
  height: clamp(8.5rem, 6.223vw, 17rem);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 9999;
  background: #0080D9;
  /*クリック後、バツボタンになる*/
}
.header .burger span {
  width: clamp(3.5rem, 2.562vw, 7rem);
  height: clamp(0.25rem, 0.183vw, 0.5rem);
  display: block;
  background: #fff;
  position: absolute;
  top: clamp(3.2rem, 2.343vw, 6.4rem);
  right: 0;
  left: 0;
  margin: 0 auto;
  transition: width 0.2s, right 0.2s, left 0.2s;
}
.header .burger span.burger--top {
  transform: translateY(0);
}
.header .burger span.burger--middle {
  transform: translateY(clamp(1rem, 0.732vw, 2rem));
  position: relative;
  background: none;
}
.header .burger span.burger--middle::before {
  content: "";
  display: block;
  position: absolute;
  width: clamp(3.5rem, 2.562vw, 7rem);
  height: clamp(0.25rem, 0.183vw, 0.5rem);
  background: #fff;
  transition: all 0.2s;
  transform: rotate(0deg);
}
.header .burger span.burger--middle::after {
  content: "";
  display: block;
  position: absolute;
  width: clamp(3.5rem, 2.562vw, 7rem);
  height: clamp(0.25rem, 0.183vw, 0.5rem);
  background: #fff;
  transition: all 0.2s;
  transform: rotate(0deg);
}
.header .burger span.burger--bottom {
  transform: translateY(clamp(2rem, 1.464vw, 4rem));
}
.header .burger.is-open {
  position: fixed;
  background: transparent;
}
.header .burger.is-open .burger--top {
  left: 100%;
  width: 0px;
}
.header .burger.is-open .burger--middle::before {
  transform: rotate(135deg);
  background: #191919;
}
.header .burger.is-open .burger--middle::after {
  transform: rotate(45deg);
  background: #191919;
}
.header .burger.is-open .burger--bottom {
  right: 100%;
  width: 0px;
}
.header .burger.fixedNav {
  position: fixed;
  animation: header-show 0.5s ease-in;
}

@media screen and (min-width: 0) and (max-width: 860px) {
  /* =================================
    header
  ================================= */
  .home .header .burger {
    right: 0;
    left: inherit;
  }
  .header {
    padding: clamp(1.7rem, 4.07vw, 3.5rem) clamp(2.5rem, 5.814vw, 5rem);
    /*バーガーボタン設定*/
  }
  .header__inner {
    display: block;
  }
  .header__logo .logo {
    width: clamp(14.1rem, 32.791vw, 28.2rem);
    line-height: 1;
  }
  .header .gNav {
    display: none;
  }
  .header .burgerNav__logo {
    width: clamp(6.9rem, 16.047vw, 13.8rem);
    top: clamp(2rem, 4.651vw, 4rem);
    line-height: 1;
  }
  .header .burgerNavList {
    padding: clamp(15rem, 34.884vw, 30rem) clamp(2.5rem, 5.814vw, 5rem);
    gap: clamp(2.5rem, 5.814vw, 5rem);
    width: 100%;
  }
  .header .burgerNavList__item {
    width: calc((100% - clamp(2.5rem, 5.814vw, 5rem)) / 2);
  }
  .header .burgerNavList__item a {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
  }
  .header .headerNav__cover {
    background: url(../img/bg_burgerNav_sp.png) no-repeat right center, #fff;
    background-size: cover;
  }
  .header .burger {
    width: clamp(6rem, 13.953vw, 12rem);
    height: clamp(6rem, 13.953vw, 12rem);
  }
  .header .burger span {
    width: clamp(2.1rem, 4.884vw, 4.2rem);
    top: clamp(2.2rem, 5.116vw, 4.4rem);
  }
  .header .burger span.burger--middle {
    transform: translateY(clamp(0.7rem, 1.628vw, 1.4rem));
  }
  .header .burger span.burger--middle::before {
    width: clamp(2.1rem, 4.884vw, 4.2rem);
  }
  .header .burger span.burger--middle::after {
    width: clamp(2.1rem, 4.884vw, 4.2rem);
  }
  .header .burger span.burger--bottom {
    transform: translateY(clamp(1.4rem, 3.256vw, 2.8rem));
  }
}
/*=================================
footer
=================================*/
.pageTop {
  width: clamp(9rem, 6.589vw, 18rem);
  cursor: pointer;
  position: absolute;
  right: clamp(2rem, 6.223vw, 17rem);
  bottom: clamp(11rem, 8.053vw, 22rem);
  opacity: 0;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 860px) {
  .pageTop:hover {
    transform: translateY(-10%);
  }
}
.pageTop.float {
  position: fixed;
  z-index: 10;
  opacity: 1;
}
.pageTop.nonfloat {
  opacity: 1;
}

.mainWrap:not(.under) .pageTop {
  bottom: clamp(17rem, 7.833vw, 21.4rem);
}

.footer {
  position: relative;
  z-index: -1;
}
.footer a {
  color: #fff;
}
.footer__main {
  background: url(../img/bg_footer_pc.jpg) no-repeat center center;
  background-size: cover;
  padding: clamp(8rem, 5.857vw, 16rem) clamp(2rem, 6.223vw, 17rem) clamp(11.8rem, 8.638vw, 23.6rem);
  box-sizing: content-box;
}
.footer__sub {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: #fff;
  padding: clamp(4.8rem, 3.551vw, 9.7rem) clamp(2rem, 6.223vw, 17rem);
}
.footer__sub .copy {
  font-family: "Outfit", "Noto Sans JP", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "Yu Gothic", YuGothic, "Noto Sans JP", "源ノ角ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  letter-spacing: 0.05em;
  line-height: 2.2;
}
.footer__logo {
  font-size: clamp(3rem, 2.196vw, 6rem);
  line-height: 2.33333;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: clamp(5rem, 3.66vw, 10rem);
}
.footerCont {
  display: flex;
  align-self: flex-start;
}
.footerInfoWrap {
  border-top: clamp(0.2rem, 0.146vw, 0.4rem) solid #fff;
  padding-top: clamp(2.3rem, 1.684vw, 4.6rem);
}
.footerInfo__ttl {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  font-weight: 500;
  color: #fff;
  margin-bottom: clamp(1.5rem, 1.098vw, 3rem);
}
.footerInfo__add {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  line-height: 1.5;
  color: #fff;
}
.footerInfo + .footerInfo {
  margin-top: clamp(2.5rem, 1.83vw, 5rem);
}
.footerNav {
  border-top: clamp(0.2rem, 0.146vw, 0.4rem) solid #fff;
  padding-top: clamp(2.3rem, 1.684vw, 4.6rem);
}
.footerNav:not(:first-child) {
  margin-left: clamp(6rem, 6.589vw, 18rem);
}
.footerNavWrap {
  display: flex;
  margin-left: clamp(14.2rem, 15.52vw, 42.4rem);
}
.footerNav__ttl {
  font-size: clamp(1.8rem, 1.281vw, 3.5rem);
  font-weight: 500;
  line-height: 1;
  color: #fff;
  margin-bottom: clamp(2.4rem, 1.757vw, 4.8rem);
}
.footerNavList__item a {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  line-height: 2;
  color: #fff;
  display: block;
  text-decoration: none;
  padding-right: clamp(2.6rem, 1.903vw, 5.2rem);
  position: relative;
}
.footerNavList__item a::after {
  content: "";
  width: clamp(1.5rem, 1.098vw, 3rem);
  height: clamp(1.2rem, 0.878vw, 2.4rem);
  display: inline-block;
  background: url(../img/footer_arrow.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (min-width: 860px) {
  .footerNavList__item a:hover {
    text-decoration: underline;
  }
}
.footer .footerSubLink {
  display: flex;
  gap: 0 clamp(2rem, 1.464vw, 4rem);
}
.footer .footerSubLink__item a {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  font-weight: 500;
  color: #191919;
  text-decoration: none;
}
@media screen and (min-width: 860px) {
  .footer .footerSubLink__item a:hover {
    color: #0080D9;
    text-decoration: underline;
  }
}

@media screen and (min-width: 0) and (max-width: 860px) {
  /*=================================
  footer
  =================================*/
  .pageTop {
    width: clamp(6rem, 13.953vw, 12rem);
    right: 0;
    bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .mainWrap:not(.under) .pageTop {
    bottom: clamp(5rem, 11.628vw, 10rem);
  }
  .footer__main {
    background: url(../img/bg_footer_sp.jpg) no-repeat center center;
    background-size: cover;
    padding: clamp(7.5rem, 17.442vw, 15rem) clamp(2.5rem, 5.814vw, 5rem) clamp(7.4rem, 17.209vw, 14.8rem);
  }
  .footer__sub {
    display: block;
    padding: clamp(3rem, 6.977vw, 6rem) clamp(2.5rem, 5.814vw, 5rem);
  }
  .footer__sub .copy {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    display: block;
    text-align: center;
    margin-top: clamp(2.5rem, 5.814vw, 5rem);
  }
  .footer__logo {
    font-size: clamp(2.2rem, 4.07vw, 3.5rem);
    line-height: 1.5;
    letter-spacing: 0;
    margin-bottom: clamp(3.1rem, 7.209vw, 6.2rem);
  }
  .footerCont {
    display: block;
  }
  .footerInfoWrap {
    border-top: clamp(0.1rem, 0.233vw, 0.2rem) solid #fff;
    padding-top: clamp(2.5rem, 5.814vw, 5rem);
  }
  .footerInfo__ttl {
    font-size: clamp(2rem, 4.651vw, 4rem);
    margin-bottom: clamp(1rem, 2.326vw, 2rem);
  }
  .footerInfo__add {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
  }
  .footerInfo + .footerInfo {
    margin-top: clamp(2.5rem, 5.814vw, 5rem);
  }
  .footerNav {
    border-top: clamp(0.1rem, 0.233vw, 0.2rem) solid #fff;
    padding-top: clamp(2.5rem, 5.814vw, 5rem);
    margin-top: clamp(5rem, 11.628vw, 10rem);
  }
  .footerNav:not(:first-child) {
    margin-left: 0;
  }
  .footerNavWrap {
    display: block;
    margin-left: 0;
  }
  .footerNav__ttl {
    font-size: clamp(2rem, 4.651vw, 4rem);
    margin-bottom: clamp(1.5rem, 3.488vw, 3rem);
  }
  .footerNavList__item a {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    padding-right: 0;
  }
  .footerNavList__item a::after {
    width: clamp(1.4rem, 3.256vw, 2.8rem);
    height: clamp(1rem, 2.326vw, 2rem);
    position: static;
    margin-left: clamp(1.5rem, 3.488vw, 3rem);
    margin-bottom: clamp(-0.5rem, -1.163vw, -1rem);
  }
  .footer .footerSubLink {
    gap: 0 clamp(3rem, 9.302vw, 8rem);
    justify-content: center;
  }
  .footer .footerSubLink__item a {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
  }
}
/*=================================
  sidebar
=================================*/
.sidebar > * + * {
  margin-top: clamp(5rem, 3.66vw, 10rem);
}
.sidebar .sideLinkList__item:not(:first-child) {
  margin-top: clamp(2rem, 1.464vw, 4rem);
}
.sidebar .sideLinkList__item a {
  font-size: clamp(1.5rem, 1.098vw, 3rem);
  line-height: 1;
  color: #191919;
  text-align: center;
  text-decoration: none;
  display: block;
  border: clamp(0.1rem, 0.073vw, 0.2rem) solid #191919;
  background: #fff;
  padding: clamp(1.9rem, 1.391vw, 3.8rem) clamp(1rem, 0.732vw, 2rem);
  position: relative;
}
.sidebar .sideLinkList__item a::before {
  content: "";
  display: inline-block;
  width: clamp(1.5rem, 1.098vw, 3rem);
  height: clamp(1.1rem, 0.805vw, 2.2rem);
  mask-image: url(../img/icon_arrow.svg);
  background-color: #0080D9;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  position: absolute;
  top: 50%;
  right: clamp(1.9rem, 1.391vw, 3.8rem);
  transform: translateY(-50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 860px) {
  .sidebar .sideLinkList__item a:hover {
    color: #fff;
    background: #0080D9;
  }
  .sidebar .sideLinkList__item a:hover::before {
    background-color: #fff;
    transform: translate(30%, -50%);
  }
}
.sidebar .profileCard__img {
  display: block;
  margin-bottom: clamp(2rem, 1.464vw, 4rem);
}
.sidebar .profileCard__name {
  font-size: clamp(3rem, 2.196vw, 6rem);
  font-weight: 500;
  line-height: 1;
  color: #191919;
}
.sidebar .profileCard__ruby {
  font-family: "Outfit", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.2rem, 0.915vw, 2.5rem);
  letter-spacing: 0.05em;
  line-height: 1;
  color: #0080D9;
  display: block;
  margin-bottom: clamp(1rem, 0.732vw, 2rem);
}

@media screen and (max-width: 1080px) {
  /*=================================
    sidebar
  =================================*/
  .sidebar .sideLinkList {
    display: flex;
    overflow-x: scroll;
    word-break: keep-all;
    white-space: nowrap;
    gap: 0 clamp(1.5rem, 3.488vw, 3rem);
    padding: 0 clamp(2.5rem, 5.814vw, 5rem);
  }
  .sidebar .sideLinkList__item:not(:first-child) {
    margin-top: 0;
  }
  .sidebar .sideLinkList__item a {
    font-size: clamp(1.5rem, 3.488vw, 3rem);
    border: clamp(0.1rem, 0.233vw, 0.2rem) solid #191919;
    padding: clamp(1.9rem, 4.419vw, 3.8rem) clamp(3rem, 6.977vw, 6rem) clamp(1.9rem, 4.419vw, 3.8rem) clamp(1rem, 2.326vw, 2rem);
  }
  .sidebar .sideLinkList__item a::before {
    width: clamp(1.5rem, 3.488vw, 3rem);
    height: clamp(1.1rem, 2.558vw, 2.2rem);
    right: clamp(0.9rem, 2.093vw, 1.8rem);
  }
  .sidebar .profile {
    padding: clamp(5rem, 11.628vw, 10rem) clamp(2.5rem, 5.814vw, 5rem);
    background: #EFF6F7;
    display: none;
  }
  .sidebar .profileCard {
    width: fit-content;
    max-width: 80%;
    margin: 0 auto;
  }
  .sidebar .profileCard__img {
    margin-bottom: clamp(2.5rem, 5.814vw, 5rem);
  }
  .sidebar .profileCard__name {
    font-size: clamp(3rem, 6.977vw, 6rem);
  }
  .sidebar .profileCard__ruby {
    font-size: clamp(1.2rem, 2.907vw, 2.5rem);
    margin-bottom: clamp(1rem, 2.326vw, 2rem);
  }
  .spMenuStatic .sidebar {
    margin-top: clamp(5rem, 11.628vw, 10rem);
  }
}