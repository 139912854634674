@use "../../global" as *;
/*=================================
  member
=================================*/
.memberSec {
  &__position {
    font-size: vwclamp(35,70);
    font-weight: 500;
    line-height: 1.38571;
    color: $color-theme;
    margin-bottom: vwclamp(35,70);
  }
  .memberList {
    display: flex;
    flex-wrap: wrap;
    gap: vwclamp(50,100) vwclamp(20,75);
    &__item {
      width: calc((100% - vwclamp(20,75) * 2) / 3);
    }
    &__thumb {
      width: 100%;
    }
    &__name {
      font-size: vwclamp(20,60);
      line-height: 1;
      color: $font-color-base;
      margin: vwclamp(20,40) 0;
    }
    &__ruby {
      @include font-outfit;
      font-size: vwclamp(12,25);
      line-height: 2;
      letter-spacing: .05em;
      color: $color-theme;
      margin-bottom: vwclamp(7,14);
      display: block;
    }
    &__link {
      font-size: vwclamp(12,30);
      line-height: 1;
      color: $font-color-base;
      text-align: center;
      text-decoration: none;
      display: block;
      width: 72.7%;
      min-width: 100px;
      border: vwclamp(1,2) solid $font-color-base;
      background: #fff;
      padding: vwclamp(19,38) vwclamp(20,40);
      margin-top: vwclamp(20,40);
      position: relative;
      &::before {
        content: "";
        display: inline-block;
        width: vwclamp(15,30);
        height: vwclamp(11,22);
        mask-image: url(../img/icon_arrow.svg);
        background-color: $color-theme;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        position: absolute;
        top: 50%;
        right: vwclamp(5,38);
        transform: translateY(-50%);
        @include animation-base;
      }
      @include mq(pc) {
        &:hover {
          color: #fff;
          background: $color-theme;
          &::before {
            background-color: #fff;
            transform: translate(30%,-50%);
          }
        }
      }
    }
  }
  & + .memberSec {
    margin-top: vwclamp(70,140);
  }
}

.memberKv {
  position: relative;
  &__info {
    position: absolute;
    top: vwclamp(100,200);
    left: vwclamp(87,174);
  }
  &__name {
    font-size: vwclamp(54,108);
    font-weight: 500;
    .office {
      font-size: vwclamp(35,70);
      display: block;
      margin-bottom: vwclamp(10,20);
    }
    .ruby {
      @include font-outfit;
      font-size: vwclamp(18,36);
      letter-spacing: .05em;
      display: block;
      margin-top: vwclamp(30,60);
    }
    .slash {
      width: vwclamp(20,40);
      height: vwclamp(20,40);
      display: inline-block;
      margin: 0 vwclamp(10,20);
    }
  }
  &__whiteBox {
    font-size: vwclamp(25,50);
    font-weight: 500;
    line-height: 1.8;
    color: $font-color-base;
    background: rgba(255,255,255,.5);
    padding: vwclamp(20,40) vwclamp(30,60);
    margin-top: vwclamp(38,76);
    width: fit-content;
  }
  &__img {
    display: block;
    img {
      width: 100%;
      height: 100%;
      min-height: 460px;
      object-fit: cover;
    }
  }
}
.memberDetail {
  @include singleStyle-parts;
  &__inner {
    padding: vwclamp(70,140) vwclamp(50,100) vwclamp(50,100);
    background: #fff;
    @include mq(tab) {
      padding: 100px 100px 80px;
    }
    @include singleStyle-base;
    @for $i from 1 through 6 {
      & > h#{$i}:first-child {
        margin-top: 0!important;
      }
    }
    h3:not([class]),h3.wp-block-heading {
      font-size: vwclamp(35,70);
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: vwclamp(35,70);
    }
    .wp-block-table tr td:nth-child(1) {
      background: #EFF6F7;
      width: 30%;
    }
  }
  .sidebar {
    margin-top: vwclamp(70,140);
    @include mq(tab) {
      margin-top: 100px;
    }
  }
  .memberSlider {
    margin-top: vwclamp(55,110);
  }
  .solution {
    display: flex;
    flex-wrap: wrap;
    gap: vwclamp(22,44);
    &__item {
      font-size: vwclamp(15,30);
      font-weight: 400;
      color: $font-color-base;
      text-decoration: none;
      width: calc((100% - vwclamp(44,88)) / 3);
      border: vwclamp(1,2) solid $font-color-base;
      padding: vwclamp(32,64) vwclamp(48,95) vwclamp(32,64) vwclamp(28,56);
      background: #fff;
      position: relative;
      &::after {
        content: "";
        display: inline-block;
        width: vwclamp(15,30);
        height: vwclamp(11,22);
        mask-image: url(../img/icon_arrow.svg);
        background-color: $color-theme;
        mask-repeat: no-repeat;
        mask-position: center;
        mask-size: contain;
        position: absolute;
        top: 50%;
        right: vwclamp(5,35);
        transform: translateY(-50%);
        @include animation-base;
      }
      @include mq(pc) {
        &:hover {
          color: $color-theme;
          background: #EFF6F7;
          border-color: $color-theme;
          text-decoration: underline;
          &::after {
            transform: translate(30%,-50%);
          }
        }
      }
    }
  }
}
.otherSite {
  display: flex;
  gap: vwclamp(50,100);
  margin-top: vwclamp(105,210);
  img {
    margin: 0!important;
  }
  @include mq(pc) {
    a:hover {
      opacity: .8;
    }
  }
}

@include mq(sp) {
  /*=================================
    member
  =================================*/
  .memberSec {
    padding: 0 vwclamp(25,50,$sp-width);
    margin-top: vwclamp(50,100,$sp-width);
    &__position {
      font-size: vwclamp(25,50,$sp-width);
      line-height: 1;
      margin-bottom: vwclamp(25,50,$sp-width);
    }
    .memberList {
      gap: vwclamp(50,100,$sp-width) 0;
      padding: 0 vwclamp(25,50,$sp-width);
      &__item {
        width: 100%;
      }
      &__name {
        font-size: vwclamp(30,60,$sp-width);
        margin: vwclamp(25,50,$sp-width) 0;
      }
      &__ruby {
        font-size: vwclamp(12,20,$sp-width);
        line-height: 2.5;
        margin-bottom: vwclamp(5,10,$sp-width);
      }
      &__link {
        font-size: vwclamp(15,30,$sp-width);
        border: vwclamp(1,2,$sp-width) solid $font-color-base;
        padding: vwclamp(20,40,$sp-width) vwclamp(10,20,$sp-width);
        margin-top: vwclamp(25,50,$sp-width);
        &::before {
          width: vwclamp(15,30,$sp-width);
          height: vwclamp(11,22,$sp-width);
          right: vwclamp(21,42,$sp-width);
        }
      }
    }
    & + .memberSec {
      margin-top: vwclamp(50,100,$sp-width);
    }
  }

  .memberKv {
    &__info {
      top: vwclamp(40,80,$sp-width);
      left: vwclamp(27,54,$sp-width);
    }
    &__name {
      font-size: vwclamp(30,60,$sp-width);
      max-width: vwclamp(180,360,$sp-width);
      .office {
        font-size: vwclamp(20,40,$sp-width);
        margin-bottom: vwclamp(5,10,$sp-width);
      }
      .ruby {
        font-size: vwclamp(12,20,$sp-width);
        margin-top: vwclamp(5,10,$sp-width);
      }
      .slash {
        width: vwclamp(12,24,$sp-width);
        height: vwclamp(12,24,$sp-width);
        display: inline-block;
        margin: 0 vwclamp(6,12);
      }
    }
    &__whiteBox {
      font-size: vwclamp(15,30,$sp-width);
      line-height: 2;
      padding: vwclamp(14,28) vwclamp(18,36);
      margin-top: vwclamp(25,50,$sp-width);
    }
    &__img {
      img {
        min-height: 260px;
      }
    }
  }
  .memberDetail {
    &__inner {
      padding: vwclamp(50,100,$sp-width) vwclamp(25,50,$sp-width);
      background: transparent;
      h3:not([class]),h3.wp-block-heading {
        font-size: vwclamp(25,50,$sp-width);
        margin-bottom: vwclamp(25,50,$sp-width);
      }
      .wp-block-table tr td:nth-child(1) {
        width: 35%;
      }
    }
    .sidebar {
      margin-top: 0;
    }
    .memberSlider {
      width: calc(100% - vwclamp(25,50,$sp-width));
      margin-top: vwclamp(55,110,$sp-width);
      margin-left: vwclamp(25,50,$sp-width);
    }
    .solution {
      gap: vwclamp(25,50,$sp-width) 0;
      &__item {
        font-size: vwclamp(15,30,$sp-width);
        width: 100%;
        border: vwclamp(1,2,$sp-width) solid $font-color-base;
        padding: vwclamp(29,58,$sp-width) vwclamp(41,82,$sp-width) vwclamp(29,58,$sp-width) vwclamp(25,50,$sp-width);
        &::after {
          width: vwclamp(15,30,$sp-width);
          height: vwclamp(11,22,$sp-width);
          right: vwclamp(5,35,$sp-width);
        }
      }
    }
  }
  .otherSite {
    gap: vwclamp(25,50,$sp-width);
    margin-top: vwclamp(50,100,$sp-width);
  }
}