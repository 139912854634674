@use "../../global" as *;

/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
  text-align: center;
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  > * + * {
    margin-top: 9px;
  }
}
.btn--border {
  font-size: vwclamp(15,30);
  font-weight: 400;
  line-height: 1;
  color: $font-color-base;
  border: vwclamp(1,2) solid $font-color-base;
  background: #fff;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: vwclamp(29,58) per(15);
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    width: vwclamp(19,38);
    height: vwclamp(13,26);
    mask-image: url(../img/icon_arrow.svg);
    background-color: $color-theme;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    position: absolute;
    top: 50%;
    right: vwclamp(21,42);
    transform: translateY(-50%);
  }
}

@include mq(pc) {
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
    > * + * {
      margin: 0 20px;
    }
  }
  .btn--border {
    font-size: vwclamp(20,40);
    border-width: vwclamp(2,4);
    padding: vwclamp(35,70) vwclamp(20,70);
    max-width: 70%;
    &::before {
      width: vwclamp(27,54);
      height: vwclamp(18,37);
      @include animation-base;
    }
    &:hover {
      color: #fff;
      background: $color-theme;
      &::before {
        background-color: #fff;
        transform: translate(15%,-50%);
      }
    }
  }
}