@use "../../global" as *;

/*=================================
  memberSlider
=================================*/
.memberSlider {
  width: calc(100% + vwclamp(20,170));
  overflow: hidden;
  position: relative;
  &__wrap {
    overflow: visible!important; // 1.はみ出させるように visible で上書き
    width: calc(100% + vwclamp(170,340));
    position: relative;
  }
  &__item {
    display: block;
    text-decoration: none;
    position: relative;
    @include mq(pc) {
      &:hover {
        .memberSlider__img img {
          transform: scale(1.1);
        }
        .memberSlider__profile {
          opacity: 1;
        }
      }
    }
  }
  &__img {
    width: 100%;
    display: block;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include animation-base;
    }
  }
  &__profile {
    width: calc((580 / 800) * 100%);
    background: #fff;
    padding: vwclamp(20,40) vwclamp(15,30);
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    @include animation-base;
    &--name {
      font-size: vwclamp(20,60);
      font-weight: 500;
      line-height: 1.85;
      color: $font-color-base;
    }
    &--ruby {
      @include font-outfit;
      font-size: vwclamp(12,25);
      letter-spacing: .05em;
      line-height: 2;
      color: $color-theme;
      display: block;
    }
    &--post {
      font-size: vwclamp(14,35);
      line-height: 2;
      color: $font-color-base;
      display: block;
    }
  }
  .swiper-wrapper {
    box-sizing: border-box;
    align-items: flex-end;
  }
  .swiper-slide {
    transform: scale(.835);
    transform-origin: top right;
    @include animation-base;
    &.swiper-slide-active {
      transform: scale(1);
      .memberSlider__profile {
        opacity: 1;
      }
    }
  }
  .swiper-controller {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: vwclamp(14,48);
    position: absolute;
    bottom: 0;
    right: vwclamp(20,170);
  }
  .swiper-pagination {
    margin-right: auto;
  }
  .swiper-button-next, .swiper-button-prev {
    width: vwclamp(34,108);
    height: vwclamp(34,108);
    margin: 0;
    position: static;
    z-index: 100;
    &::after {
      content: "";
      width: vwclamp(34,108);
      height: vwclamp(34,108);
      display: inline-block;
      background: url(../img/slider_arrow.png) no-repeat center center;
      background-size: contain;
    }
    &.swiper-button-disabled {
      opacity: .3!important;
    }
  }
  .swiper-button-next {
    &::after {
      transform: rotate(180deg);
    }
  }
}
@include mq(sp) {
  .memberSlider {
    width: 100%;
    padding-bottom: calc(vwclamp(63,125,$sp-width) + vwclamp(35,70,$sp-width));
    &__wrap {
      width: calc(100% + vwclamp(20,100,$sp-width));
    }
    &__profile {
      width: calc((500 / 595) * 100%);
      padding: vwclamp(5,20,$sp-width) vwclamp(5,20,$sp-width);
      &--name {
        font-size: vwclamp(20,50,$sp-width);
        line-height: 1.62;
      }
      &--ruby {
        font-size: vwclamp(10,20,$sp-width);
        line-height: 1.7;
      }
      &--post {
        font-size: vwclamp(12,16,$sp-width);
        line-height: 1.73333;
      }
    }
    .swiper-slide {
      transform: scale(.735);
    }
    .swiper-controller {
      gap: vwclamp(27,55,$sp-width);
      right: vwclamp(25,50,$sp-width);
    }
    .swiper-button-next, .swiper-button-prev {
      width: vwclamp(63,125,$sp-width);
      height: vwclamp(63,125,$sp-width);
      &::after {
        width: vwclamp(63,125,$sp-width);
        height: vwclamp(63,125,$sp-width);
      }
    }
  }
}