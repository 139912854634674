@use "../../global" as *;

/*=================================
  toc
=================================*/
.tocBox {
  margin: 40px auto;
  padding: 100px 3%;
  background: $color-theme;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  &__ttl {
    font-size: clamp(1.8rem, vw(37), 3.7rem);
    font-weight: 500;
    line-height: 1;
    color: rgba(255, 255, 255, .5);
    margin-bottom: 60px;
  }
  .tocList {
    counter-reset: tocNum;
    padding: 0 1.5%;
    &__item {
      position: relative;
      &::before {
        counter-increment: tocNum;
        content: "0"counter(tocNum);
        font-size: clamp(1.5rem, vw(20), 2rem);
        font-weight: 700;
        color: #fff;
        display: inline-block;
        position: absolute;
        top: 1px;
        left: 0;
      }
      a {
        font-size: clamp(1.5rem, vw(20), 2rem);
        font-weight: 700;
        color: #fff;
        display: inline-block;
        padding: 0 0 0 38px;
        @include mq(pc) {
          &:hover {
            text-decoration: none;
          }
        }
      }
      & + .tocList__item {
        margin-top: 1.5em;
      }
      .tocList {
        counter-reset: tocChildNum;
        padding-left: 2.5em;
        margin-top: 1em;
        &__item {
          &::before {
            counter-increment: tocChildNum;
            content: "0"counter(tocChildNum);
          }
          a {
            padding-left: 2em;
          }
          & + .tocList__item {
            margin-top: .5em;
          }
        }
      }
    }
  }
  .tocMore {
    font-size: 1.5rem;
    font-weight: 500;
    background: $color-yellow;
    text-align: center;
    margin-top: 20px;
    padding: 8px 20px;
    display: inline-block;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    @include mq(pc) {
      &:hover {
        color: #fff;
        background: $color-theme;
      }
    }
  }
  // &:not(.open) {
  //   .tocBox__inner {
  //     > .tocList > .tocList__item:nth-child(n + 4) {
  //       display: none;
  //     }
  //   }
  //   .tocMore {
  //     &::after {
  //       transform: rotate(0deg);
  //     }
  //     @include mq(pc) {
  //       &:hover {
  //         &::after {
  //           transform: translateY(2px) rotate(90deg);
  //         }
  //       }
  //     }
  //   }
  // }
}
@include mq(sp) {
  .tocBox {
    margin: 35px auto;
    padding: 30px per(17) 60px;
    &__ttl {
      font-size: 2.5rem;
      margin-bottom: 22px;
    }
    .tocList {
      padding: 0;
      &__item {
        &::before {
          font-size: 1.8rem;
        }
        a {
          font-size: 1.8rem;
        }
      }
    }
    .tocMore {
      font-size: 1.4rem;
    }
  }
}