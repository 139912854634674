@use "../../global" as *;

/*=================================
  kv
=================================*/
.underKv {
  margin: vwclamp(50,100) auto vwclamp(70,140);
  display: block;
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include mq(sp) {
  /*=================================
    kv
  =================================*/
  .underKv {
    margin: vwclamp(50,100,$sp-width) auto;
  }
}