@use "../../global" as *;

/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  ul {
    li {
      font-size: vwclamp(15,30);
      color: $font-color-base;
      line-height: 2;
      display: inline-block;
      padding-right: vwclamp(22,45);
      position: relative;
      &:not(:first-child) {
        padding-left: vwclamp(22,45);
        &::before {
          content: "";
          width: vwclamp(1,2);
          height: vwclamp(21,42);
          background: $font-color-base;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%) rotate(-45deg);
        }
      }
      a {
        font-size: vwclamp(15,30);
        color: $font-color-base;
        line-height: 2;
        display: inline-block;
        text-decoration: none;
        @include mq(pc) {
          &:hover {
            color: $color-link;
            text-decoration: none;
          }
        }
      }
    }
  }
}
@include mq(sp) {
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb {
    padding: 0 vwclamp(25,50,$sp-width);
    ul {
      li {
        font-size: vwclamp(12,20,$sp-width);
        line-height: 2.2;
        padding-right: vwclamp(15,30,$sp-width);
        &:not(:first-child) {
          padding-left: vwclamp(15,30,$sp-width);
          &::before {
            height: vwclamp(12,24,$sp-width);
          }
        }
        a {
          font-size: vwclamp(12,20,$sp-width);
          line-height: 2.2;
        }
      }
    }
  }
}