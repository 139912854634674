// color
$base-color: #fff;
$font-color-base: #191919;
$color-link: #0080D9;
$color-theme: #0080D9;
$color-theme-light:#e7edf8;
$color-red: #FC0000;
$color-yellow: #ffce08;
$color-gray: #F6F6F6;

// Designwidth
$design-width: 2732;
$sp-width: 860;

//タイトルの定義
@mixin ttl-base($size,$color:$font-color-base,$lineHeight:1.25,$letterSpacing:.12em,$weight:600) {
  font-size: $size;
  color: $color;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
  font-weight: $weight;
}

//vw計算
@function vw($pixelsVw,$width:$design-width) {
  @return calc($pixelsVw / $width) * 100vw;
}
//%計算
@function per($pixelsPer,$width:$sp-width) {
  @return calc($pixelsPer / $width) * 100%;
}

//vw clamp計算
@function vwclamp($minPx,$maxPx,$width:$design-width) {
  $minSize:calc($minPx / 10);
  $maxSize:calc($maxPx / 10);
  $vwSize:calc(round($maxPx / $width * 100 * 1000) / 1000);
  @return clamp(#{$minSize}rem,#{$vwSize}vw,#{$maxSize}rem);
}

// width
$base-width: vwclamp(1195,2390);
$base-width-in: 1085px;
$display-width-s: 860px;