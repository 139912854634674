@use "../../global" as *;
.remove-scrolling {
  height: 100%; 
  overflow: hidden; 
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
  z-index: 999;
  svg {
    width: 100%;
    max-width: 1080px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
line {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 3s ease-in both;
}
@keyframes svg {
  0% {
      fill: transparent;
      stroke-dashoffset: 600px;
  }
  100%{
      fill: $color-theme;
      stroke-dashoffset: 0;
  }
}
@keyframes svg2 {
  0% {
      fill: transparent;
      stroke-dashoffset: 600px;
  }
  100%{
      fill: #fff;
      stroke-dashoffset: 0;
  }
}
@keyframes rect {
  0% {
      fill: transparent;
      stroke-dashoffset: 600px;
  }
  100%{
      fill: #198fd9;
      stroke-dashoffset: 0;
  }
}
.fo1 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 1s;
}
.fo2 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 1.3s;
}
.fo3 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 1.6s;
}
.fo4 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 1.9s;
}
.fo5 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 2.2s;
}
.fo6 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 2.5s;
}
.fo7 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 2.8s;
}
.fo8 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.8s ease-in both;
  animation-delay: 3.1s;
}
.fo9 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 3.4s;
}
.fo10 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 3.7s;
}
.fo11 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 4s;
}
.fo12 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 4.3s;
}
.fo13 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 4.6s;
}
.fo14 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 4.9s;
}
.fo15 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 5.2s;
}
.fo16 {
  stroke: $color-theme;
  stroke-width: 0.5px;
  stroke-dasharray: 600px;
  animation: svg 0.5s ease-in both;
  animation-delay: 5.5s;
}

.loadingHide {
  opacity: 0;
  pointer-events: none;
}
body.appear .loadingHide {
  animation-name: PageAnime-content;
  animation-duration: 0.55s;
  animation-delay: 0.55s;
  animation-fill-mode: forwards;
  opacity: 0;
  pointer-events: auto;
}
.animation .loadingHide {
  opacity: 1;
  pointer-events: auto;
}
@keyframes PageAnime-content {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
 
.animation-bg {
  display: block;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
body.appear .animation-bg {
  background: #f8f8f8;
  animation-name: PageAnime-top-ltr;
  animation-duration: 1s;
}
@keyframes PageAnime-top-ltr {
  0% {
    transform-origin: center left;
    transform: scale(2,2) translateX(-300%) skewX(-45deg);
  }
  50% {
    transform-origin: center left;
    transform: scale(2,2) translateX(-17%) skewX(-45deg);
  }
  50.001% {
    transform-origin: center left;
    transform: scale(2,2) translateX(-17%) skewX(-45deg);
  }
  100% {
    transform-origin: center left;
    transform: scale(2,2) translateX(500%) skewX(-45deg);
  }
}

@include mq(sp) {
  .loading svg {
    width: 100%;
    padding: 0 per(32);
  }
  body.appear .animation-bg {
    animation-duration: .5s;
  }
}