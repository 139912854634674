@use "../../global" as *;

/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: vwclamp(50,100) 0;
  width: 100%;
  background: #fff;
  border-left: vwclamp(1,2) solid #AAAAAA;
  border-right: vwclamp(1,2) solid #AAAAAA;
  caption {
    font-size: vwclamp(15,30);
    font-weight: 700;
    padding: vwclamp(10,20) vwclamp(22,45);
    text-align: left;
  }
  thead {
    th,td {
      font-size: vwclamp(15,30);
      font-weight: 700;
      color: #fff;
      padding: vwclamp(22,45);
      background: $color-theme;
    }
  }
  th {
    font-weight: 500;
    background: #EFF6F7;
    width: 30%;
  }
  td {
    background: #fff;
    width: 70%;
  }
  th,td {
    font-size: vwclamp(15,30);
    line-height: 1.5;
    text-align: left;
    padding: vwclamp(22,45);
    vertical-align: top;
    border-top: vwclamp(1,2) solid #AAAAAA;
    border-bottom: vwclamp(1,2) solid #AAAAAA;
  }
  &.scroll {
    white-space: nowrap;
    overflow: auto;
    display: block;
    border: none;
    ::-webkit-scrollbar {
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #bcbcbc;
    }
  }
}
.scrollAnnounce {
  display: none;
}
.wp-block-table {
  margin: vwclamp(50,100) 0;
  table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0;
    width: 100%;
    background: #fff;
    border-left: vwclamp(1,2) solid #AAAAAA!important;
    border-right: vwclamp(1,2) solid #AAAAAA!important;
    caption {
      font-size: vwclamp(15,30);
      font-weight: 700;
      margin: vwclamp(25,50) 0;
      text-align: left;
    }
    thead {
      th,td {
        font-size: vwclamp(15,30);
        font-weight: 700;
        color: #fff;
        padding: vwclamp(22,45);
        background: $color-theme;
      }
    }
    th {
      font-weight: 500;
      background: #EFF6F7;
      width: 30%;
    }
    td {
      background: #fff;
      width: 70%;
    }
    th,td {
      font-size: vwclamp(15,30);
      line-height: 1.5;
      text-align: left;
      padding: vwclamp(22,45);
      vertical-align: top;
      border-top: vwclamp(1,2) solid #AAAAAA!important;
      border-bottom: vwclamp(1,2) solid #AAAAAA!important;
      border-left: none!important;
      border-right: none!important;
    }
    &.scroll {
      white-space: nowrap;
      overflow: auto;
      display: block;
      border: none;
      &::-webkit-scrollbar {
        height: vwclamp(5,10);
      }
      &::-webkit-scrollbar-track {
        background-color: #4DB0F5;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #fff;
      }
    }
  }
}
.wp-block-table td, .wp-block-table th {
  border: initial;
}
@include mq(sp) {
  /*=================================
    table
  =================================*/
  table {
    margin: vwclamp(20,40,$sp-width) 0 vwclamp(25,50,$sp-width);
    border-left: vwclamp(1,2,$sp-width) solid #AAAAAA;
    border-right: vwclamp(1,2,$sp-width) solid #AAAAAA;
    &:not(.noscroll) {
      display: block;
      white-space: nowrap;
      overflow-x: auto;
    }
    caption {
      font-size: vwclamp(15,30,$sp-width);
      padding: vwclamp(11,20,$sp-width) vwclamp(11,22,$sp-width);
    }
    thead {
      th,td {
        font-size: vwclamp(15,30,$sp-width);
        padding: vwclamp(22,45,$sp-width) vwclamp(11,22,$sp-width);
      }
    }
    th {
      width: 35%;
    }
    td {
      width: 65%;
    }
    th,td {
      font-size: vwclamp(15,30,$sp-width);
      padding: vwclamp(22,45,$sp-width) vwclamp(11,22,$sp-width);
      border-top: vwclamp(1,2,$sp-width) solid #AAAAAA;
      border-bottom: vwclamp(1,2,$sp-width) solid #AAAAAA;
    }
    &::-webkit-scrollbar {
      height: vwclamp(5,10,$sp-width);
    }
    &::-webkit-scrollbar-track {
      background-color: #4DB0F5;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
    }
  }
  .wp-block-table {
    margin: vwclamp(20,40,$sp-width) 0 vwclamp(25,50,$sp-width);
    table {
      margin: 0;
      border-left: vwclamp(1,2,$sp-width) solid #AAAAAA!important;
      border-right: vwclamp(1,2,$sp-width) solid #AAAAAA!important;
      &:not(.noscroll) {
        display: block;
        white-space: nowrap;
        overflow-x: auto;
      }
      caption {
        font-size: vwclamp(15,30,$sp-width);
        margin: vwclamp(15,30,$sp-width) 0;
      }
      thead {
        th,td {
          font-size: vwclamp(15,30,$sp-width);
          padding: vwclamp(22,45,$sp-width);
        }
      }
      th,td {
        font-size: vwclamp(15,30,$sp-width);
        padding: vwclamp(22,45,$sp-width) vwclamp(11,22,$sp-width);
        border-top: vwclamp(1,2,$sp-width) solid #AAAAAA!important;
        border-bottom: vwclamp(1,2,$sp-width) solid #AAAAAA!important;
        border-left: none!important;
        border-right: none!important;
      }
      &::-webkit-scrollbar {
        height: vwclamp(5,10,$sp-width);
      }
      &::-webkit-scrollbar-track {
        background-color: #4DB0F5;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #fff;
      }
    }
  }
  .scrollAnnounce {
    display: block;
    text-align: center;
    .text {
      font-size: vwclamp(12,24,$sp-width);
      font-weight: 600;
      color: #fff;
      background: $color-theme;
      padding: 3px 6px;
      position: relative;
      &::after {
        content: "";
        border-style: solid;
        border-width: 7px 6.5px 0 6.5px;
        border-color: $color-theme transparent transparent transparent;
        display: inline-block;
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}